<div class="container-fluid">
    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh"></td>
            </tr>
            <tr>
                <td></td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td></td>
            </tr>
            <tr>
                <td></td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">學潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">人魚</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>人魚</a>
                    <ng-template ngbNavContent class="custom-class">

                        <br>
                        <img src="../../../assets/Image/Data/MM_01.png" width="99%" style="display: block; margin: auto" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                        <br>
                        <h3>人魚 Mermaid</h3>
                        <hr />
                        <!-- 人魚自由潛水 課程介紹 -->
                        <div>
                            <h3 style="font-family:DFKai-sb;">【課程介紹】</h3>
                            <p class="fs-6">
                                美人魚不單單是穿上魚尾，跳入水中，這麼簡單！iDiving 最注重的安全與水中的踢動，提供符合 SSI 官方課程內容的人魚課程。適合各個年齡層的人們，也可以讓你帶
                                有 Aqua man 的英姿、童話故事中小美人魚優雅。
                            </p>
                            <br>
                            <br>
                        </div>
                        <!-- 人魚自由潛水 課程內容 -->
                        <div>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;">【課程內容】</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="1%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                學科（4hr）<br />
                                                人魚相關知識 / 安全事項 / 陸地擺動練習
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="2%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                泳池訓練（8-12hr）<br>
                                                人魚技巧應用 / 裝備使用 / 救援演練
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <p class="fs-6" style="color:red;">
                                                <br>註:建議安排至少 2 天的時間，課程安排依據現場狀況為主
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <img src="../../../../assets/Image/Data/MM_02.png" width="99%" style="display: block; margin: auto" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                        </div>
                        <!-- 人魚自由潛水 課程特色 -->
                        <div>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;"><br>【課程特色】</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="1%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                補訓、團練搭配人魚教練進行
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                術科練習，精實你的夏天
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                高品質教學人數比 1:2，教練全程盯著你
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                數位教材伴你一生，不用擔心知識忘記
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                學員上課期間購買潛水器材享有學員優惠價
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                水中攝影活動，搭配一位專門攝影師及人魚教練動作指導
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <img src="../../../../assets/Image/Data/MM_03.png" width="99%" style="display:block; margin:auto;" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                        </div>
                        <!-- 人魚自由潛水 課程規劃 -->
                        <div>
                            <h3 style="font-family:DFKai-sb;"><br>【課程規劃】</h3>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;"><br>平日班</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="2%">
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                DAY 1 | 學科 +泳池術科 09:00-18:00
                                            </p>
                                                <hr>
                                                <p class="fs-6">
                                                人魚相關知識、裝備介紹、安全事項、美人魚技巧及練習。<br>
                                                人魚技巧應用、裝備使用、救援演練等。<br>
                                                <br>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                DAY 2 | 泳池術科 12:00-19:00
                                            </p>
                                                <hr>
                                                <p class="fs-6">
                                                深水區人魚技巧應用、人魚裝備使用、救援演練等。<br>
                                                <br>
                                                <br>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;"><br>假日班</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="2%">
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                DAY 1 | 學科 19:00-22:00
                                            </p>
                                                <hr>
                                                <p class="fs-6">
                                                人魚相關知識、裝備介紹、安全事項、人魚技巧及練習。<br>
                                                <br>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                DAY 2 | 泳池術科 12:00-19:00
                                            </p>
                                                <hr>
                                                <p class="fs-6">
                                                人魚技巧應用、裝備使用、救援演練等。<br>
                                                <br>
                                                </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                DAY 3 | 泳池術科 12:00-19:00
                                            </p>
                                                <hr>
                                                <p class="fs-6">
                                                深水區人魚技巧應用、人魚裝備使用、救援演練等。
                                                <br>
                                                <br>
                                                </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <ul>
                                <li>建議安排至少 2 天的時間，課程安排依據現場狀況為主</li>
                            </ul>
                            <p class="fs-6" style="color:red;">
                                <br>註:人魚課程無海洋術科
                            </p>
                            <div id="carouselExampleIndicators" class="carousel slide">
                                <div class="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 2"></button>
                                </div>
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="../../../../assets/Image/Data/FD_MM_01.png" class="d-block w-100" alt="...">
                                    </div>
                                    <div class="carousel-item">
                                        <img src="../../../../assets/Image/Data/FD_MM_02.png" class="d-block w-100" alt="...">
                                    </div>
                                    <div class="carousel-item">
                                        <img src="../../../../assets/Image/Data/FD_MM_03.png" class="d-block w-100" alt="...">
                                    </div>
                                    <div class="carousel-item">
                                        <img src="../../../../assets/Image/Data/FD_MM_04.png" class="d-block w-100" alt="...">
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                            <!-- <br>
                            <br>
                            <mdb-carousel class="carousel slide carousel-fade" interval="false" [animation]="'fade'">
                                <mdb-carousel-item *ngFor="let Weekday of Weekdays; let i = index">
                                    <div class="view">
                                        <img class="d-block" src="{{Weekday.img}}" width="80%" style="display:block; margin:auto;" alt="First slide">
                                    </div>
                                </mdb-carousel-item>
                            </mdb-carousel>
                            <br> -->
                            <br>
                            <br>
                            <img src="../../../assets/Image/Data/MM_05.png" width="99%" style="display: block; margin: auto" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                        </div>
                        <!-- 人魚自由潛水 課程學費 -->
                        <!-- 方案  -->
                        <div>
                            <br>
                            <div class="d-block grey lighten-3">
                                <br>
                                <table borderless="true">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" class="fs-6">
                                                <p>
                                                    🌞夏季優惠方案🌞<br>
                                                    報名6至7月人魚課程<br>
                                                    完成特定開課之人魚夏季課程報名<br>
                                                    🎉優惠2000元<br>
                                                    <br>
                                                    <br>
                                                    ⚠️注意⚠️<br>
                                                    ＊限2024/7/1前完成報名者，期別／名額有限，額滿為止！！！<br>
                                                    ＊本方案優惠不得與其他折扣、優惠、行銷活動合併使用。<br>
                                                    ＊須臨櫃以現金繳交全額尾款，方能享本優惠。<br>
                                                    ＊iDiving 保留對本活動文字的解釋權利。<br>
                                                    <br>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6">
                                            <ul>
                                                <li>
                                                    迷路了嗎？<br>
                                                    開放水域潛水員課程<a href="/#/c120">連結</a><br>
                                                    自由潛水員課程LV1<a href="/#/cfd110">連結</a>
                                                </li>
                                                <li>以上活動不得與其他優惠合併使用</li>
                                                <li>已報名學員不得要求更改活動方案</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 人魚自由潛水 報名方式 -->
                        <app-paymentmethod></app-paymentmethod>
                        <!-- 人魚自由潛水 考核標準 -->
                        <!-- 
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">考核標準</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <a>學員結訓均需達到學科與術科課程標準之評鑑</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="7%">
                                            <a>學科:</a>
                                        </td>
                                        <td class="fs-6">
                                            <a>需通過筆試測驗（成績達80分以上），未達標準需於檢討後重考。</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            <a>泳池考核:</a>
                                        </td>
                                        <td class="fs-6">
                                            <a>靜態閉氣2’30”、動態平潛50m</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            <a>深度考核: </a>
                                        </td>
                                        <td class="fs-6">
                                            <a>
                                                15m划手上升、15m無面鏡上升、15m救援<br>
                                                執行各項潛伴系統、水面拖帶<br>
                                                使用Frenzel及自由落體進行20-30m恆重下潛，<br>
                                                並同時展現適當身體技巧。
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" width="4%" style="vertical-align:text-top;">
                                            <a>註:</a>
                                        </td>
                                        <td class="fs-6">
                                            <a>
                                                1.LV2課程為非保證班，如有考核未過則需繳交費用進行補考，費用請參閱下列說明。
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            <a> </a>
                                        </td>
                                        <td class="fs-6">
                                            <a>
                                                2.自由潛水LV2課程，如泳池考核無法通過，則需繳交500元/次，進行補考。
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            <a> </a>
                                        </td>
                                        <td class="fs-6">
                                            <a>
                                                3.5-9月自由潛水 LV2 課程，每位學生有3次的海洋結訓機會，如果無法通過測驗，則需繳交費用 750
                                                元/次進行海洋補考。在夏季課程中的任何時候, 如果計畫於10-4月前往潛立方繼續課程，須自付 1,500
                                                元（潛立方21米池９０分鐘場地費），如使用 3 小時，須自付 2,500 元。
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            <a> </a>
                                        </td>
                                        <td class="fs-6">
                                            <a>
                                                4.10月-隔年4月冬季自由潛水 LV2 課程，每位學生有1天的結訓機會，如果測驗失敗欲補考，須自付 1,500
                                                元（潛立方21米池９０分鐘場地費），如使用 3 小時，須自付 2,500 元，如計畫於 5-9 月進行東北角海洋結訓，則需自費 750
                                                元/次進行補考。
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            <a> </a>
                                        </td>
                                        <td class="fs-6">
                                            <a>
                                                5.如欲移地結訓或船潛結訓，費用另計。
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> -->
                        <!-- 人魚自由潛水 上課地點 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">上課地點</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                            <p>教室學科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>iDiving士林前港店</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>泳池術科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>各配合泳池（需先到iDiving集合）</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>海洋實習: </p>
                                        </td>
                                        <td class="fs-6">
                                            <p>夏季東北角（iDiving 東北角龍洞店）冬季潛立方（台中市西屯區安和西路 69 號）或 各移地結訓地</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>註: </p>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                ＊如遇天候不佳或泳池場地整修等，上課地點與時間可能視狀況調整，若有額外增加的場地費用，本中心將自行吸收（大台北區轉換場地之費用，台中泳池費用），不會另外收費。
                                                墾丁離島移地訓練產生之費用需自付。
                                                <br>＊學員不可因課程之"地點/時間"有調整而拒學並要求退費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 人魚自由潛水 特約課程 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">特約課程</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>學科部份，每另外約一日課程加價 300 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>術科部份，每另外約一日課程加價 500 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>指定教練費: 指定教練，加價 500 元/日，教練如果已安排既定課程或是出團，恕無法指定。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 人魚自由潛水 更改辦法 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">更改辦法</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>課程須於開始上課後的兩個月內完成。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期之更改須在 10 天前告知</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 人魚自由潛水 取消辦法 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">取消辦法</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                若因身體因素而不適合從事潛水活動，並開立醫生證明者，繳交之費用退費方式如下: NT 3,000
                                                元訂金用做購買線上教材，無法退費。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                學員自行放棄課程，本訓練中心將不予退費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <br>

                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink>親子人魚</a>
                    <ng-template ngbNavContent>

                        <br>
                        <img src="../../../assets/Image/Data/MMParentChild_01.png" width="99%" style="display: block; margin: auto" />
                        <br>
                        <h3>親子人魚</h3>
                        <hr />
                        <!-- 親子人魚自由潛水 課程介紹 -->
                        <div>
                            <p class="fs-6">
                                人魚不再只是童話故事！一起來實現童話帶給孩子們的夢想跟憧憬。讓孩子們積極的接受全新的挑戰，學習人魚自由自在地悠遊在水中的同時，學會基本的安全知識，讓安全與樂趣並存。
                            </p>
                            <br>
                        </div>
                        <!-- 親子人魚自由潛水 課程內容 -->
                        <div>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;">【親子人魚課程內容】</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="1%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                學科<br />
                                                人魚相關知識 / 陸地擺動練習
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="2%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                泳池訓練<br>
                                                人魚技巧應用 / 裝備使用 / 人魚趣味練習
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <p class="fs-6" style="color:red;">
                                                <br>註:無海洋課程
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <img src="../../../../assets/Image/Data/MMParentChild_02.png" width="99%" style="display: block; margin: auto" />
                        </div>
                        <!-- 親子人魚自由潛水 課程特色 -->
                        <div>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;"><br>【課程特色】</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="1%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                補訓、團練搭配人魚教練進行
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                高品質教學人數比 1:2~4，教練全程陪伴
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                數位教材伴你一生，不用擔心知識忘記
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                學員上課期間購買潛水器材享有學員優惠價
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <img src="../../../../assets/Image/Data/MMParentChild_03.png" width="99%" style="display:block; margin:auto;" />
                        </div>
                        <!-- 親子人魚自由潛水 課程規劃 -->
                        <div>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;"><br>【課程規劃】</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="2%">
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                DAY 1 | 學科 3hr<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                人魚相關知識、裝備介紹 、人魚技巧及練習<br>
                                                <br>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                DAY 2 | 泳池 3hr<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                人魚技巧應用、裝備使用、人魚趣味練習<br>
                                                <br>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <p class="fs-6" style="color:red;">
                                                <br>註:無海洋術科
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <img src="../../../../assets/Image/Data/MMParentChild_Schedule.png" width="99%" style="display:block; margin:auto;" />
                        </div>
                        <!-- 親子人魚自由潛水 課程學費 -->
                        <!-- 方案  -->
                        <div>
                            <br>
                            <div class="d-block grey lighten-3">
                                <br>
                                <table borderless="true">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" class="fs-6">
                                                <p>
                                                    🎉早鳥優惠方案🎉<br>
                                                    即日起(2/15)<br>
                                                    完成特定開課之人魚夏季課程報名<br>
                                                    優惠2000元：01-04期<br>
                                                    <br>
                                                    <br>
                                                    ⚠️注意⚠️<br>
                                                    ＊期別／名額有限，額滿為止！！！<br>
                                                    ＊本方案優惠不得與其他折扣、優惠、行銷活動合併使用。<br>
                                                    ＊需依照課程排程完成課程<br>
                                                    ＊須臨櫃以現金繳交全額尾款，方能享本優惠。<br>
                                                    ＊iDiving 保留對本活動文字的解釋權利。<br>
                                                    <br>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td>
                                            <h4>2023年 親子人魚</h4>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            <p>其他開課日期請加 LINE 客服安排上課日期<br></p>
                                            <p>【親子人魚課程】即日起報名<br></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <img src="../../../../assets/Image/Data/MMParentChild_05.png" width="99%" style="display:block; margin:auto;" />
                            <br>
                            <img src="../../../../assets/Image/Data/MMParentChild_06.png" width="99%" style="display:block; margin:auto;" />
                        </div>
                        <!-- 親子人魚自由潛水 報名方式 -->
                        <app-paymentmethod></app-paymentmethod>
                        <!-- 親子人魚自由潛水 上課地點 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">上課地點</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                            <p>教室學科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>iDiving士林前港店</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>泳池術科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>各配合泳池（需先到iDiving集合）</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>海洋實習: </p>
                                        </td>
                                        <td class="fs-6">
                                            <p>夏季東北角（iDiving 東北角龍洞店）冬季潛立方（台中市西屯區安和西路 69 號）或 各移地結訓地</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>註: </p>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                ＊如遇天候不佳或泳池場地整修等，上課地點與時間可能視狀況調整，若有額外增加的場地費用，本中心將自行吸收（大台北區轉換場地之費用，台中泳池費用），不會另外收費。
                                                墾丁離島移地訓練產生之費用需自付。
                                                <br>＊學員不可因課程之"地點/時間"有調整而拒學並要求退費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 親子人魚自由潛水 特約課程 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">特約課程</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>學科部份，每另外約一日課程加價 300 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>術科部份，每另外約一日課程加價 500 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>指定教練費: 指定教練，加價 500 元/日，教練如果已安排既定課程或是出團，恕無法指定。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 親子人魚自由潛水 更改辦法 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">更改辦法</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>課程須於開始上課後的兩個月內完成。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期之更改須在 10 天前告知</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 親子人魚自由潛水 取消辦法 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">取消辦法</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                若因身體因素而不適合從事潛水活動，並開立醫生證明者，繳交之費用退費方式如下: NT 3,000
                                                元訂金用做購買線上教材，無法退費。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                學員自行放棄課程，本訓練中心將不予退費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <br>

                        </div>
                    </ng-template>
                </li>
            </ul>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>

            <!-- <pre>Active: {{ active }}</pre> -->

        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td></td>
            </tr>
            <tr>
                <td></td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>
</div>