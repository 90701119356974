import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c101',
  templateUrl: './c101.component.html',
  styleUrls: ['./c101.component.scss']
})
export class C101Component implements OnInit {

  active = 2;

  constructor() { }

  ngOnInit(): void {
  }

}
