<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">學潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">進階探險潛水員</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <h3 style="font-family:DFKai-sb;">進階探險潛水員 Advanced Adventurer</h3>
            <hr>

            <!-- 進階探險 課程內容 -->
            <div>
                <br>
                <h3 style="font-family:DFKai-sb;">開課內容</h3>
                <ul>
                    <li class="fs-6">
                        學科（2hr）<br>
                        船潛 / 深潛 / 水中導航 / 完美浮力 / 潮汐風浪水流
                    </li>
                    <li class="fs-6">
                        海洋訓練（13hr）<br>
                        電腦錶監控 / 中性浮力練習 / 大深度體驗 / 水中導航練習<br>
                        船潛出入水方式 / 水底浮力棒使用 / 遇水流時的應對方式
                    </li>
                </ul>
                <br>
                <img src="../../../../assets/Image/Data/SC_AA_01.png" width="60%" style="display:block; margin:auto;">
            </div>
            <!-- 進階探險 課程特色 -->
            <div>
                <h3 style="font-family:DFKai-sb;"><br>課程特色</h3>
                <ul class="fs-6">
                    <li>
                        潛水深度將可突破18米的限制。
                    </li>
                    <li>
                        針對浮力控制會有更多的介紹與練習，增加潛水的穩定度。
                    </li>
                    <li>
                        海洋課程期間提供調節器、B.C.D.、電腦錶、浮力袋與手輪。
                    </li>
                    <li>
                        想了解專長課程卻不知從何開始，AA課程讓您預先體驗。
                    </li>
                    <li>
                        平日 / 假日 均有開課，課程安排很方便。
                    </li>
                    <li>
                        領導級人員對學員比例 1：3，教練時時刻刻都在您身旁。
                    </li>
                    <li>
                        數位教材伴您一生，不必擔心知識忘記。
                    </li>
                    <li>
                        於iDiving上完AA可享船潛、導航、深潛、完美浮力專長課程優惠。
                    </li>
                    <li>
                        學員上課期間，購買潛水器材享有學員優惠價。
                    </li>
                    <li>
                        搭配移地結訓，冬季也能完成課程。
                    </li>
                </ul>
                <br>
                <img src="../../../../assets/Image/Data/SC_AA_02.png" width="60%" style="display:block; margin:auto;">
            </div>
            <!-- 進階探險 課程規劃 -->
            <div>
                <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td width="10%">
                            </td>
                            <td>
                                <p class="fs-6">
                                    DAY 1 | 學科 19:00~22:00<br>
                                </p>
                                <hr>
                                <p class="fs-6">
                                    船潛、深潛、水中導航、完美浮力、潮汐風浪水流
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            </td>
                            <td>
                                <p class="fs-6">
                                    <br>DAY 2 | 開放水域 08:00 ~ 16:00<br>
                                </p>
                                <hr>
                                <p class="fs-6">
                                    電腦錶監控、中性浮力練習<br>
                                    出入水技巧練習、水中導航練習
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            </td>
                            <td>
                                <p class="fs-6">
                                    <br>DAY 3 | 開放水域 08:00 ~ 13:00<br>
                                </p>
                                <hr>
                                <p class="fs-6">
                                    大深度體驗、船潛出入水方式、水底浮力棒使用
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br>
                <div id="carouselExampleIndicators" class="carousel slide">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src="../../../../assets/Image/Data/AA_Schedule_Weekend.png" class="d-block w-100" alt="...">
                        </div>
                        <div class="carousel-item">
                            <img src="../../../../assets/Image/Data/AA_Schedule_Weekends.png" class="d-block w-100" alt="...">
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
                <br>
                <!-- 
                <mdb-carousel class="carousel slide carousel-fade" interval="false" [animation]="'fade'">
                    <mdb-carousel-item *ngFor="let AA_Schedule of AA_Schedule; let i = index">
                        <div class="view">
                            <img class="d-block" src="{{AA_Schedule.img}}" width="100%" style="display:block; margin:auto;" alt="First slide">
                        </div>
                    </mdb-carousel-item>
                </mdb-carousel>
                <br> -->
                
            </div>
            <!-- 進階探險 課程學費 -->
            <div>
                <br>
                <h3 style="font-family:DFKai-sb;">課程學費</h3>
                <hr>
                <ul class="fs-6">
                    <li style="color: green;">定價 13,000 元。</li>
                    <li>現金優惠，折扣 300 元。<p style="color: red;">報名24年度夏季 9 10期，送東北角免費住宿一晚。</p></li>
                    <li style="color: red;">
                        iDiving的OWD學員，課程結訓1個月內完成報名（需填寫報名資料及繳交3,000元定金），可享有2,000元折扣優惠 。
                        <p style="color: green;">註：以上優惠只要符合條件，可合併使用</p>
                    </li>
                    <li>費用包含：SSI數位教材、國際數位證照申請、教練費、訓練期間潛水重裝使用（浮力調整裝置、調節器、電腦錶）、術科課程期間保險。</li>
                    <li>費用不含：海洋實習交通與餐食費用、輕裝租借。</li>
                    <li>假日班學生可改於平日班完成術科，不須額外付費。</li>
                    <li>無輕裝者，欲購買iDiving OWD課程全套贈品，享優惠價。</li>
                </ul>
                <br>
            </div>            
            <!-- 進階探險 報名方式 -->
            <div>
                <br>
                <app-paymentmethod></app-paymentmethod>
                <br>
            </div>
            <!-- 進階探險 報名須知 -->
            <div>
                <br>
                <h3 style="font-family:DFKai-sb;">報名須知</h3>
                <hr>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td class="fs-6" colspan="2">
                                <p>具備開放水域潛水員OWD證照（不分系統）</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" colspan="2">
                                <p>年齡需滿12歲以上，健康狀況良好</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" colspan="2">
                                <a href="https://drive.google.com/file/d/13Lltu9vx3duMY8BWTbkFMDUY5FbmipIw/view?pli=1">健康聲明書在此請點擊</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 進階探險 上課地點 -->
            <div>
                <br>
                <h3 style="font-family:DFKai-sb;">上課地點</h3>
                <hr>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td class="fs-6" colspan="2">
                                <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                <p>教室學科:</p>
                            </td>
                            <td class="fs-6">
                                <p>iDiving 士林前港店（台北市士林區前港街 8 號）</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <p>海洋實習:</p>
                            </td>
                            <td class="fs-6">
                                <p>iDiving 東北角龍洞店（新北市貢寮區龍洞街 66-1 號）</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="color:green;vertical-align:text-top;">
                                <p>註: </p>
                            </td>
                            <td class="fs-6" style="color:green;">
                                <p>
                                    上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修等），若有額外增加的費用本中心將自行吸收，不會另外收費，但學員不可因課程時間調整而要求退費。
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 進階探險 特約課程 -->
            <div>
                <br>
                <h3 style="font-family:DFKai-sb;">特約課程</h3>
                <hr>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>學科部份，每另外約一日課程加價 300 元</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>術科部份，每另外約一日課程加價 500 元</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>三人同行，一起約定特殊時間上課，不須額外加價。</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 進階探險 課程更改 -->
            <div>
                <br>
                <h3 style="font-family:DFKai-sb;">課程更改</h3>
                <hr>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>課程須於開始上課後的兩個月內完成。</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>上課日期之更改須在 10 天前告知</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 進階探險 取消辦法 -->
            <div>
                <br>
                <h3 style="font-family:DFKai-sb;">取消辦法</h3>
                <hr>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>若因身體因素不適合從事潛水活動，並開立醫生證明者，除了NT 3,000元購買數位教材的訂金，其餘將會退還。</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br>
            </div>

        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>