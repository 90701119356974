<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">愛潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">關於我們</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>
    
    <div class="row">
        <img src="../../../../assets/Image/Data/About_01.jpg" width="100%" style="display:block; margin:auto;" alt="" />
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <!-- 關於我們 -->
            <div>
                <br>
                <br>
                <h3 style="text-align:center;">關於我們</h3>
                <br>
                <p style="text-align:center;">
                    iDiving成立於2011年，最初為三位大學好友，<br>
                    在士林區開立自己的店面，希望提供一個優質的環境，<br>
                    讓大家感受潛水的樂趣與美麗。
                </p>
                <br>
                <p style="text-align:center;">
                    一直以來致力於潛水教學，課程不只包含初階OWD，<br>
                    也在早期即有推廣自由潛水課程，一路教授各項專長，<br>
                    甚至領導級的教練班訓練，提供從初級到職業領導級的<br>
                    培訓，亦有延伸領域等多元的課程。
                </p>
                <br>
                <p style="text-align:center;">
                    目前有1間潛水中心和1間海邊店提供顧客最佳的設施，<br>
                    除了潛水器材裝備購買和維修服務外，<br>
                    每年開許多國內外潛水旅遊團，讓喜愛潛水的您，<br>
                    跟著iDiving一起探索和感受各地海洋魅力。
                </p>
                <br>
                <p style="text-align:center;">
                    iDiving致力將安全又充滿歡樂的潛水活動推薦給大家<br>
                    盡力落實近海、親海、愛海的觀念來推廣海洋保育！<br>
                    期望能使絢麗多彩又波瀾壯闊的海洋美景繼續傳承下去！
                </p>
                <br>
                <br>
                <h3 style="text-align:center;">環境友善且專業的潛水中心</h3>
                <br>
                <p style="text-align:center;">
                    iDiving 於2022年1月19日，由交通部觀光局<br>
                    東北角暨宜蘭海岸國家風景區管理處舉辦的<br>
                    GTS綠色旅行標章頒獎典禮上獲頒<br>
                    GTS綠⾊旅⾏標章
                </p>
                <br>
                <img src="../../../../assets/Image/Data/GTS_01.jpg" width="100%" style="display:block; margin:auto;" alt="" />
                <br>
                <img src="../../../../assets/Image/Data/GTS_02.jpg" width="100%" style="display:block; margin:auto;" alt="" />
                <br>
                <br>
                <br>
                <img src="../../../../assets/Image/Data/GTS_03.png" width="30%" style="display:block; margin:auto;" alt="" />
                <br>
                <br>
                <br>
                <p style="text-align:center;">
                    GTS綠⾊旅⾏標章為荷蘭「Green Destinations B.V.公司」(簡稱GD B.V.)創立的標章制度，<br>
                    提供企業一套檢視自己營運方式-是否有破壞環境，是否可以讓在地特色延續，<br>
                    以及讓自己下一代可以持續生活的標章系統。
                </p>
                <br>
                <br>
                <h3 style="text-align:center;">實行中的計畫</h3>
                <br>
                <img src="../../../../assets/Image/Data/Cleanbeach_01.jpg" width="100%" style="display:block; margin:auto;" alt="" />
                <br>
                <p style="text-align:center;">
                    定期舉辦淨灘、淨海，水肺潛水員協助下水淨海，自由潛水員協助岸上淨灘、垃圾分類，<br>
                    推動執行一人一袋的活動，倡導每次下水皆撿一些垃圾上岸，期盼增加潛水員環保觀念<br>
                </p>
                <br>
                <img src="../../../../assets/Image/Data/Cleanbeach_02.jpg" width="100%" style="display:block; margin:auto;" alt="" />
                <br>
                <p style="text-align:center;">
                    主辦或是協辦環境資訊協會執行珊瑚礁體檢
                </p>
                <br>
                <img src="../../../../assets/Image/Data/Cleanbeach_03.jpg" width="100%" style="display:block; margin:auto;" alt="" />
                <br>
                <p style="text-align:center;">
                    每年4月至10月是軟絲產卵期，然而海底珊瑚礁長期遭受破壞，故以桂竹來綁成扇型方式<br>
                    置入海中24米左右深度，成功吸引許多軟絲產卵。
                </p>
                <br>
            </div>
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>