<div class="container-fluid">
    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh"></td>
            </tr>
            <tr>
                <td></td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td></td>
            </tr>
            <tr>
                <td></td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">學潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">自由潛水教練班</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">

            <h3>自由潛水教練 FDI</h3>
            <hr />
            <img src="../../../assets/Image/Data/FDI_1.jpg" width="99%" style="display: block; margin: auto"
                alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
            <br>

            <!-- https://mdbootstrap.com/docs/angular/sections/blog/ -->
            <!-- 自由潛水教練 -->
            <div>
                    <!-- Level 1 自由潛水教練 -->
                <div class="row">
                    <!-- Grid column -->
                    <div class="col-lg-4">
                        <!-- Featured image -->
                        <div class="view overlay rounded mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                            <img src="../../../assets/Image/Data/FDI-L1-Freediver Instructor.jpg" alt="Sample image">
                            <a>
                                <div class="mask rgba-white-slight"></div>
                            </a>
                        </div>
                    </div>
                    <!-- Grid column -->
                    <div class="col-lg-8">
                        <!-- Post title -->
                        <h3 class="font-weight-bold mb-3" style="font-family:DFKai-sb;">Freediving Instructor 自由潛水教練</h3>
                        <!-- Excerpt -->
                        <p class="fs-4">
                            你是否曾經夢想過像海豚一樣游泳? 或者想知道只靠着一口氣在海洋滑翔是什麼感覺? 如果是，你應該了解更多關於SSI自由潛水課程，
                            在課程中，你將學習以自己的節奏進行自由潛水。 完成教練培訓課程後, 你就可以成為SSI 基礎自由潛水教練，
                            或是泳池自由潛水教練或是 Freediving Instructor 自由潛水教練。
                        </p>
                    </div>
                </div>
                <br>
                <!-- -->

                <!-- Level 2 自由潛水教練 -->
                <!-- Grid row -->
                <div class="row">
                    <!-- Grid column -->
                    <div class="col-lg-4">
                        <!-- Featured image -->
                        <div class="view overlay rounded mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                            <img src="../../../assets/Image/Data/FDI-L2-ADV.Freediver Instructor.jpg" alt="Sample image">
                            <a>
                                <div class="mask rgba-white-slight"></div>
                            </a>
                        </div>
                    </div>
                    <!-- Grid column -->
                    <div class="col-lg-8">
                        <!-- Post title -->
                        <h3 class="font-weight-bold mb-3" style="font-family:DFKai-sb;"><strong>Advanced Freediving Instructor 進階自由潛水教練</strong>
                        </h3>
                        <!-- Excerpt -->
                        <p class="fs-4">
                            Advanced Freediving Instructor 自由潛水教練為您提供攸關哺乳類動物潛水反射的更多知識，以及如何啟動最適用於自由潛水反射的刺激。
                            您將學習新的進階平壓技巧與閉氣訓練方法，帶領你超過20米，也發展自由潛水深達30米所需要的技巧。完成後，
                            您將成為SSI Advanced Freediving Instructor 自由潛水教練。
                        </p>
                    </div>
                    <!-- Grid column -->
                </div>
                <br>
                <!-- Level 3 自由潛水教練 -->
                <!-- Grid row -->
                <div class="row">
                    <!-- Grid column -->
                    <div class="col-lg-4">
                        <!-- Featured image -->
                        <div class="view overlay rounded mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                            <img src="../../../assets/Image/Data/FDI-L3-Performance Freediving Instructor.jpg" alt="Sample image">
                            <a>
                                <div class="mask rgba-white-slight"></div>
                            </a>
                        </div>
                    </div>
                    <!-- Grid column -->
                    <div class="col-lg-8">
                        <!-- Post title -->
                        <h3 class="font-weight-bold mb-3" style="font-family:DFKai-sb;"><strong>Performance Freediving Instructor 卓越自由潛水教練</strong>
                        </h3>
                        <!-- Excerpt -->
                        <p class="fs-4">
                            SSI Performance Freediving Instructor 自由潛水教練將帶你到40米的深度。進行肺部加壓至低於肺餘容積的自由潛水試煉，這是專業的深潛課程，並將激發您的潛水專業知識，
                            以帶您進入深藍未知的大海。完成後，您將取得SSI Performance Freediving Instructor 自由潛水教練。
                        </p>
                    </div>
                </div>
            </div>
            <br>
            <!-- 自由潛水教練班 課程規劃 -->
            <div>
                <div class="row">
                    <div class="col">
                        <h3 class="font-weight-bold mb-3" style="font-family:DFKai-sb;"><strong>【課程規劃】</strong></h3>
                        <ul style="vertical-align:text-top;">
                            <li class="fs-4">
                                教練班 Freediving Instructor Course (FITC) 自由潛水助理教練/自由潛水教練/進階自由潛水教練 : 7-10天課程
                            </li>
                        </ul>
                        <p class="fs-4">
                            課程條件:
                        </p>
                        <p class="fs-4">
                            須具備游泳能力及高一階的學生執照。<br>
                            舉例: 自由潛水教練要具備 進階自由潛水 學員的執照及須具備急救執照 React Right (SSI RR) 
                            或效期內之醫生／護理師急救執照或 EMT2 執照 具備 SSI 自潛專長 Training Techniques (SSI SP-TT)
                        </p>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <ul style="vertical-align:text-top;">
                            <li class="fs-4">
                                教練升等班<br>
                                助理教練升級至 自潛教練 或 進階自潛教練 : 3-6 天課程<br>
                                進階自潛教練升級至 卓越自潛教練 : 5-8 天課程
                            </li>
                        </ul>
                        <p class="fs-4">
                            課程條件:
                        </p>
                        <ol class="fs-4">
                            <li>完成教練班 FITC 課程</li>
                            <li>高一階的學生執照。舉例:自潛教練要具備 進階自由潛水 學員的執照</li>
                            <li>效期內之急救執照（認可急救執照如上）</li>
                        </ol>
                        <br>
                    </div>
                </div>

                <img src="../../../../assets/Image/Data/FDI_2.jpg" width="99%" style="display:block; margin:auto;" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                <br>
            </div>
            <!-- 自由潛水教練班 開課日期 -->
            <div>
                <h3 class="font-weight-bold mb-3" style="font-family:DFKai-sb;"><strong>【開課日期】</strong></h3>
                <ul class="fs-4">
                    <li>自由潛水教練 Freediving Instructor :夏季課程，請來電與教練一同規劃專屬於您的教練課程！</li>
                    <li>進階自由潛水教練 Advanced Freediving Instructor :秋季課程，每年10-11月視情況開班！</li>
                    <li>卓越自由潛水教練 Performance Freediving Instructor :特殊教練課程，只接受特別預定！</li>
                </ul>
            </div>
            <!-- 自由潛水教練班 超值學費 -->
            <div>
                <h3 class="font-weight-bold mb-3" style="font-family:DFKai-sb;"><strong>【超值學費】</strong></h3>
                <ul class="fs-4" style="vertical-align:text-top;">
                    <li>SSI 系統 全球領導級資料登入費用:6,000</li>
                    <li>自由潛水教練班:50,000，教材費用 7,000 另計</li>
                    <li>
                        <p>
                            自潛教練升級班:<br>
                            助理教練升級至 自潛教練 或 進階自潛教練:18,000，教材費用 4,000 另計<br>卓越自潛教練班:30,000
                        </p>
                    </li>
                    <li>
                        <p>
                            相關專長課程<br>
                            RR 5,000<br>
                            TT 8,000
                        </p>
                    </li>
                    <li>
                        <p>
                            自潛教練班套裝<br>
                        </p>
                        <ol>
                            <li>
                                如果您是 自由潛水員, 原價 91,000 的學費，現金優惠價格為 79,800<br>
                                包含:進階自由潛水員 15,000 + RR 5,000 + TT 8,000 + 教練班 50,000 + 教材費 7,000 + 登陸費 6,000
                            </li>
                            <li>
                                如果您是 進階自潛員, 原價 76,000 的學費, 現金優惠價格為 66,800<br>
                                包含:RR 5,000 + TT 8,000 + 教練班 50,000 + 教材費 7,000 + 登陸費 6,000
                            </li>
                            <li>
                                如果您有 RR 同等執照，套裝現金優惠價格可再扣 4,000
                            </li>
                            <li>
                                當您取得 自由潛水教練 教練執照，將同時具有 TT + FIM 兩項專長教練執照
                            </li>
                        </ol>
                    </li>
                </ul>
            </div>
            <!-- 自由潛水教練班 報名方式 -->
            <div>
                <app-paymentmethod></app-paymentmethod>
                <br>
                <a class="fs-4" href="mailto:kankp726@gmail.com">或請 Email 與阿甘教練聯繫</a>
                <br>
            </div>
            <!-- 自由潛水教練班 學前須知 -->
            <div>
                <br>
                <h3 class="font-weight-bold mb-3" style="font-family:DFKai-sb;"><strong>【學前須知】</strong></h3>
                <ol class="fs-4">
                    <li>
                        iDiving 自潛教練班非保證班教學（註）。所有課程須於 2 個月內完成 (9 月後報名隔年 5 月前完成)。約定日期之更改須在 15 天前告知。
                        15 天內更動或未告知而曠課，補課需額外負擔 1,000 元，身體不適，出示醫院掛號收據者不收。
                    </li>
                    <li>
                        若學員自行放棄課程，本訓練中心將不予退費；但因身體不適且經醫生證明不宜從事潛水活動者，繳交之訓練費用在扣除已支付的成本後，餘額將全數退還。
                    </li>
                    <li>
                        課程之訓練費用、器材之購買費用與 2 吋大頭照一張，請於術科前繳齊。
                    </li>
                    <li>
                        海洋實習地點為東北角或各移地結訓地，往返之交通請自行處理。
                    </li>
                    <li>
                        學員結訓後，訓練中心將於兩週內完成 SSI 國際數位潛水證照申請。（如有急需請先告知，本中心可於課程完成後的兩個工作天內完成申請）
                    </li>
                </ol>
                <a class="fs-4">
                    註:如果 Freediver 教練班各項要求無法達到結訓標準，訓練官會與您討論補訓的時間與相關費用，也可能降發 POOL 自潛教練
                </a>
            </div>
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td></td>
            </tr>
            <tr>
                <td></td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>
</div>