<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">玩潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">墾丁</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <h3 style="font-family:DFKai-sb;">墾丁</h3>
            <hr>
            <br>
            <!-- 景點介紹 -->
            <div>
                <br>
                <h3 style="font-family:DFKai-sb;">【景點介紹】</h3>
                <br>
                <!-- <img src="../../../assets/Image/Data/Kenting_1..jpg" style="float: right; width: 80%"> -->
                <table cellpadding="0" cellspacing="0" style="text-align: justify;">
                    <tr>
                        <td class="fs-6" width="55%">
                            <strong>墾丁</strong><br>
                            墾丁三面環海，因氣候及地理特色，海洋環境具有海水溫暖、海流呈現季節性變動、營養鹽豐富等特性。如此絕佳外在條件，造就海洋植群基礎生產力高，
                            浮游生物量豐富，珊瑚生長繁茂形成水族絕佳棲所。墾丁地區軟珊瑚種類多樣性常常令國外潛水客讚嘆不已，珊瑚王國美譽就是因此而來。<br><br>
                            <strong>氣候</strong><br>
                            墾丁不同於台灣其他地區的副熱帶氣候，屬於標準的熱帶氣候區，正因為三面環海，受海洋調劑，夏天雖然太陽大，確不會有悶熱的感覺，所以冬天不會比台灣北部冷，
                            夏天也不會比台灣北部熱。正因為氣候溫暖四季如春，清朝沈葆楨才將原本古名”瑯嶠”改為現在大家熟知的”恆春”。<br>
                        </td>
                        <td><img src="../../../assets/Image/Data/Kenting_1.jpg" style="float: right; width: 80%"></td>
                    </tr>
                </table>
                <br>
                <table cellpadding="0" cellspacing="0" style="text-align: justify;">
                    <tr>
                        <td class="fs-6">
                            <strong>陸遊景點</strong><br />
                            來到了墾丁，除了在白天享受風光明媚的美麗海景，到了晚上原本安靜的墾丁街搖身一變不夜城。來到此地，你可以享受各種美味的小吃，或是選購一些墾丁紀念品，
                            又或者挑間你喜歡的酒吧，坐下來喝杯調酒，感受這個地方的熱情與活力。除此之外，墾丁國家公園也十分適合全家一起出遊的好去處，國家公園內有許多特殊景觀例如茄冬巨木、
                            人工湖、石筍寶穴、銀葉板根、觀海樓、仙洞、一線天、垂榕谷、棲猿崖等旅遊景點非常值得遊客一遊。<br>
                            或者你也可以前往恆春鎮探訪恆春古城，恆春古城是台灣保留最完整的城門古蹟，已列為國家二級古蹟。四季如春有130年歷史的古城，
                            古城門的城牆以磚石灰土砌築而成的，高約2丈、厚約8尺，全長計972丈，建有東、西、南、北四個城門，內外門洞皆半圓拱，在上建有城樓。順著四個古城門走一遭，
                            踏著城牆的步道感受百年古城的歷史魅力，恆春古城有著其他城市無法取代的歷史片段。
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 著名潛點 -->
            <div>
                <br>
                <h3 style="font-family:DFKai-sb;">【著名潛點】</h3>
                <br>
                <table style="text-align: justify;">
                    <tr>
                        <td><img src="../../../assets/Image/Data/Kenting_2.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Kenting_3.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Kenting_5.jpg" width="100%"></td>
                    </tr>
                </table>
                <br>
                <table style="text-align: justify;">
                    <tr>
                        <td>
                            <ol class="fs-6">
                                <li>
                                    獨立礁：獨立礁是墾丁熱門的船潛點，礁體為長形，前端隆起部位俗稱龍頭，也是獨立礁名字的由來，此潛場最著名的明星就是28米的豆丁海馬，
                                    除此之外礁體上的軟珊瑚及各式魚群也是廣角拍照的最佳取景點。
                                </li>
                                <li>
                                    雙峰藍洞：墾丁最出名的峭壁潛水點之一，此處有兩個洞穴景觀可觀探訪，一個是由下往上鑽的小藍洞，另一個則是如海底隧道般狹小的洞穴，
                                    藉由洞口的光線照射呈現淡藍色波紋而得名，峭壁頂端更是觀看魚群頂流覓食的最佳地點，也是廣角拍照的取景點。
                                </li>
                                <li>
                                    大咾咕斜坡：大咾咕礁岩下方一帶全都是軟珊瑚，淺礁內的魚群數量十分可觀，有時順著水流做放流潛水是非常舒服的潛水方式，
                                    2016年在這發現的蝠魟Mobula更是讓潛水客流連忘返，每個潛水客都希望在海中偶遇這名稀客，哪怕是5秒的短暫相遇也讓大家開心不已。
                                </li>
                                <li>
                                    出水口：墾丁岸潛的朝聖地，也是水上活動及浮潛的最佳去處，夏天的出水口潛水客與水上活動混雜不好行動，
                                    只要一潛入水中迎面而來的黃金尾梭魚就是最佳的迎賓禮，不期而遇的海龜帶給大家快樂驚奇。幸運的潛水客還會遇到難得一見的隆頭鸚哥群，
                                    這也是出水口變成岸潛聖地的原因，因為在這裡我們總能跟大物偶遇，特別是沒帶相機下水的時刻。
                                </li>
                                <li>
                                    合界：墾丁西岸高級岸潛點，號稱台灣小藍碧的合界是最棒的微距天堂，每週末合界總是聚滿愛好微距攝影的潛水客，趴在30m沙地上進行膜拜朝聖。
                                    此外32m的合界沈船就像是沙漠裡的綠洲，獨特的生態圈也是潛水客的拍攝目標之一。
                                </li>
                            </ol>
                        </td>
                    </tr>
                </table>
                <br>
                <!-- <table style="text-align: justify;">
                    <tr>
                        <td><img src="../../../assets/Image/Data/Kenting_4.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Kenting_5.jpg" width="100%"></td>
                    </tr>
                </table> -->
            </div>
            <!-- 建議行程 -->
            <div>
                <br>
                <h3 style="font-family:DFKai-sb;">【建議行程】</h3>
                <br>
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>2 天 2 夜</a>
                        <ng-template ngbNavContent class="custom-class">
                            <!-- 行程說明 -->
                            <div>
                                <h3 style="font-family:DFKai-sb;">行程說明</h3>
                                <table style="text-align: justify;">
                                    <tr>
                                        <td class="fs-6">
                                            出發<br>
                                            交通方式可選擇：<br>
                                            一、行程前一天 19:00 於 iDiving 集合，搭乘專車南下，到達墾丁的潛莊潛水度假村約為凌晨 01:30。<br>
                                            二、自行前往：請於 01:30 前至潛莊潛水度假村（屏東縣恆春鎮南光路9號）
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            第一天<br>
                                            早上 08:00 起床，享用潛店提供的早餐後，可自費進行岸潛一支或 10:00
                                            將裝備整理並放置於潛水中心，午餐於潛店附近餐館用餐，下午進行船潛一趟二支，晚餐於恆春享用。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            第二天<br>
                                            早上 7:30 用餐，8:00 集合潛水，早上進行船潛一趟二支。約略於 12:00
                                            返回潛莊，清洗並整理裝備，盥洗後開始返回台北，午餐將在恆春路程中享用。
                                        </td>
                                    </tr>
                                </table>
                                <br />
                                <table style="text-align: justify;">
                                    <tr>
                                        <td><img src="../../../assets/Image/Data/Kenting_6.jpg" width="100%"></td>
                                        <td>&nbsp;</td>
                                        <td><img src="../../../assets/Image/Data/Kenting_7.jpg" width="100%"></td>
                                    </tr>
                                </table>
                            </div>
                            <!-- 費用說明 -->
                            <div>
                                <br>
                                <h3 style="font-family:DFKai-sb;">費用說明</h3>
                                <div class="fs-6">行程費用及更多活動詳情請洽iDiving Line&#64;
                                    <a href="https://lin.ee/ge19YeP">
                                        <img src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png" height="36" border>
                                    </a>
                                </div>
                                <table style="text-align: justify;">
                                    <tr class="fs-6">
                                        <td width="10%">費用包含：</td>
                                        <td>２夜住宿、３簡餐、船潛２趟共４支、三佰萬旅遊平安險。</td>
                                    </tr>
                                    <tr class="fs-6">
                                        <td style="white-space:nowrap">不包含：</td>
                                        <td>潛水裝備租借的費用及個人性質的花費。台北來回墾丁的費用。</td>
                                    </tr>
                                    <tr class="fs-6">
                                        <td colspan="2">如需共乘來回台北墾丁每人車資 2,000 元</td>
                                    </tr>
                                    <tr class="fs-6">
                                        <td colspan="2" style="white-space:nowrap">
                                            <a target="_blank" href="https://goo.gl/forms/AFgsrUnZYexX7L1i1">立即報名</a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink>3 天 3 夜</a>
                        <ng-template ngbNavContent>
                            <!-- 行程說明 -->
                            <div>
                                <h3 style="font-family:DFKai-sb;">行程說明</h3>
                                <table style="text-align: justify;">
                                    <tr>
                                        <td class="fs-6">
                                            出發<br>
                                            交通方式可選擇：<br>
                                            一、行程前一天 19:00 於 iDiving 集合，搭乘專車南下，到達墾丁的潛莊潛水度假村約為凌晨 01:30。<br>
                                            二、自行前往：請於 01:30 前至潛莊潛水度假村（屏東縣恆春鎮南光路9號）
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            第一天<br>
                                            早上 08:00 起床，享用潛店提供的早餐後，可自費進行岸潛一支或 10:00
                                            將裝備整理並放置於潛水中心，午餐於潛店附近餐館用餐，下午進行船潛一趟二支，晚餐於恆春享用。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            第二天<br>
                                            此日將會安排船潛一趟二支，時間將會依照潛店安排在上午或是下午。其餘時間將視情況自費增加岸潛，或自由活動。早餐在潛店享用，午餐、晚餐，將由領隊帶隊到附近餐館享用。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            第三天<br>
                                            早上 7:30 用餐，8:00 集合潛水，早上進行船潛一趟二支。約略於 12:00
                                            返回潛莊，清洗並整理裝備，盥洗後開始返回台北，午餐將在恆春路程中享用。
                                        </td>
                                    </tr>
                                </table>
                                <br />
                                <table style="text-align: justify;">
                                    <tr>
                                        <td><img src="../../../assets/Image/Data/Kenting_6.jpg" width="100%"></td>
                                        <td>&nbsp;</td>
                                        <td><img src="../../../assets/Image/Data/Kenting_7.jpg" width="100%"></td>
                                    </tr>
                                </table>
                            </div>
                            <!-- 費用說明 -->
                            <div>
                                <br>
                                <h3 style="font-family:DFKai-sb;">費用說明</h3>
                                <div class="fs-6">
                                    行程費用及更多活動詳情請洽iDiving Line&#64;
                                    <a href="https://lin.ee/ge19YeP">
                                        <img src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png" height="36" border>
                                    </a>
                                </div>
                                <table style="text-align: justify;">
                                    <tr class="fs-6">
                                        <td width="10%">費用包含：</td>
                                        <td>３夜住宿、５簡餐、船潛３趟共６支、三佰萬旅遊平安險。</td>
                                    </tr>
                                    <tr class="fs-6">
                                        <td style="white-space:nowrap">不包含：</td>
                                        <td>潛水裝備租借的費用及個人性質的花費。台北來回墾丁的費用。</td>
                                    </tr>
                                    <tr class="fs-6">
                                        <td colspan="2">如需共乘來回台北墾丁每人車資 2,000 元</td>
                                    </tr>
                                    <tr class="fs-6">
                                        <td colspan="2" style="white-space:nowrap">
                                            <a target="_blank" href="https://goo.gl/forms/AFgsrUnZYexX7L1i1">立即報名</a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </ng-template>
                    </li>
                </ul>

                <div [ngbNavOutlet]="nav" class="mt-2"></div>
                <!-- <pre>Active: {{ active }}</pre> -->

            </div>
            <!-- 注意事項 -->
            <div>
                <br>
                <h3 style="font-family:DFKai-sb;">【注意事項】</h3>
                <br>
                <ol class="fs-6">
                    <li>
                        填寫＂Google 表單＂報名，並繳交團費
                        <span class="Large Important">
                            NT$2,000的『定金』
                        </span>
                        才算完成報名流程，若以轉帳方式繳交定金，請將您的匯款日期及帳號末五碼與金額傳至iDiving官方Line
                        &#64;帳號，以便確認。若以現金（or匯款）繳交全額費用，享有現金優惠！
                    </li>
                    <li>
                        國內潛水旅遊活動會有成團人數要求，含領隊需達10人才會出團；
                        <span class="Large Important">
                            若因報名人數不足而無法出團，最遲將於出團日14天以前通知已報名活動之團員，『定金』可全數退還。
                        </span>
                    </li>
                    <li>
                        <span class="Large Important">
                            未達出團人數標準，行程前 30 天發布通知。
                        </span>
                    </li>
                    <li>代訂行程外的交通 ( 船票、機票、高鐵 ) 須在接到繳款通知後的 24 小時內付款。</li>
                    <li>對於活動需求 ( 交通、住宿、潛水安排等 ) 更動一次以上者，每次更動將酌收作業費用 500 元/次。</li>
                    <li>自訂機票請確認航班與日期資訊，個人的失誤可能造成延誤或取消部分行程，請恕無法退費。</li>
                    <li>當人數達到出團門檻，所有團員亦繳清費用時，則保證出團，若有人員臨時取消造成出團人數不足亦不受影響，而臨時退團人員的退費規則如下：<br />
                        <span class="Large Important">A. 活動開始前第十六日~三十日取消者，須支付團費定價的百分之十。</span><br />
                        <span class="Large Important">B. 活動開始前第八日~第十五日取消者，須支付團費定價的百分之三十。</span><br />
                        <span class="Large Important">C. 活動開始前第三日~第七日取消者，須支付團費定價的百分之五十。</span><br />
                        <span class="Large Important">D. 活動開始前兩日內取消，須支付團費全額。</span><br />
                        *若有溢繳或可退款的費用，可選擇來店退款或刷退（須視當時繳費方式），若要匯款轉帳退費須扣除手續費（每筆NT 30元），亦可選擇保留餘額轉至其他消費。
                    </li>
                    <li>若遇颱風、浪況不佳等不可抗拒之因素，基於安全考量，iDiving將保有取消活動之權利，已繳交的費用於扣除必要支出（如代訂大眾交通的退票手續費）後退還。</li>
                    <li>國內潛水旅遊皆為完整套裝行程，如有個人因素更動行程內容，恕不提供會員優惠。</li>
                    <li>住宿房間之房等差異（套房、雅房、通鋪等），如現場遇特殊情況，將由領隊視情況協調分配。若有特殊需求請事先提出。</li>
                    <li>須持有合格的潛水證照（國際潛水證照初級以上資格），若要參加特殊環境潛水（如：夜潛），需具備相關資格；部分活動可能會有額外條件要求。</li>
                    <li>活動行程以Line活動群組公布的資訊為準。潛點選擇由潛導視當時天候、海況、團隊狀況決定。進行潛水活動時採潛伴制進行，請彼此相互照應。</li>
                    <li>禁帶漁獵器材，亦禁止攜帶任何生物上岸，違者不得再參加後續潛水活動。</li>
                    <li>填寫＂Google表單＂報名者，視同同意以上所有條款。</li>
                </ol>
            </div>
            <!-- 注意事項
            <div>
                <br>
                <app-activitypleasenote></app-activitypleasenote>
                <br>
            </div> -->
            <!-- 協辦旅行社 -->
            <div>
                <br>
                <h3 style="font-family:DFKai-sb;">【協辦旅行社】</h3>
                <br>
                <div>iDiving 為了讓旅遊活動有保障，所有旅遊行程特別與旅行社偕同舉辦，讓你玩得開心又有保障。</div>
                <br />
                <table border="1" cellpadding="0" cellspacing="0" width="70%">
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">主辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">愛潛水股份有限公司</td>
                        <td>承辦人：愛潛水</td>
                        <td>行動電話：0909-097696</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2882-7696</td>
                        <td>FAX：02-2882-7656</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAILl：info&#64;idiving.com.tw</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市士林區前港街8號</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">協辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">萬陽旅行社有限公司</td>
                        <td>業務請洽：蘇恒璀</td>
                        <td>行動電話：0937-196518</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2517-1333</td>
                        <td>FAX：02-2508-1537</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAIL：tracy410&#64;ms45.hinet.net</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市南京東路3段91號四樓之三</td>
                    </tr>
                </table>
                <br>
            </div>
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>