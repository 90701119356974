<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">玩潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">俱樂部介紹</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <br>
            <h3 style="font-family:DFKai-sb;">俱樂部介紹</h3>
            <br>
            <br>
            <!-- 俱樂部介紹 宗旨 -->
            <div>
                <h3 style="font-family:DFKai-sb;">Serious diving serious fun</h3>
                <hr>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td width="1%" style="vertical-align:text-top;">
                                <p class="fs-6">
                                    iDiving 俱樂部成立之目的，是為了提供潛水同好一個培養潛水活力的環境<br>
                                    藉由各項潛水活動的進行，凝聚會員之間的向心力<br>
                                    並培養彼此互助合作的精神，增進潛水的樂趣與安全<br>
                                    不定期舉辦各類型潛水講座，同時提升會員的樂趣與知性<br>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br>
            </div>
            <!-- 俱樂部介紹 一起來 Happy -->
            <div>
                <h3 style="font-family:DFKai-sb;">一起來 Happy</h3>
                <hr>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td width="1%" style="vertical-align:text-top;">
                                <p class="fs-6">
                                    繳交俱樂部年費（NT 2,000元）即可享有會員資格<br>
                                    會員效期：依照加入日起算一年<br>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br>
            </div>
            <!-- 俱樂部介紹 會員專屬優惠 -->
            <div>
                <h3 style="font-family:DFKai-sb;">會員專屬優惠</h3>
                <hr>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td width="1%" style="vertical-align:text-top;">
                                <p class="fs-6">
                                    在 iDiving 購買器材時，可享有會員優惠價。<br>
                                    報名參與活動享會員優惠價。<br>
                                    參加iDiving潛水出團活動，租裝備將少算一天。<br>
                                    參加國內潛水旅遊時，團費享 NT 1,000 元折扣（不限次數）<br>
                                    參加國外潛水旅遊時，團費享 NT 2,000 元折扣（不限次數）<br>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br>
            </div>
            <!-- 俱樂部介紹 報名活動 -->
            <div>
                <h3 style="font-family:DFKai-sb;">報名活動</h3>
                <hr>
                <a id="Scubasignup" href="/#/dz02" target="_blank">東北角水肺活動報名表</a>
                <ul class="fs-6">
                    <li>每周３開放往後兩周活動報名</li>
                    <li>報名表內會列出活動地點</li>
                    <li>東北角活動無須繳交訂金，詳細規則請參考報名資訊</li>
                    <li>船潛活動每趟須繳交 $1,000 訂金，詳細規則請參考報名資訊</li>
                </ul>
                <br />
                <a href="/#/dz03" target="_blank">墾丁/離島及國外水肺活動報名表</a>
                <ul class="fs-6">
                    <li>每年年底會公布次年所有活動</li>
                    <li>所有出團將開Line群組告知應注意事項</li>
                    <li>報名後請務必繳交訂金，才算報名完成！</li>
                </ul>
                <br />
                <a id="FDsignup" href="/#/dz04" target="_blank">自由潛水活動報名</a>
                <ul class="fs-6">
                    <li>各項活動說明請參考報名表內資訊</li>
                    <li>夏季東北角活動為５月－９月</li>
                    <li>東北角活動時間規劃：每月將公布下月活動時間</li>
                </ul>
                <br />
                <a id="FDsignup"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSePrlXpJiWqS29NTZjMiFUluOT9sd88NnzPIs5BPokMUP4zqw/viewform"
                    target="_blank">環保活動報名表</a>
                <ul class="fs-6">
                    <li>每年年底會公布次年所有活動</li>
                    <li>報名表內會列出活動地點</li>
                    <li>東北角活動無須繳交訂金，詳細規則請參考報名資訊</li>
                </ul>
                <br>
            </div>
            <!-- 俱樂部介紹 裝備租借價格表 -->
            <div>
                <h3 style="font-family:DFKai-sb;">裝備租借價格表</h3>
                <hr>
                <table width="70%" style="text-align: justify;">
                    <tr align="center">
                        <td><img src="../../../assets/Image/Data/FDPriceList.png" width="100%"></td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr align="center">
                        <td><img src="../../../assets/Image/Data/ScubaPriceList.png" width="100%"></td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr align="center">
                        <td><img src="../../../assets/Image/Data/ERPriceList.png" width="100%"></td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td><span class="fs-6">註：iDiving 保有更改之權力，不另行通知，以網站上最新公告為準。</span></td>
                    </tr>
                </table>
                <br>
            </div>
            <!-- 俱樂部介紹 注意事項 -->
            <div>
                <h3 style="font-family:DFKai-sb;">注意事項</h3>
                <hr>
                <ul class="fs-6">
                    <li>參與俱樂部活動時，請多協助及指導新進會員，發揮互助合作精神</li>
                    <li>建議出席每年度的會員大會，以獲知相關訊息，資訊更新時將公告在 Facebook 的 iDiving Home 社團裡，請大家多多留意最新訊息。</li>
                    <li>若違反重大道德議題而不適合繼續參與俱樂部活動時，本俱樂部有權予以開除會籍</li>
                </ul>
                <br>
            </div>
            <!-- 俱樂部介紹 協辦旅行社 -->
            <div>
                <h3 style="font-family:DFKai-sb;">協辦旅行社</h3>
                <hr>
                <div>iDiving 為了讓旅遊活動有保障，所有旅遊行程特別與旅行社偕同舉辦，讓你玩得開心又有保障。</div>
                <br />
                <table border="1" cellpadding="0" cellspacing="0" width="70%">
                    <tr align="center">
                        <td class="fs-6" rowspan="4" style="vertical-align: middle">主辦單位</td>
                        <td class="fs-6" rowspan="4" style="vertical-align: middle">愛潛水股份有限公司</td>
                        <td class="fs-6">承辦人：愛潛水</td>
                        <td class="fs-6">行動電話：0909-097696</td>
                    </tr>
                    <tr align="center">
                        <td class="fs-6">TEL：02-2882-7696</td>
                        <td class="fs-6">FAX：02-2882-7656</td>
                    </tr>
                    <tr align="center">
                        <td class="fs-6" colspan="2">E-MAILl：info&#64;idiving.com.tw</td>
                    </tr>
                    <tr align="center">
                        <td class="fs-6" colspan="2">地址：台北市前港街8號</td>
                    </tr>
                    <tr align="center">
                        <td class="fs-6" rowspan="4" style="vertical-align: middle">協辦單位</td>
                        <td class="fs-6" rowspan="4" style="vertical-align: middle">萬陽旅行社有限公司</td>
                        <td class="fs-6">業務請洽：蘇恒璀</td>
                        <td class="fs-6">行動電話：0937-196518</td>
                    </tr>
                    <tr align="center">
                        <td class="fs-6">TEL：02-2517-1333</td>
                        <td class="fs-6">FAX：02-2508-1537</td>
                    </tr>
                    <tr align="center">
                        <td class="fs-6" colspan="2">E-MAIL：tracy410&#64;ms45.hinet.net</td>
                    </tr>
                    <tr align="center">
                        <td class="fs-6" colspan="2">地址：台北市南京東路3段91號四樓之三</td>
                    </tr>
                </table>
                <br>
            </div>
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>