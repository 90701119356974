import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coc100',
  templateUrl: './coc100.component.html',
  styleUrls: ['./coc100.component.scss']
})
export class Coc100Component implements OnInit {

  active = 1;
  
  constructor() { }

  ngOnInit(): void {
  }

}
