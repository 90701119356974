<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">玩潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">杜馬蓋地</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <h3 style="font-family:DFKai-sb;">杜馬蓋地</h3>
            <hr>
            <br>
            <!-- 景點介紹 -->
            <div>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="font-family:DFKai-sb;">【景點介紹】</h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="70%" cellpadding="0" cellspacing="0" style="text-align: justify;">
                    <tr>
                        <td width="55%">
                            <table cellpadding="0" cellspacing="0" width="100%">
                                <tr>
                                    <td>
                                        <strong>菲律賓</strong><br />
                                        菲律賓是由7107個島嶼組成，總面積30萬平方公里，這些島共分北中南三大區域，分別為呂宋(Luzon)、比薩亞(Visayas)、民答那峨(Mindanao)，菲律賓的首都是馬尼拉。<br /><br />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>氣候</strong><br />
                                        菲律賓是標準的熱帶氣候，氣候深受季風影響，3-5月為乾熱的季節、6-10月為多雨的季節、11-2月為涼爽的季節。1月最冷，5月最熱，平均溫度在攝氏25-32度，溼度77%。群島中氣候最溫和的地方是太平洋沿岸，包括呂宋島、薩摩島、雷伊泰島、民答那峨等。<br /><br />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>人文</strong><br />
                                        官方語言為英語與菲律賓語；菲律賓有8種方言是被多數人使用，其中Tagalog使用的人數最多，因此定為國語。信仰部分：有83%人信仰天主教、8%信仰回教、其他的屬於不同的基督教教派及佛教。<br /><br />
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td><img src="../../../assets/Image/Data/Philippines.png" style="float: right; width: 70%"></td>
                    </tr>
                </table>
                <br>
                <table width="70%" cellpadding="0" cellspacing="0" style="text-align: justify;">
                    <tr>
                        <td>
                            <strong>地點：杜馬蓋地 Dumaguete</strong><br />
                            菲律賓中部的visaya水域包括南Negros、Cebu、Bohol及最右方的Leyte 4大島之間的區域，而南Negros 省的首府Dumaguete
                            city更是其中最有名的，visaya位於颱風帶之外，全年均可潛水，杜市的人口眾多，文風鼎盛，共有三所大學及一家醫學院，市區各式餐廳林立，夜生活也是多彩多姿。此地主要的潛水resort大多位於距杜市機場車行30分鐘的Dauin區。
                        </td>
                    </tr>
                </table>
                <br>
                <table width="70%" cellpadding="0" cellspacing="0" style="text-align: justify;">
                    <tr>
                        <td><img src="../../../assets/Image/Data/Dumaguete_32.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Dumaguete_33.jpg" width="100%"></td>
                    </tr>
                </table>
            </div>
            <!-- 著名潛點 -->
            <div>
                <br>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="font-family:DFKai-sb;">【著名潛點】</h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="70%" style="text-align: justify;">
                    <tr>
                        <td><img src="../../../assets/Image/Data/Dumaguete_34.JPG" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Dumaguete_35.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Dumaguete_36.jpg" width="100%"></td>
                    </tr>
                </table>
                <br>
                <table width="70%" style="text-align: justify;">
                    <tr>
                        <td>一、Apo island：這是一個菲國很有名的海洋保護區，軟硬珊瑚生長完整茂盛，主要地形為slope+wall，在潛點Mamsa（當地菲語意為Jack
                            fish）的流區固定可以看到大群的Jack fish，島上有2家設備簡陋原始的潛水店，只有歐洲人才能接受這種方式。建議安排一天三潛Apo island，從Dauin
                            beach到此只需船行30分鐘。</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>二、Oslob Whale
                            shark：這是一個在宿霧島南端的潛點，剛好位於鯨鯊迴遊的路線上，剛開始當地漁民都會用誘餌把鯨鯊引開他們的漁網，以避免鯨鯊破壞漁網。幾年前，漁民開始把體型較小的年幼鯨鯊用誘餌引到
                            Tanawan 離岸不遠處以吸引觀光客。自此「賞鯨鯊」活動就成為奧斯樂博的重要旅遊活動，而該地方政府也順勢介入收取高額的賞鯊費用。</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>三、Dauin
                            sanctuary：地形是以黑細沙為主，水深30米處有一較完整的小沈船及汽車遺跡，但大多數的生物均在5~20米處，此點地形較廣，有許多的迷你的娃娃魚、海馬、鬼龍等等的生物。</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <table width="100%">
                                <tr>
                                    <td><img src="../../../assets/Image/Data/Dumaguete_37.jpg" width="100%"></td>
                                    <td>&nbsp;</td>
                                    <td><img src="../../../assets/Image/Data/Dumaguete_38.jpg" width="100%"></td>
                                    <td>&nbsp;</td>
                                    <td><img src="../../../assets/Image/Data/Dumaguete_39.jpg" width="100%"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 建議行程 -->
            <div>
                <br>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="font-family:DFKai-sb;">【建議行程】</h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="70%" style="text-align: justify;">
                    <tr>
                        <td>
                            第一天 11/1(三) 台北-宿霧-杜馬蓋地 5J-301/ DG-6515<br>
                            10/31晚上22:00在桃園機場第一航站，復興航空櫃台集合，搭乘飛機前往菲律賓-宿霧，即刻辦理轉機到杜馬蓋地。抵達後杜馬蓋地機場我們將安排專車前往渡假村CHECK
                            IN，並稍後休息，下午進行2次船潛。(因搭機時間非常早，又轉機會有約2個小時間可在機場內稍作休息用餐，故早餐自理。)<br>
                            <span class="Large Focus">早餐：自理，午餐、晚餐：由當地潛水店安排</span>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            第二天 11/2(四) APO ISLAND (船潛3支)<br>
                            早餐後隨即出發前往APO
                            ISLAND－這是菲國名列最佳潛點之一，在東南方禁獵區是一片漸斜坡連接著淺牆，常見大型迴游魚，如Jackfish、白鰭鯊、隆頭鸚哥等，以及成千上萬的小魚聚居在黑珊瑚叢林和洞穴中，而小丑魚，海葵和軟硬珊瑚更佈滿海床。<br>
                            <span class="Large Focus">早、午、晚：由當地潛水店安排起床準備</span>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            第三天11/3(五) OSLOB 鯨鯊潛水(船潛3支)<br>
                            早餐後隨即出發前往延著宿霧前往其東南邊的Oslob－2011.5左右，有個漁夫在小船上釣魚發現了鯨鯊，當時他一股腦把魚餌餵給鯨鯊，隔天，發現鯨鯊又來了，照慣例再餵鯨鯊，過沒多久，所有人都不捕魚了，目前這附近就有好幾隻鯨鯊待著，每天進去吃吃東西。告別鯨鯊，我們將前往Sumilon
                            Island 進行2支潛水，清澈見底的藍色海域，欣賞各種類的軟硬珊瑚、小丑魚、海葵、海蛞蝓。<br>
                            <span class="Large Focus">早、午、晚：由當地潛水店安排</span>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            第四天11/4(六) DAUIN潛水(船潛3支)<br>
                            早餐後出發前往附近潛點DAUIN，這裡常見藍點魟、石鱸、Jackfish、海龜、海兔、大片伸出沙底60公分覓食的花園鰻等，淺灘處可常見海龍、海馬、娃娃魚等，令您目不暇給。<br>
                            <span class="Large Focus">早、午餐：由當地潛水店安排 晚:將會至附近享用海鮮費用平均分攤</span>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            第五天11/5(日) 杜馬蓋地／馬尼拉／台北 5J-630/5J-310<br>
                            午餐後，專車接往機場搭機返回馬尼拉，抵達後前往國際機場搭機返回台灣桃園機場，結束難忘的杜馬蓋地潛水之旅。<br>
                            <span class="Large Focus">早、午：由當地潛水店安排 / 晚：自理</span>
                        </td>
                    </tr>
                </table>

            </div>
            <!-- 注意事項 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【注意事項】</h3>
                <table>
                    <tr>
                        <td>
                            <ol class="fs-6">
                                <li>填寫＂Google 表單＂報名，並繳交團費<span class="Large Important"> NT$5,000
                                        的『定金』</span>才算完成報名流程，若以轉帳方式繳交定金，請將您的匯款日期及帳號末五碼與金額傳至iDiving官方Line
                                        &#64;帳號，以便確認。若以現金（or匯款）繳交全額費用，享有現金優惠！</li>
                                <li>國內潛水旅遊活動會有成團人數要求，含領隊需達10人才會出團；<span
                                        class="Large Important">若因報名人數不足而無法出團，最遲將於出團日14天以前通知已報名活動之團員，『定金』可全數退還。</span>
                                </li>
                                <li><span class="Large Important">出團日30天以前會於Line的活動群組提供行程資料，若有任何需求請提出討論。</span></li>
                                <li>當人數達到出團門檻，所有團員亦繳清費用時，則保證出團，若有人員臨時取消造成出團人數不足亦不受影響，而臨時退團人員的退費規則如下：<br />
                                    <span class="Large Important">A. 活動開始前第十五日以上取消者，須賠償團費的百分之十。</span><br />
                                    <span class="Large Important">B. 活動開始前第五日至第十四日取消者，須賠償團費的百分之三十。</span><br />
                                    <span class="Large Important">C. 活動開始前第二日至第四日取消者，須賠償團費的百分之五十。</span><br />
                                    <span class="Large Important">D. 於活動當日或前一日取消，或未通知不參加者，須賠償團費全額。</span><br />
                                    *若有溢繳或可退款的費用，可選擇來店退款或刷退（須視當時繳費方式），若要匯款轉帳退費須扣除手續費（每筆NT 30元），亦可選擇保留餘額轉至其他消費。
                                </li>
                                <li>若遇颱風、浪況不佳等不可抗拒之因素，基於安全考量，iDiving將保有取消活動之權利，已繳交的費用於扣除必要支出（如代訂大眾交通的退票手續費）後退還。</li>
                                <li>國內潛水旅遊皆為完整套裝行程，如有個人因素更動行程內容，恕不提供會員優惠。</li>
                                <li>住宿房間之房等差異（套房、雅房、通鋪等），如現場遇特殊情況，將由領隊視情況協調分配。若有特殊需求請事先提出。</li>
                                <li>須持有合格的潛水證照（國際潛水證照初級以上資格），若要參加特殊環境潛水（如：夜潛），需具備相關資格；部分活動可能會有額外條件要求。</li>
                                <li>活動行程以Line活動群組公布的資訊為準。潛點選擇由潛導視當時天候、海況、團隊狀況決定。進行潛水活動時採潛伴制進行，請彼此相互照應。</li>
                                <li>禁帶漁獵器材，亦禁止攜帶任何生物上岸，違者不得再參加後續潛水活動。</li>
                                <li>填寫＂Google表單＂報名者，視同同意以上所有條款。</li>
                            </ol>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 協辦旅行社 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【協辦旅行社】</h3>
                <div>iDiving 為了讓旅遊活動有保障，所有旅遊行程特別與旅行社偕同舉辦，讓你玩得開心又有保障。</div>
                <br />
                <table border="1" cellpadding="0" cellspacing="0" width="70%">
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">主辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">愛潛水股份有限公司</td>
                        <td>承辦人：愛潛水</td>
                        <td>行動電話：0909-097696</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2882-7696</td>
                        <td>FAX：02-2882-7656</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAILl：info&#64;idiving.com.tw</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市士林區前港街8號</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">協辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">萬陽旅行社有限公司</td>
                        <td>業務請洽：蘇恒璀</td>
                        <td>行動電話：0937-196518</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2517-1333</td>
                        <td>FAX：02-2508-1537</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAIL：tracy410&#64;ms45.hinet.net</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市南京東路3段91號四樓之三</td>
                    </tr>
                </table>
                <br>
            </div>
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>