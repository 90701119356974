<div class="container-fluid">
    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh"></td>
            </tr>
            <tr>
                <td></td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td></td>
            </tr>
            <tr>
                <td></td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">學潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Performance Freediver </li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">

            <br>
            <img src="../../../assets/Image/Data/FD_Text1.png" width="99%" style="display: block; margin: auto"
                alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
            <br>
            <h3 style="white-space:nowrap; overflow:hidden; text-overflow:ellipsis">Performance Freediver</h3>
            <hr />
            <!-- 3級自由潛水 課程內容 -->
            <div>
                <table borderless="true">
                    <thead>
                        <tr>
                            <th colspan="2">
                                <h3 style="font-family:DFKai-sb;">【課程內容】</h3>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td width="1%" style="vertical-align:text-top;">
                                <ul>
                                    <li class="fs-6"></li>
                                </ul>
                            </td>
                            <td>
                                <p class="fs-6">
                                    學科（6hr）<br>
                                    拓展你的自由潛水生涯/專業自由潛水知識<br>
                                    專業自由潛水技巧/專業自由潛水安全
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="2%" style="vertical-align:text-top;">
                                <ul>
                                    <li class="fs-6"></li>
                                </ul>
                            </td>
                            <td>
                                <p class="fs-6">
                                    泳池訓練（4hr）<br />
                                    靜態閉氣指導，學員互相指導並協助至少閉氣3’30”<br>
                                    計畫並執行動態閉氣，包括75m雙蹼動態閉氣<br>
                                    穿戴配重於水深2-4米處，救援動態閉氣意識喪失潛水員
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="2%" style="vertical-align:text-top;">
                                <ul>
                                    <li class="fs-6"></li>
                                </ul>
                            </td>
                            <td>
                                <p class="fs-6">
                                    海洋訓練（8hr）<br />
                                    在10-12m處進行至少 1’30”熱身懸掛<br>
                                    FRC從水面用mouth fill潛水到至少10m<br>
                                    20m BO救援並執行25m水面拖帶<br>
                                    20m划手、無面鏡上升<br>
                                    30-40m恆重下潛，同時使用Mouth-fill及FreeFall
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <img src="../../../../assets/Image/Data/FD_Text2.png" width="99%" style="display: block; margin: auto"
                    alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
            </div>
            <!-- 3級自由潛水 課程特色 -->
            <div>
                <table borderless="true">
                    <thead>
                        <tr>
                            <th colspan="2">
                                <h3 style="font-family:DFKai-sb;"><br>【課程特色】</h3>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td width="1%" style="vertical-align:text-top;">
                                <ul>
                                    <li class="fs-6"></li>
                                </ul>
                            </td>
                            <td>
                                <p class="fs-6">
                                    成為最可靠的自由潛水員。
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ul>
                                    <li class="fs-6"></li>
                                </ul>
                            </td>
                            <td>
                                <p class="fs-6">
                                    完善你的平壓技巧。
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ul>
                                    <li class="fs-6"></li>
                                </ul>
                            </td>
                            <td>
                                <p class="fs-6">
                                    學習善用淺水域訓練。
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br>
                <img src="../../../../assets/Image/Data/FD_Text3.png" width="99%" style="display:block; margin:auto;"
                    alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
            </div>
            <!-- 3級自由潛水 課程規劃 -->
            <div>
                <table borderless="true">
                    <thead>
                        <tr>
                            <th colspan="2">
                                <h3 style="font-family:DFKai-sb;"><br>【課程規劃】</h3>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td width="2%">
                            </td>
                            <td>
                                <p class="fs-6">
                                    DAY 1 | 學科 （6hr）<br>
                                </p>
                                    <hr>
                                    <p class="fs-6">
                                    拓展你的自由潛水生涯/專業自由潛水知識<br>
                                    專業自由潛水技巧/專業自由潛水安全
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            </td>
                            <td>
                                <p class="fs-6">
                                    <br>DAY 2 | 泳池訓練（6hr）<br>
                                </p>
                                    <hr>
                                    <p class="fs-6">
                                    靜態閉氣指導，學員互相指導並協助至少閉氣3’30”<br>
                                    計畫並執行動態閉氣，包括75m雙蹼動態閉氣<br>
                                    穿戴配重於水深2-4米處，救援動態閉氣意識喪失潛水員
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            </td>
                            <td>
                                <p class="fs-6">
                                    <br>DAY 3 | 海洋訓練（12hr）<br>
                                </p>
                                    <hr>
                                    <p class="fs-6">
                                    在10-12m處進行至少 1’30”熱身懸掛<br>
                                    FRC從水面用mouth fill潛水到至少10m<br>
                                    20m BO救援並執行25m水面拖帶<br>
                                    20m划手、無面鏡上升<br>
                                    30-40m恆重下潛，同時使用Mouth-fill及FreeFall
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <p class="fs-6" style="color:red;">
                                    <br>註:實際下水時間將依照現場情況調整
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br>
                <img src="../../../../assets/Image/Data/FD_Text1.png" width="99%" style="display:block; margin:auto;"
                    alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
            </div>
            <!-- 3級自由潛水 課程學費 -->
            <!-- 方案  -->
            <div>
                <br>
                <br>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td class="fs-6">
                                <ul>
                                    <li>
                                        <p style="color:green;"> Performance Freediver :$17,000 元</p>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6">
                                <ul>
                                    <li>
                                        <p style="color:red;">現金優惠，折扣 $300 元。</p>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6">
                                <ul>
                                    <li>
                                        <p style="color:red;">人數優惠：兩人一起報名更優惠，每人優惠 500。三人同樂優惠加倍，每人優惠 1,000。<br></p>
                                        <p>費用包含：SSI線上教材、國際數位證照申請、教練費、泳池場地費、東北角海洋實習及保險。<br></p>
                                        <p>費用不含：海洋實習交通費、裝備、船潛費用。</p>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6">
                                <ul>
                                    <li>
                                        <a style="color:red;">輕裝優惠套裝，全套原價 2,580 元。</a>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src="../../../../assets/Image/Data/FD_17.png" width="100%" />
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6">
                                <ul>
                                    <li>
                                        <p style="color:green;">往後參加自潛活動費用：每次 750 元<br></p>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 3級自由潛水 報名方式 -->
            <app-paymentmethod></app-paymentmethod>
            <!-- 3級自由潛水 考核標準 -->
            <div>
                <br>
                <h3 style="font-family:DFKai-sb;">考核標準</h3>
                <hr>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td class="fs-6" colspan="2">
                                <p>學員結訓均需達到學科與術科課程標準之評鑑</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" width="90px" style="vertical-align:text-top;">
                                <p>學科:</p>
                            </td>
                            <td class="fs-6">
                                <p>需通過筆試測驗（成績達80分以上），未達標準需於檢討後重考。</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <p>泳池:</p>
                            </td>
                            <td class="fs-6">
                                <p>
                                    執導及被執導靜態閉氣3’30”、計畫並執行動態平潛75m<br>
                                    水面LMC救援、2-4m動態閉氣救援
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <p>深度:</p>
                            </td>
                            <td class="fs-6">
                                <p>
                                    10-12m熱身懸掛至少1’30”<br>
                                    用FRC於水面mouthfill至少10m<br>
                                    20m划手上升、20m無面鏡上升<br>
                                    20m BO救援並於水面拖帶25m<br>
                                    使用mouth fill及freefall進行30-40m恆重下潛，<br>
                                    並同時展現適當身體技巧。
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td class="fs-6" width="4%" style="vertical-align:text-top;">
                                <p>註:</p>
                            </td>
                            <td class="fs-6">
                                <p>
                                    1. Performance Freediver 課程為<a style="color: red;">非保證班</a>，如有未過則需繳交費用進行補考，費用請參閱下列說明。
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6">
                                <p> </p>
                            </td>
                            <td class="fs-6">
                                <p>
                                    2. Performance Freediver 課程，如泳池無法通過，則需繳交500元/次，進行補考。
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6">
                                <p> </p>
                            </td>
                            <td class="fs-6">
                                <p>
                                    3.5-9月 Performance Freediver 課程，每位學生有2次的海洋結訓機會，如果無法通過測驗，則需繳交費用 750 元/次進行海洋補考
                                </p>
                                <a style="color: red;">(不含船潛費用)。</a>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6">
                                <p> </p>
                            </td>
                            <td class="fs-6">
                                <p>
                                    4.10月-隔年4月冬季 Performance Freediver 課程，需配合<a style="color: red;">移地結訓</a>， 移地訓練費用不含在課程費用內。
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 3級自由潛水 上課地點 -->
            <div>
                <br>
                <h3 style="font-family:DFKai-sb;">上課地點</h3>
                <hr>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td class="fs-6" colspan="2">
                                <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                <p>教室學科:</p>
                            </td>
                            <td class="fs-6">
                                <p>iDiving士林前港店</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <p>泳池術科:</p>
                            </td>
                            <td class="fs-6">
                                <p>各配合泳池（需先到iDiving集合）</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <p>海洋實習: </p>
                            </td>
                            <td class="fs-6">
                                <p>夏季東北角（iDiving 東北角龍洞店）冬季潛立方（台中市西屯區安和西路 69 號）或 各移地結訓地</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <p>註: </p>
                            </td>
                            <td class="fs-6">
                                <p>
                                    ＊如遇天候不佳或泳池場地整修等，上課地點與時間可能視狀況調整，若有額外增加的場地費用，本中心將自行吸收（大台北區轉換場地之費用，台中泳池費用），不會另外收費。
                                    墾丁離島移地訓練產生之費用需自付。
                                    <br>＊學員不可因課程之"地點/時間"有調整而拒學並要求退費。
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 3級自由潛水 特約課程 -->
            <div>
                <br>
                <h3 style="font-family:DFKai-sb;">特約課程</h3>
                <hr>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>學科部份，每另外約一日課程加價 300 元</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <p>術科部份，每另外約一日課程加價 500 元</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>三人同行，一起約定特殊時間上課，不須額外加價。</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>指定教練費: 指定教練，加價 500 元/日，教練如果已安排既定課程或是出團，恕無法指定。</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 3級自由潛水 更改辦法 -->
            <div>
                <br>
                <h3 style="font-family:DFKai-sb;">更改辦法</h3>
                <hr>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>課程須於開始上課後的兩個月內完成。</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>上課日期之更改須在 10 天前告知</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 3級自由潛水 取消辦法 -->
            <div>
                <br>
                <h3 style="font-family:DFKai-sb;">取消辦法</h3>
                <hr>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>
                                    若因身體因素而不適合從事潛水活動，並開立醫生證明者，繳交之費用退費方式如下: NT 3,000
                                    元訂金用做購買線上教材，無法退費。
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6" style="vertical-align:text-top;">
                                <ul>
                                    <li></li>
                                </ul>
                            </td>
                            <td class="fs-6">
                                <p>
                                    退費金額依據繳交之費用總額，扣除行政及教材費用各階段扣除費用參考如下:<br>
                                    學員自行放棄課程，本訓練中心將不予退費。
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br>

                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 fs-6">
                    <ng-container matColumnDef="position">
                        <th mat-header-cell *matHeaderCellDef class="fs-6"> </th>
                        <td mat-cell *matCellDef="let element" class="fs-6"> {{element.position}} </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="fs-6"> 30天前</th>
                        <td mat-cell *matCellDef="let element" class="fs-6"> {{element.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="weight">
                        <th mat-header-cell *matHeaderCellDef class="fs-6"> 30天內</th>
                        <td mat-cell *matCellDef="let element" class="fs-6"> {{element.weight}} </td>
                    </ng-container>
                    <ng-container matColumnDef="symbol">
                        <th mat-header-cell *matHeaderCellDef class="fs-6"> 已上過學科 </th>
                        <td mat-cell *matCellDef="let element" class="fs-6"> {{element.symbol}} </td>
                    </ng-container>
                    <ng-container matColumnDef="symbol1">
                        <th mat-header-cell *matHeaderCellDef class="fs-6"> 已上過泳池 </th>
                        <td mat-cell *matCellDef="let element" class="fs-6"> {{element.symbol1}} </td>
                    </ng-container>
                    <ng-container matColumnDef="symbol2">
                        <th mat-header-cell *matHeaderCellDef class="fs-6"> 去過海洋 </th>
                        <td mat-cell *matCellDef="let element" class="fs-6"> {{element.symbol2}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <br>

            </div>
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td></td>
            </tr>
            <tr>
                <td></td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>
</div>