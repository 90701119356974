import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c120',
  templateUrl: './c120.component.html',
  styleUrls: ['./c120.component.scss']
})
export class C120Component implements OnInit {

  active = 3;

  constructor() { }

  ngOnInit(): void {
  }

}
