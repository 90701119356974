<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">玩潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">西巴丹</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <h3 style="font-family:DFKai-sb;">西巴丹</h3>
            <hr>
            <br>
            <!-- 景點介紹 -->
            <div>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="font-family:DFKai-sb;">【景點介紹】</h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table cellpadding="0" cellspacing="0" width="70%" style="text-align: justify;">
                    <tr>
                        <td>
                            <strong>馬來西亞</strong><br />
                            馬來西亞聯邦，大家稱馬來西亞(Malaysia)或簡稱「大馬」，位於東南亞，全境有13個州，分為東西兩大部分，中間隔著南海，西半部位於馬來半島，稱「西馬」，共11個州，東半部位於婆羅洲北部有沙巴(Sabah)與沙勞越(Sarawak)
                            2州，俗稱「東馬」。
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td><img src="../../../assets/Image/Data/Sipadan_01.jpg" width="100%"></td>
                    <tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>氣候</strong><br />
                            馬來西亞位置近於地球赤道，故其氣候為熱帶氣候，常年炎熱而多雨，空氣中的濕氣非常高，一年之中的溫差變化極小，平均溫度在26—29℃之間，全年雨量充沛，10—12月是雨季。在馬來西亞旅遊，基本上全年都適宜。白天雖然炎熱，但是午後有陣雨，晚上會有季風吹拂，十分涼爽。而針對西巴丹所在的東馬而言，11月到隔年的3月是雨季，其他時候都是高溫炎熱的好天氣。<br /><br />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>人文</strong><br />
                            馬來西亞是個多元種族特色的國家，主要的民族是：佔大多數而且是回教徒的馬來人(59％)，還有華人(32.1％)、印度人(8.2％)、及其他(0.7％)，在沙巴和砂勞越的原住民如：卡達山族、巴曹族、木如族、格拉比族、達雅族和依班等等。馬來人是馬來半島的較早住民，最早是由印尼移至馬來半島。華人移至馬來半島則較晚，主要是明朝及清朝兩代由中國大陸的廣東、福建及海南島移民至馬來西亞。使用的語言包括馬來語、英語、華語、印語、其中以馬來語為馬來西亞之國語。
                            對華人而言，有閩南話、福建話、廣東話、客家話、潮州話、海南話。馬來人(Malay)，原是從印尼而來。西馬中北邊較少，南部多些，最多是東馬。
                            印度人分成七、八種不同語言等。雖然馬來西亞為一回教國家，但它也確保其他民族的信仰自由。其他宗教有佛教、道教、印度教、天主教、興都教和基督教等。<br /><br />
                        </td>
                    </tr>
                </table>
                <table cellpadding="0" cellspacing="0" width="70%" style="text-align: justify;">
                    <tr>
                        <td>
                            <strong>地點</strong><br />
                            西巴丹島(Sipadan)除了以海龜聞名之外，還有三大魚種(隆頭鸚哥、梭魚、Jackfish)，不但數量龐大，而且定時定點，是一個深受各地熱愛潛水人士讚賞的「潛水樂園」，該島位於仙本那(Semporna)的岸外群島，即沙巴州東南方的西利比斯海面上的一個小島，地處北緯四度左右，雖極近赤道，卻甚涼爽，大馬政府向國內外宣傳這裡是「世界首屈一指的潛水天堂」，其島週圍的海底最深可達六百公尺，就如深海中的一座山。<br /><br />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            西巴丹島為世界頂尖的潛點，從600米的深淵流往北部蘇拉威西海，形成一個古老的火山。頂級的西巴丹潛水點，周圍佈滿星羅棋布當地珊瑚礁，您有可能看到鯊魚、海龜和其他大中上層魚類。輕鬆的延著海流潛水，西巴丹島周圍海域任何地方都擁有最大海龜種群。幾乎每一個西巴丹島都可以看到潛水綠海龜，甚至有龜洞。為了保護豐富的海洋多樣性的西巴丹島，島上已經為國家公園保護區，所有度假設施的基礎上西巴丹島本身移除，每日只開放120個名額許可進入西巴丹。<br /><br />
                        </td>
                    </tr>
                </table>
                <table cellpadding="0" cellspacing="0" width="70%" style="text-align: justify;">
                    <tr>
                        <td><img src="../../../assets/Image/Data/Sipadan_02.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Sipadan_03.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Sipadan_04.jpg" width="100%"></td>
                    </tr>
                </table>
            </div>
            <!-- 著名潛點 -->
            <div>
                <br>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="font-family:DFKai-sb;">【著名潛點】</h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table cellpadding="0" cellspacing="0" width="70%" style="text-align: justify;">
                    <tr>
                        <td>Barracuda Point</td>
                    </tr>
                    <tr>
                        <td>眾人去西巴丹島最愛的潛點，在此有機會遇到成群的梭魚與傑克風暴或隆頭鸚哥，在這兒多是放流潛水的形式居多，在後半段的沙地上也時常遇到滿地的花園鰻與各式礁鯊，是個令人期待的潛點。</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>The Drop Off/Sipadan Jetty</td>
                    </tr>
                    <tr>
                        <td>就在西巴丹島碼頭的正下方，屬於峭壁潛水，往下可以深到600米，有許多巨大的海扇。</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>Turtle Cavern</td>
                    </tr>
                    <tr>
                        <td>西巴丹島很具特色的景點之一，一個深藏水底的洞穴，可以深入海島65米深處，洞穴內有許多鐘乳石，也被稱為龜塚。洞穴入口處可見成群的魚群，但這個潛點只允許經驗豐富的洞穴潛水員進入深處探險。
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>Coral Gardens</td>
                    </tr>
                    <tr>
                        <td>遍地都是絢爛奪目的軟硬珊瑚，也是海龜與魟魚最佳觀賞據點。</td>
                    </tr>
                </table>
                <br>
                <table cellpadding="0" cellspacing="0" width="70%" style="text-align: justify;">
                    <tr>
                        <td><img src="../../../assets/Image/Data/Sipadan_05.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Sipadan_06.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Sipadan_07.jpg" width="100%"></td>
                    </tr>
                </table>
            </div>
            <!-- 建議行程 -->
            <div>
                <br>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="font-family:DFKai-sb;">【建議行程】</h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="70%" style="text-align: justify;">
                    <tr>
                        <td width="55%">
                            <table width="100%" style="text-align: justify;">
                                <tr>
                                    <td>台北TPE-亞庇BKI</td>
                                    <td>馬航 MH69 0800-1130</td>
                                </tr>
                                <tr>
                                    <td>亞庇BKI-斗湖TWU</td>
                                    <td>馬航 MH2137 1230-1320</td>
                                </tr>
                                <tr>
                                    <td>斗湖TWU-亞庇BKI</td>
                                    <td>馬航 MH3052 1245-1345</td>
                                </tr>
                                <tr>
                                    <td>亞庇BKI-台北TPE</td>
                                    <td>馬航 MH68 1735-2105</td>
                                </tr>
                            </table>
                            <br />
                            <table width="100%" style="text-align: justify;">
                                <tr>
                                    <td>
                                        第一天<br>
                                        台北~亞庇~斗湖~仙本那~馬寶<br>
                                        集合於桃園機場搭機飛往沙巴州首邑亞庇市，之後轉機飛往斗湖，抵達後經仙本那搭乘快艇前往馬寶島上。<br>
                                        <br>
                                        住宿:BORNEO DIVERS RESORT潛水渡假村
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>
                                        第二天 西巴丹馬寶 MABUL（船潛3支）<br>
                                        享用早餐後，隨即開始今日的潛水活動，西巴丹海底的地形以峭壁為主，只有梭魚坪、南端角這兩個潛點是斜坡和平台的地形。隨處可見的海龜、黃鰭鮪魚、隆頭鸚哥、梭魚群、Jackfish、白鰭鯊、灰礁鯊等大型魚類也常在此迴游。馬寶或卡巴賴附近的潛點有著多種海龍、葉魚、海馬、豆丁海馬、緞帶鰻等奇特的生物讓您目不暇給，是知名的微距攝影天堂。傍晚享用茶點後可於渡假村碼頭邊岸潛。(16:00/19:00岸潛無限支)
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>
                                        第三天 西巴丹馬寶 MABUL（每日船潛3支）<br>
                                        享用早餐後，專業潛導將再帶領各位投入這奧妙的海洋生態環境中與各式繽紛魚群共舞及觀賞海底生態奇觀之美。(16:00/19:00岸潛無限支)
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>
                                        第四天 西巴丹馬寶 MABUL（船潛3支）<br>
                                        享用早餐後，展開今日的潛水活動，無論是任何潛點都能依照各位的喜好做適當調整，滿足您對海底生態環境之探索與喜悅。
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>
                                        第五天 馬寶~仙本那~斗湖~亞庇~台北<br>
                                        享受一個無morning
                                        call的清晨，在渡假村悠閒的享用早餐後，再次漫步瀏覽這美麗的海洋，結束此趟愉快的海底觀光；隨後乘快艇至仙本那，轉搭巴士續往斗湖機場搭乘馬航國內班機抵達亞庇市。抵亞庇後續搭乘馬來西亞航空客機飛返台北。
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td><img src="../../../assets/Image/Data/Sipadan_08.png" style="float: right; width: 100%"></td>
                    </tr>
                </table>
            </div>
            <!-- 注意事項 -->
            <div>
                <br>
                <h3 style="font-family:DFKai-sb;">【注意事項】</h3>
                <table>
                    <tr>
                        <td>
                            <ol class="fs-6">
                                <li>填寫＂Google 表單＂報名，並繳交團費
                                    <span> 
                                        NT$5,000的『定金』
                                    </span>
                                    才算完成報名流程，若以轉帳方式繳交定金，請將您的匯款日期及帳號末五碼與金額傳至iDiving官方Line
                                    &#64;帳號，以便確認。若以現金（or匯款）繳交全額費用，享有現金優惠！
                                </li>
                                <li>國內潛水旅遊活動會有成團人數要求，含領隊需達10人才會出團；
                                    <span>
                                        若因報名人數不足而無法出團，最遲將於出團日14天以前通知已報名活動之團員，『定金』可全數退還。
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        出團日30天以前會於Line的活動群組提供行程資料，若有任何需求請提出討論。
                                    </span>
                                </li>
                                <li>
                                    當人數達到出團門檻，所有團員亦繳清費用時，則保證出團，若有人員臨時取消造成出團人數不足亦不受影響，而臨時退團人員的退費規則如下：<br />
                                    <span>A. 活動開始前第十五日以上取消者，須賠償團費的百分之十。</span><br />
                                    <span>B. 活動開始前第五日至第十四日取消者，須賠償團費的百分之三十。</span><br />
                                    <span>C. 活動開始前第二日至第四日取消者，須賠償團費的百分之五十。</span><br />
                                    <span>D. 於活動當日或前一日取消，或未通知不參加者，須賠償團費全額。</span><br />
                                    *若有溢繳或可退款的費用，可選擇來店退款或刷退（須視當時繳費方式），若要匯款轉帳退費須扣除手續費（每筆NT 30元），亦可選擇保留餘額轉至其他消費。
                                </li>
                                <li>若遇颱風、浪況不佳等不可抗拒之因素，基於安全考量，iDiving將保有取消活動之權利，已繳交的費用於扣除必要支出（如代訂大眾交通的退票手續費）後退還。</li>
                                <li>國內潛水旅遊皆為完整套裝行程，如有個人因素更動行程內容，恕不提供會員優惠。</li>
                                <li>住宿房間之房等差異（套房、雅房、通鋪等），如現場遇特殊情況，將由領隊視情況協調分配。若有特殊需求請事先提出。</li>
                                <li>須持有合格的潛水證照（國際潛水證照初級以上資格），若要參加特殊環境潛水（如：夜潛），需具備相關資格；部分活動可能會有額外條件要求。</li>
                                <li>活動行程以Line活動群組公布的資訊為準。潛點選擇由潛導視當時天候、海況、團隊狀況決定。進行潛水活動時採潛伴制進行，請彼此相互照應。</li>
                                <li>禁帶漁獵器材，亦禁止攜帶任何生物上岸，違者不得再參加後續潛水活動。</li>
                                <li>填寫＂Google表單＂報名者，視同同意以上所有條款。</li>
                            </ol>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 協辦旅行社 -->
            <div>
                <br>
                <h3 style="font-family:DFKai-sb;">【協辦旅行社】</h3>
                <div>iDiving 為了讓旅遊活動有保障，所有旅遊行程特別與旅行社偕同舉辦，讓你玩得開心又有保障。</div>
                <br />
                <table border="1" cellpadding="0" cellspacing="0" width="90%">
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">主辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">愛潛水股份有限公司</td>
                        <td>承辦人：愛潛水</td>
                        <td>行動電話：0909-097696</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2882-7696</td>
                        <td>FAX：02-2882-7656</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAILl：info&#64;idiving.com.tw</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市士林區前港街8號</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">協辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">萬陽旅行社有限公司</td>
                        <td>業務請洽：蘇恒璀</td>
                        <td>行動電話：0937-196518</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2517-1333</td>
                        <td>FAX：02-2508-1537</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAIL：tracy410&#64;ms45.hinet.net</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市南京東路3段91號四樓之三</td>
                    </tr>
                </table>
                <br>
            </div>
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>