<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">玩潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">小琉球</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <h1 style="font-family:DFKai-sb;">小琉球</h1>
            <hr>
            <br>
            <!-- 景點介紹 -->
            <div>
                <!-- <h3 style="font-family:DFKai-sb;">【景點介紹】</h3> -->
                <p class="fs-6">
                    小琉球是臺灣唯一的珊瑚礁島嶼，也是一年四季皆可潛水的聖地，航行時間只需20分鐘，又因黑潮支流的關係，水質中年清澈，溫暖的海洋，
                    孕育出獨特的海洋生態，這裡可以是潛旅人生中，第一次與海龜相遇的最佳潛點。
                </p>
            </div>
            <br>
            <!-- 著名推薦
            <div>
                <h3 style="font-family:DFKai-sb;">著名推薦</h3>
                <hr class="hr" style="width: 200px;border-width:5px;" align="left">
                <br>
                <img src="../../../assets/Image/Data/1695549354136.webp" width="100%">
                <br>
                <br>
                <a>
                    【花瓶岩】小琉球的熱門潛點，深度6~12米，魚群親人不怕生，也是海龜常出沒的地點，軟珊瑚的種類也不少。
                </a>
                <br>
                <br>
                <a>
                    【美人洞】這裡的海域寬廣，孕育豐富的海洋生態，同時也有大量海龜被吸引而來，因此是各潛點中海龜數量最多的一個。
                </a>
                <br>
                <br>
                <a>
                    【衫福漁港沈船】位於小琉球西北面杉福漁港外，最大深度23m內。水下沈船已經斷裂破碎，主要分為船頭和船身，
                    船頭在水深16-18m沙地與岩盤交界處，沿著下潛繩下潛便可看見船頭。船身距離船頭約100~200公尺外的大片沙地上，
                    深度約22-23m，船身周遭的沙地上都有許多花園鰻，踢到船尾就可以看到幾座電桿礁。
                </a>
                <br>
                <br>
                <table width="100%">
                    <tr>
                        <td><img src="../../../assets/Image/Data/Liuchiu_08.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Liuchiu_09.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Liuchiu_10.jpg" width="100%"></td>
                    </tr>
                </table>
                <br>
                <br>
            </div> -->
            <!-- 潛點推薦 -->
            <div>
                <h3 style="font-family:DFKai-sb;">潛點推薦</h3>
                <hr class="hr" style="width: 200px;border-width:5px;" align="left">
                <br>
                <img src="../../../assets/Image/Data/1695549354136.webp" width="100%">
                <div class="DiveSite01">
                    <div>
                        <br>
                        <br>
                        <br>
                        <p style="font-size: 25px; text-align: center;color: white;">
                            【花瓶岩】小琉球的熱門潛點，深度6~12米，魚群親人不怕生，也是海龜常出沒的地點，軟珊瑚的種類也不少。
                        </p>
                        <br>
                        <br>
                        <br>
                    </div>
                </div>
                <br>
                <br>
                <div class="DiveSite02">
                    <div>
                        <br>
                        <br>
                        <br>
                        <p style="font-size: 25px; text-align: center;color: white;">
                            【美人洞】這裡的海域寬廣，孕育豐富的海洋生態，同時也有大量海龜被吸引而來，因此是各潛點中海龜數量最多的一個。
                        </p>
                        <br>
                        <br>
                        <br>
                    </div>
                </div>
                <br>
                <br>
                <div class="DiveSite03">
                    <div>
                        <br>
                        <br>
                        <br>
                        <p style="font-size: 25px; text-align: center;color: white;">
                            【衫福漁港沈船】位於小琉球西北面杉福漁港外，最大深度23m內。水下沈船已經斷裂破碎，主要分為船頭和船身，
                            船頭在水深16-18m沙地與岩盤交界處，沿著下潛繩下潛便可看見船頭。船身距離船頭約100~200公尺外的大片沙地上，
                            深度約22-23m，船身周遭的沙地上都有許多花園鰻，踢到船尾就可以看到幾座電桿礁。
                        </p>
                        <br>
                        <br>
                        <br>
                    </div>
                </div>
                <br>
                <br>
            </div>
            <!-- 建議行程 -->
            <div>
                <h3 style="font-family:DFKai-sb;">建議行程</h3>
                <hr class="hr" style="width: 200px;border-width:5px;" align="left">
                <!-- 行程說明 -->
                <div>
                    <a>
                        二日建議行程：<br>
                        Day 1：早上搭高鐵出發，午餐後進行第一趟船潛 ( 2 支 )<br>
                        Day 2：上午進行第二趟船潛 ( 2 支 )，午餐後離開小琉球北返
                    </a>
                    <br>
                    <br>
                    <a>
                        三日建議行程：<br>
                        Day 1：早上搭高鐵出發，午餐後進行第一趟船潛 ( 2 支 )<br>
                        Day 2：上午進行第二趟船潛 ( 2 支 )，下午為自由活動<br>
                        Day 3：上午進行第三趟船潛 ( 2 支 )，午餐後離開小琉球北返
                    </a>
                    <br>
                    <br>
                    <a>
                        包含：東港<>小琉球來回船票、小琉球島上交通及食宿、 行程內的潛水活動、三百萬旅責險<br>
                            不含：出發地<>東港來回交通、裝備租借、個人消費
                    </a>
                    <br>
                    <br>
                    <button type="button" class="btn idiving-color btn-block"
                        onClick="window.open('https://www.idiving.com.tw/#/dz03')">行程費用 連結國內外報名表</button>
                    <br>
                    <br>
                    <a>
                        更多活動詳情請洽iDiving Line&#64;
                    </a>
                    <br>
                    <br>
                </div>
            </div>
            <!-- 注意事項 -->
            <div>
                <h3 style="font-family:DFKai-sb;">注意事項</h3>
                <hr class="hr" style="width: 200px;border-width:5px;" align="left">
                <table>
                    <tr>
                        <td>
                            <ol class="fs-6">
                                <li>填寫＂Google 表單＂報名，並繳交團費<span class="Large Important"> NT$5,000
                                        的『定金』</span>才算完成報名流程，若以轉帳方式繳交定金，請將您的匯款日期及帳號末五碼與金額傳至iDiving官方Line
                                        &#64;帳號，以便確認。若以現金（or匯款）繳交全額費用，享有現金優惠！</li>
                                <li>國內潛水旅遊活動會有成團人數要求，含領隊需達10人才會出團；<span
                                        class="Large Important">若因報名人數不足而無法出團，最遲將於出團日14天以前通知已報名活動之團員，『定金』可全數退還。</span>
                                </li>
                                <li><span class="Large Important">出團日30天以前會於Line的活動群組提供行程資料，若有任何需求請提出討論。</span>
                                </li>
                                <li>當人數達到出團門檻，所有團員亦繳清費用時，則保證出團，若有人員臨時取消造成出團人數不足亦不受影響，而臨時退團人員的退費規則如下：<br />
                                    <span class="Large Important">A. 活動開始前第十五日以上取消者，須賠償團費的百分之十。</span><br />
                                    <span class="Large Important">B. 活動開始前第五日至第十四日取消者，須賠償團費的百分之三十。</span><br />
                                    <span class="Large Important">C. 活動開始前第二日至第四日取消者，須賠償團費的百分之五十。</span><br />
                                    <span class="Large Important">D. 於活動當日或前一日取消，或未通知不參加者，須賠償團費全額。</span><br />
                                    *若有溢繳或可退款的費用，可選擇來店退款或刷退（須視當時繳費方式），若要匯款轉帳退費須扣除手續費（每筆NT 30元），亦可選擇保留餘額轉至其他消費。
                                </li>
                                <li>若遇颱風、浪況不佳等不可抗拒之因素，基於安全考量，iDiving將保有取消活動之權利，已繳交的費用於扣除必要支出（如代訂大眾交通的退票手續費）後退還。
                                </li>
                                <li>國內潛水旅遊皆為完整套裝行程，如有個人因素更動行程內容，恕不提供會員優惠。</li>
                                <li>住宿房間之房等差異（套房、雅房、通鋪等），如現場遇特殊情況，將由領隊視情況協調分配。若有特殊需求請事先提出。</li>
                                <li>須持有合格的潛水證照（國際潛水證照初級以上資格），若要參加特殊環境潛水（如：夜潛），需具備相關資格；部分活動可能會有額外條件要求。</li>
                                <li>活動行程以Line活動群組公布的資訊為準。潛點選擇由潛導視當時天候、海況、團隊狀況決定。進行潛水活動時採潛伴制進行，請彼此相互照應。</li>
                                <li>禁帶漁獵器材，亦禁止攜帶任何生物上岸，違者不得再參加後續潛水活動。</li>
                                <li>填寫＂Google表單＂報名者，視同同意以上所有條款。</li>
                            </ol>
                        </td>
                    </tr>
                </table>
                <br>
                <br>
            </div>
            <!-- 協辦旅行社 -->
            <div>
                <h3 style="font-family:DFKai-sb;">協辦旅行社</h3>
                <hr class="hr" style="width: 200px;border-width:5px;" align="left">
                <div>iDiving 為了讓旅遊活動有保障，所有旅遊行程特別與旅行社偕同舉辦，讓你玩得開心又有保障。</div>
                <br>
                <table class="table table-bordered" border="1">
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">主辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">愛潛水股份有限公司</td>
                        <td>承辦人：愛潛水</td>
                        <td>行動電話：0909-097696</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2882-7696</td>
                        <td>FAX：02-2882-7656</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAILl：info&#64;idiving.com.tw</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市士林區前港街8號</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">協辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">萬陽旅行社有限公司</td>
                        <td>業務請洽：蘇恒璀</td>
                        <td>行動電話：0937-196518</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2517-1333</td>
                        <td>FAX：02-2508-1537</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAIL：tracy410&#64;ms45.hinet.net</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市南京東路3段91號四樓之三</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>