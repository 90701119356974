<div class="container-fluid">
    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh"></td>
            </tr>
            <tr>
                <td></td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td></td>
            </tr>
            <tr>
                <td></td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">學潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">人魚體驗</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <br>
            <h3>人魚體驗 Try Mermaid</h3>
            <hr />
            <!-- 人魚體驗 課程介紹 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【課程介紹】</h3>
                <p class="fs-6">
                    人魚不單單是穿上魚尾，跳入海中，這麼簡單！iDiving最注重的安全與正確水中的踢動，不但適合各同年齡層的人，也可以讓你帶有 Aqua man
                    的英姿、童話故事中小人魚優雅，
                    一門可以更貼近海洋生物的課程。現在！立即了解課程！享受大海享受優美吧！
                </p>
                <br>
                <br>
                <img src="../../../assets/Image/Data/MMT_01.png" width="99%" style="display: block; margin: auto" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                <br>
                <br>
            </div>
            <!-- 人魚體驗 課程內容 -->
            <div>
                <table borderless="true">
                    <thead>
                        <tr>
                            <th colspan="2">
                                <h3 style="font-family:DFKai-sb;">【課程內容】</h3>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td width="1%" style="vertical-align:text-top;">
                                <ul>
                                    <li class="fs-6"></li>
                                </ul>
                            </td>
                            <td>
                                <p class="fs-6">
                                    人魚技巧應用 / 裝備使用/人魚裝備穿著/人魚水里適應
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="2%" style="vertical-align:text-top;">
                                <ul>
                                    <li class="fs-6"></li>
                                </ul>
                            </td>
                            <td>
                                <p class="fs-6">
                                    預約制, 不接受現場報名
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="2%" style="vertical-align:text-top;">
                                <ul>
                                    <li class="fs-6"></li>
                                </ul>
                            </td>
                            <td>
                                <p class="fs-6">
                                    2-3 小時
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <img src="../../../../assets/Image/Data/MMT_02.png" width="99%" style="display: block; margin: auto" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
            </div>
            <!-- 人魚體驗 課程學費 -->
            <div>
                <br>
                <br>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td class="fs-6">
                                <ul>
                                    <li>
                                        人魚體驗 TRY Mermaid<br>
                                        兩米深水肺體驗 2,500 元，兩人同行價 4,000。<br>
                                        五米深水肺體驗 3,500 元，兩人同行價 6,000。
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p>體驗完立即報名人魚課程，學費可折抵 1,000 元。</p>
            </div>
            <!-- 人魚體驗 報名方式 -->
            <app-paymentmethod></app-paymentmethod>

        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td></td>
            </tr>
            <tr>
                <td></td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>
</div>