import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cfd140',
  templateUrl: './cfd140.component.html',
  styleUrl: './cfd140.component.scss'
})
export class Cfd140Component {

  active = 1;

  constructor() { }

  ngOnInit(): void {
  }

}
