<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">玩潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">帛琉</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <h3 style="font-family:DFKai-sb;">帛琉</h3>
            <hr>
            <br>
            <!-- 景點介紹 -->
            <div>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="font-family:DFKai-sb;">【景點介紹】</h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="70%" cellpadding="0" cellspacing="0" style="text-align: justify;">
                    <tr>
                        <td>
                            <table width="100%">
                                <tr>
                                    <td width="55%">
                                        <table cellpadding="0" cellspacing="0" width="100%">
                                            <tr>
                                                <td>
                                                    <strong>帛琉共和國</strong><br />
                                                    是太平洋上的島國，位於菲律賓群島以東500公里。帛琉1994年從美國的託管統治下獨立。主要產業是漁業和旅遊業，主要宗教信仰是基督教、天主教等等，70%人口屬密克羅尼西亞群島當地原住民，其餘人口主要是菲律賓人
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>氣候</strong><br />
                                                    四季如夏，年平均溫度攝氏29度，平均水溫攝氏27度~29度，7~4月是乾季，雨季分佈在7~10月份。
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td><img src="../../../assets/Image/Data/Palau_01.jpg"
                                            style="float: right; width: 85%"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>人文</strong><br />
                            當地居民約20,000人，其中大部分為帛琉土著，屬密克羅尼西亞人。少數族裔有來自菲律賓為主的亞洲人和少量歐洲人。三分之二的居民信奉基督教，當中天主教徒佔大多數，其餘的信奉其他新教派系。除了基督教外，當地一種叫Modekngei的地方宗教也十分流行。
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>地點：帛琉</strong><br />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            有彩虹盡頭之稱的帛琉，之前連續被世界知名的洛戴爾潛水雜誌（Rodale’s Scuba Diving
                            Magazine）評選為世界上最棒的潛水景點之一。當地的潛水條件極佳，並擁有完整的海洋生態與現代化的旅遊設施，使得帛琉一直是舉世公認最值得前往潛水旅遊的好去處。海面下的能見度極高，除了生態豐富外，還有精彩的海底峭壁、神祕的沈船和戰機殘骸。當然，也很有機會與大型海底生物相遇，包括到處可見的鯊魚喔，這趟海底之旅保證刺激過癮！
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>帛琉共和國是個極適合發展潛水旅遊的國度，它是由八個連綿的島嶼與無數的小島所組成的國家，每座島嶼都充滿不同的特色；北方諸島多為火山島，南方則多為珊瑚礁島。四季如夏，年平均溫度攝氏二十九度，平均水溫攝氏二十七度～二十九度，非常適合潛水旅遊。
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 著名潛點 -->
            <div>
                <br>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="font-family:DFKai-sb;">【著名潛點】</h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="70%" style="text-align: justify;">
                    <tr>
                        <td>
                            <table width="100%">
                                <tr>
                                    <td><img src="../../../assets/Image/Data/Palau_02.jpg" width="100%"></td>
                                    <td>&nbsp;</td>
                                    <td><img src="../../../assets/Image/Data/Palau_03.jpg" width="100%"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <ol>
                                <li>Blue
                                    Hole藍洞：帛琉最知名的潛點之一，天氣好的時候從洞內往外張望，從水面上灑下來的那一抹藍光，是令人屏息的奇特景觀，光影的折射及水波萬般流轉，會讓您忍不住發出驚嘆。
                                </li>
                                <li>German Channel德國水道：這裡號稱是Manta
                                    清潔站，可以欣賞魔鬼魟翱翔的壯觀。德國水道，並不是天然的水道，而是二次世界大戰期間，德國礦產公司為了運輸方便所開鑿出來，連接珊瑚礁淺灘至外海的一條水道。</li>
                                <br>
                                <table width="100%">
                                    <tr>
                                        <td><img src="../../../assets/Image/Data/Palau_09.jpg" width="100%"></td>
                                        <td>&nbsp;</td>
                                        <td><img src="../../../assets/Image/Data/Palau_10.jpg" width="100%"></td>
                                        <td>&nbsp;</td>
                                        <td><img src="../../../assets/Image/Data/Palau_11.jpg" width="100%"></td>
                                    </tr>
                                </table>
                                <li>Big Drop off大斷層：這是一個又大又深的斷層，由海平面深入285公尺深，可以說是世界上最棒的牆潛(wall
                                    dive)。大斷層有帛琉海底花園之稱，您會被大量的珊瑚礁魚群所驚艷，及各種色彩繽紛的海扇與軟珊瑚，可愛的蘇眉魚會來向您打招呼，不時還可以欣賞到黑鰭礁鯊優游。</li>
                                <li>Ulong Channel烏龍水道：位於Ulong
                                    島西緣的水道，底部的沙地僅約13公尺，兩旁的緩坡到處是海洋生物群聚，是個非常特殊的潛點。通常由隧道北邊下潛到約20公尺處，在水道入口處可使用流鉤固定，欣賞經過的大型魚類，例如：鯊魚、鮪魚等。
                                </li>
                                <li>Blue Corner藍角：帛琉知名大魚聚集的地點，可能是帛琉最受歡迎的潛點。由一片向海裡延伸的壯觀懸崖所形成，深度約30公尺。</li>
                                <br>
                            </ol>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table width="100%">
                                <tr>
                                    <td><img src="../../../assets/Image/Data/Palau_12.jpg" width="100%"></td>
                                    <td>&nbsp;</td>
                                    <td><img src="../../../assets/Image/Data/Palau_13.jpg" width="100%"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 建議行程 -->
            <div>
                <br>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="font-family:DFKai-sb;">【建議行程】</h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>龍馬號船宿</a>
                        <ng-template ngbNavContent class="custom-class">
                            <!-- 行程說明 -->
                            <div>
                                <h3 style="font-family:DFKai-sb;">行程說明</h3>
                                <table width="70%" style="text-align: justify;">
                                    <tr>
                                        <td>
                                            <table width="100%">
                                                <tr>
                                                    <td><img src="../../../assets/Image/Data/Palau_06.jpg" width="100%">
                                                    </td>
                                                    <td>&nbsp;</td>
                                                    <td><img src="../../../assets/Image/Data/Palau_07.jpg" width="100%">
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            第一天　台北-帛琉<br>
                                            早上11:00在桃園機場第一航站，華航航空櫃台集合，搭乘飛機前往帛琉，在機上享用機上午餐，到帛琉後，會先至路上的漢堡店享用晚餐，隨即轉車到馬拉卡爾碼頭，準備登上龍馬一號船宿船。
                                            <br>
                                            <br>
                                            早餐：自理，中餐：機上餐，晚餐：自理，住宿：龍馬一號
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            第二天 ～ 第四天　船宿~龍馬號<br>
                                            這三日安排總共安排11支的船宿船潛，三餐皆都在船上享用喔！
                                            <br>
                                            <br>
                                            早餐/午餐/晚餐：龍馬一號船上buffet，住宿：龍馬一號
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            第五天　帛琉-台北<br>
                                            早上睡到自然醒, 回程會先前往牛奶湖，預計下午三點左右下船前往機場，搭機返回台北。
                                            <br>
                                            <br>
                                            早餐/午餐：龍馬一號船上buffet
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%">
                                                <tr>
                                                    <td><img src="../../../assets/Image/Data/Palau_04.jpg" width="100%">
                                                    </td>
                                                    <td>&nbsp;</td>
                                                    <td><img src="../../../assets/Image/Data/Palau_05.jpg" width="100%">
                                                    </td>
                                                    <td>&nbsp;</td>
                                                    <td><img src="../../../assets/Image/Data/Palau_08.jpg" width="100%">
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <h3 style="font-family:DFKai-sb;">費用說明</h3>
                                <div class="fs-6">行程費用及更多活動詳情請洽iDiving Line&#64;
                                    <a href="https://lin.ee/ge19YeP">
                                        <img src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png"
                                            height="36" border>
                                    </a>
                                </div>
                                <table style="text-align: justify;">
                                    <tr class="fs-6">
                                        <td width="10%">費用包含：</td>
                                        <td>２夜住宿、３簡餐、船潛２趟共４支、三佰萬旅遊平安險。</td>
                                    </tr>
                                    <tr class="fs-6">
                                        <td style="white-space:nowrap">不包含：</td>
                                        <td>潛水裝備租借的費用及個人性質的花費。台北來回墾丁的費用。</td>
                                    </tr>
                                    <tr class="fs-6">
                                        <td colspan="2">如需共乘來回台北墾丁每人車資 2,000 元</td>
                                    </tr>
                                    <tr class="fs-6">
                                        <td colspan="2" style="white-space:nowrap">
                                            <a target="_blank" href="https://goo.gl/forms/AFgsrUnZYexX7L1i1">立即報名</a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink>獵人三號船宿</a>
                        <ng-template ngbNavContent>
                            <!-- 行程說明 -->
                            <div>
                                <h3 style="font-family:DFKai-sb;">行程說明</h3>
                                <table width="70%" style="text-align: justify;">
                                    <tr>
                                        <td>
                                            <table width="100%">
                                                <tr>
                                                    <td><img src="../../../assets/Image/Data/Palau_17.jpg" width="100%"></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            第一天<br>
                                            11:15於桃園第一航廈集合，搭乘13:15的華航CI 28 飛往帛琉18:15 機場，接送至潛水中心報到後，至旁邊碼頭登上海洋獵人III。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            第2~6天<br>
                                            航程會從科羅出發依次會經過烏龍區域、德國水道區域、貝里琉區域，可選其中一天安排水母湖行程（自費水母湖稅金）。<br>
                                            每日行程如下：<br />
                                            起床06:00-6:30，享用咖啡、茶和麵包<br />
                                            早餐08:30、午餐12:00、晚餐19:00<br />
                                            日間潛水07:00、10:00、13:00、16:00<br />
                                            視情況進行夜潛18:00
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%">
                                                <tr>
                                                    <td><img src="../../../assets/Image/Data/Palau_18.jpg" width="100%"></td>
                                                    <td>&nbsp;</td>
                                                    <td><img src="../../../assets/Image/Data/Palau_19.jpg" width="100%"></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            第7天<br />
                                            在烏龍（Ulong）海域潛水後，我們將回到科羅。預計傍晚時間返回碼頭，船員將會幫您清洗潛水器材。本日無夜潛計畫。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            第8天<br />
                                            早上7:00享用早餐後，于8:00離開海洋獵人III。預計自費半日陸遊。17:05至機場搭乘19:05的華航CI27班機返回台北，預計抵達時間21:45。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%">
                                                <tr>
                                                    <td><img src="../../../assets/Image/Data/Palau_20.jpg" width="100%"></td>
                                                    <td>&nbsp;</td>
                                                    <td><img src="../../../assets/Image/Data/Palau_21.jpg" width="100%"></td>
                                                    <td>&nbsp;</td>
                                                    <td><img src="../../../assets/Image/Data/Palau_22.jpg" width="100%"></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </ng-template>
                    </li>
                </ul>

                <div [ngbNavOutlet]="nav" class="mt-2"></div>
                <!-- <pre>Active: {{ active }}</pre> -->

            </div>
            <!-- 注意事項 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【注意事項】</h3>
                <table>
                    <tr>
                        <td>
                            <ol class="fs-6">
                                <li>填寫＂Google 表單＂報名，並繳交團費<span class="Large Important"> NT$5,000
                                        的『定金』</span>才算完成報名流程，若以轉帳方式繳交定金，請將您的匯款日期及帳號末五碼與金額傳至iDiving官方Line
                                        &#64;帳號，以便確認。若以現金（or匯款）繳交全額費用，享有現金優惠！</li>
                                <li>國內潛水旅遊活動會有成團人數要求，含領隊需達10人才會出團；<span
                                        class="Large Important">若因報名人數不足而無法出團，最遲將於出團日14天以前通知已報名活動之團員，『定金』可全數退還。</span>
                                </li>
                                <li><span class="Large Important">出團日30天以前會於Line的活動群組提供行程資料，若有任何需求請提出討論。</span></li>
                                <li>當人數達到出團門檻，所有團員亦繳清費用時，則保證出團，若有人員臨時取消造成出團人數不足亦不受影響，而臨時退團人員的退費規則如下：<br />
                                    <span class="Large Important">A. 活動開始前第十五日以上取消者，須賠償團費的百分之十。</span><br />
                                    <span class="Large Important">B. 活動開始前第五日至第十四日取消者，須賠償團費的百分之三十。</span><br />
                                    <span class="Large Important">C. 活動開始前第二日至第四日取消者，須賠償團費的百分之五十。</span><br />
                                    <span class="Large Important">D. 於活動當日或前一日取消，或未通知不參加者，須賠償團費全額。</span><br />
                                    *若有溢繳或可退款的費用，可選擇來店退款或刷退（須視當時繳費方式），若要匯款轉帳退費須扣除手續費（每筆NT 30元），亦可選擇保留餘額轉至其他消費。
                                </li>
                                <li>若遇颱風、浪況不佳等不可抗拒之因素，基於安全考量，iDiving將保有取消活動之權利，已繳交的費用於扣除必要支出（如代訂大眾交通的退票手續費）後退還。</li>
                                <li>國內潛水旅遊皆為完整套裝行程，如有個人因素更動行程內容，恕不提供會員優惠。</li>
                                <li>住宿房間之房等差異（套房、雅房、通鋪等），如現場遇特殊情況，將由領隊視情況協調分配。若有特殊需求請事先提出。</li>
                                <li>須持有合格的潛水證照（國際潛水證照初級以上資格），若要參加特殊環境潛水（如：夜潛），需具備相關資格；部分活動可能會有額外條件要求。</li>
                                <li>活動行程以Line活動群組公布的資訊為準。潛點選擇由潛導視當時天候、海況、團隊狀況決定。進行潛水活動時採潛伴制進行，請彼此相互照應。</li>
                                <li>禁帶漁獵器材，亦禁止攜帶任何生物上岸，違者不得再參加後續潛水活動。</li>
                                <li>填寫＂Google表單＂報名者，視同同意以上所有條款。</li>
                            </ol>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 協辦旅行社 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【協辦旅行社】</h3>
                <div>iDiving 為了讓旅遊活動有保障，所有旅遊行程特別與旅行社偕同舉辦，讓你玩得開心又有保障。</div>
                <br />
                <table border="1" cellpadding="0" cellspacing="0" width="90%">
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">主辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">愛潛水股份有限公司</td>
                        <td>承辦人：愛潛水</td>
                        <td>行動電話：0909-097696</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2882-7696</td>
                        <td>FAX：02-2882-7656</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAILl：info&#64;idiving.com.tw</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市士林區前港街8號</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">協辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">萬陽旅行社有限公司</td>
                        <td>業務請洽：蘇恒璀</td>
                        <td>行動電話：0937-196518</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2517-1333</td>
                        <td>FAX：02-2508-1537</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAIL：tracy410&#64;ms45.hinet.net</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市南京東路3段91號四樓之三</td>
                    </tr>
                </table>
                <br>
            </div>
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>