<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">學潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">專長潛水員</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">

            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a class="fs-4" ngbNavLink>基礎專長</a>
                    <ng-template ngbNavContent class="custom-class">

                        <ul ngbNav #nav="ngbNav" [(activeId)]="active_01" class="nav-tabs">
                            <li [ngbNavItem]="1">
                                <a class="fs-4" ngbNavLink>夜潛</a>
                                <ng-template ngbNavContent>

                                    <br>
                                    <h3 style="font-family:DFKai-sb;">夜潛 Night Limited Visibility (NL)</h3>
                                    <hr>
                                    <!-- 課程內容 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">開課內容</h3>
                                        <ul>
                                            <li class="fs-6">
                                                學科（3hr）<br>
                                                夜潛與低能見度的定義 / 夜潛需要的特殊裝備<br>
                                                規劃合適的夜潛行程 / 燈光訊號的使用方式<br>
                                                燈具故障時的處理方式
                                            </li>
                                            <li class="fs-6">
                                                海洋訓練（6hr）<br>
                                                黃昏低能見度體驗 / 燈具保養<br>
                                                燈光訊號使用 / 燈具故障模擬
                                            </li>
                                        </ul>
                                        <br>
                                        <img src="../../../../assets/Image/Data/SC_NL_01.png" width="60%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程特色 -->
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>課程特色</h3>
                                        <ul class="fs-6">
                                            <li>
                                                天黑之後仍可進行潛水活動。
                                            </li>
                                            <li>
                                                海洋課程期間提供調節器、B.C.D.、電腦錶、潛水燈。
                                            </li>
                                            <li>
                                                平日 / 假日 均有開課，課程安排很方便。
                                            </li>
                                            <li>
                                                領導級人員對學員比例 1：3，教練時時刻刻都在您身旁。
                                            </li>
                                            <li>
                                                數位教材伴您一生，不必擔心知識忘記。
                                            </li>
                                            <li>
                                                學員上課期間，購買潛水器材享有學員優惠價。
                                            </li>
                                            <li>
                                                搭配移地結訓，冬季也能完成課程。
                                            </li>
                                        </ul>
                                        <br>
                                        <img src="../../../../assets/Image/Data/SC_NL_02.png" width="100%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程規劃 -->
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td width="10%">
                                                    </td>
                                                    <td>
                                                        <p class="fs-6">
                                                            DAY 1 | 學科 19:00~22:00<br>
                                                        </p>
                                                        <hr>
                                                        <p class="fs-6">
                                                            夜潛與低能見度的定義、夜潛需要的特殊裝備<br>
                                                            規劃合適的夜潛行程、燈光訊號的使用方式<br>
                                                            燈具故障時的處理方式
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    </td>
                                                    <td>
                                                        <p class="fs-6">
                                                            <br>DAY 2 | 開放水域 15:30 ~ 20:00<br>
                                                        </p>
                                                        <hr>
                                                        <p class="fs-6">
                                                            黃昏低能見度體驗、燈具保養<br>
                                                            燈光訊號使用、燈具故障模擬
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br>
                                        <div id="carouselExampleIndicators" class="carousel slide">
                                            <div class="carousel-indicators">
                                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                            </div>
                                            <div class="carousel-inner">
                                                <div class="carousel-item active">
                                                    <img src="../../../../assets/Image/Data/Schedule_NL_02.png" class="d-block w-100" alt="...">
                                                </div>
                                                <div class="carousel-item">
                                                    <img src="../../../../assets/Image/Data/Schedule_NL_01.png" class="d-block w-100" alt="...">
                                                </div>
                                            </div>
                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Previous</span>
                                            </button>
                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Next</span>
                                            </button>
                                        </div>
                                        <br>

                                        <!-- <br>
                                        <mdb-carousel class="carousel slide carousel-fade" interval="false" [animation]="'fade'">
                                            <mdb-carousel-item *ngFor="let NLSchedule of NLSchedules; let i = index">
                                                <div class="view">
                                                    <img class="d-block" src="{{NLSchedule.img}}" width="100%" style="display:block; margin:auto;" alt="First slide">
                                                </div>
                                            </mdb-carousel-item>
                                        </mdb-carousel>
                                        <br> -->

                                    </div>
                                    <!-- 課程學費 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程學費</h3>
                                        <hr>
                                        <ul class="fs-6">
                                            <li style="color: green;">定價 6,500 元。</li>
                                            <li>現金優惠，折扣 300 元。</li>
                                            <li>費用包含：SSI數位教材、國際數位證照申請、教練費、訓練期間潛水重裝使用（浮力調整裝置、調節器、電腦錶）、術科課程期間保險。</li>
                                            <li>費用不含：海洋實習交通與餐食費用、輕裝租借、移地結訓時之旅遊團費（請參考各地旅遊活動的費用）。</li>
                                        </ul>
                                        <br>
                                    </div>
                                    <!-- 報名方式 -->
                                    <div>
                                        <br>
                                        <app-paymentmethod></app-paymentmethod>
                                        <br>
                                    </div>
                                    <!-- 報名須知 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">報名須知</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>具備開放水域潛水員OWD證照（不分系統）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>年齡需滿12歲以上，健康狀況良好</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <a href="https://drive.google.com/file/d/13Lltu9vx3duMY8BWTbkFMDUY5FbmipIw/view?pli=1">健康聲明書在此請點擊</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 上課地點 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">上課地點</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                                        <p>教室學科:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>iDiving 士林前港店（台北市士林區前港街 8 號）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>海洋實習:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>iDiving 東北角龍洞店（新北市貢寮區龍洞街 66-1 號）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="color:green;vertical-align:text-top;">
                                                        <p>註: </p>
                                                    </td>
                                                    <td class="fs-6" style="color:green;">
                                                        <p>
                                                            上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修等），若有額外增加的費用本中心將自行吸收，不會另外收費，但學員不可因課程時間調整而要求退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 特約課程 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">特約課程</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學科部份，每另外約一日課程加價 300 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>術科部份，每另外約一日課程加價 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>三人同行，一起約定特殊時間上課，不須額外加價。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 課程更改 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程更改</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>課程須於開始上課後的兩個月內完成。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期之更改須在 10 天前告知</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 課程取消 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程取消</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>若因身體因素不適合從事潛水活動，並開立醫生證明者，除了NT 3,000元購買數位教材的訂金，其餘將會退還。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br>
                                    </div>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2">
                                <a class="fs-4" ngbNavLink>高氧</a>
                                <ng-template ngbNavContent>

                                    <br>
                                    <h3 style="font-family:DFKai-sb;">高氧 Enriched Air Nitrox (EAN)</h3>
                                    <hr>
                                    <!-- 課程內容 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">開課內容</h3>
                                        <ul>
                                            <li class="fs-6">
                                                學科（3hr）<br>
                                                高氧潛水的定義 / 高氧的好處<br>
                                                使用高氧時的裝備考量<br>
                                                制定符合需求的高氧潛水計畫
                                            </li>
                                        </ul>
                                        <br>
                                        <img src="../../../../assets/Image/Data/SC_EAN_01.png" width="60%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程特色 -->
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>課程特色</h3>
                                        <ul class="fs-6">
                                            <li>
                                                可以使用高氧氣瓶，減緩體內氮氣累積，增加潛水時間。
                                            </li>
                                            <li>
                                                平日 / 假日 均有開課，課程安排很方便。
                                            </li>
                                            <li>
                                                數位教材伴您一生，不必擔心知識忘記。
                                            </li>
                                            <li>
                                                學員上課期間，購買潛水器材享有學員優惠價。
                                            </li>
                                        </ul>
                                        <br>
                                        <img src="../../../../assets/Image/Data/SC_EAN_02.png" width="100%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程規劃 -->
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td width="10%">
                                                    </td>
                                                    <td>
                                                        <p class="fs-6">
                                                            DAY 1 | 學科 19:00~22:00<br>
                                                        </p>
                                                        <hr>
                                                        <p class="fs-6">
                                                            高氧的定義、高氧的好處與限制<br>
                                                            使用高氧時的裝備考量<br>
                                                            制定符合需求的高氧潛水計畫
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br>
                                        <img class="d-block" src="../../../../assets/Image/Data/Schedule_EAN.png" width="100%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程學費 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程學費</h3>
                                        <hr>
                                        <ul class="fs-6">
                                            <li style="color: green;">定價 5,000 元(額外贈送2支高氧使用券)。</li>
                                            <li>現金優惠，折扣 300 元。</li>
                                            <li>費用包含：SSI數位教材、國際數位證照申請。</li>
                                        </ul>
                                        <br>
                                    </div>
                                    <!-- 報名方式 -->
                                    <div>
                                        <br>
                                        <app-paymentmethod></app-paymentmethod>
                                        <br>
                                    </div>
                                    <!-- 報名須知 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">報名須知</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>具備開放水域潛水員OWD證照（不分系統）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>年齡需滿12歲以上，健康狀況良好</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <a href="https://drive.google.com/file/d/13Lltu9vx3duMY8BWTbkFMDUY5FbmipIw/view?pli=1">健康聲明書在此請點擊</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 上課地點 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">上課地點</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>教室學科：iDiving士林前港店（台北市士林區前港街8號）</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 特約課程 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">特約課程</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學科部份，每另外約一日課程加價 300 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>三人同行，一起約定特殊時間上課，不須額外加價。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 課程更改 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程更改</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期之更改須在 10 天前告知</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 課程取消 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程取消</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>若因身體因素不適合從事潛水活動，並開立醫生證明者，除了NT 3,000元購買數位教材的訂金，其餘將會退還。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br>
                                    </div>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="3">
                                <a class="fs-4" ngbNavLink>完美浮力</a>
                                <ng-template ngbNavContent>

                                    <br>
                                    <h3 style="font-family:DFKai-sb;">完美浮力 Perfect Buoyancy (PB)</h3>
                                    <hr>
                                    <!-- 課程內容 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">開課內容</h3>
                                        <ul>
                                            <li class="fs-6">
                                                學科（3hr）<br>
                                                不同款式的浮力補償裝置介紹<br>
                                                浮力的原理與適當配重的優點<br>
                                                如何調整合適的中性浮力<br>
                                                完美浮力對潛水員與環境的影響
                                            </li>
                                        </ul>
                                        <ul>
                                            <li class="fs-6">
                                                術科訓練（3hr）<br>
                                                適當配重調整 / 呼吸控制的練習<br>
                                                蛙鞋使用時機與浮力控制<br>
                                                不同蛙鞋踢法的使用時機
                                            </li>
                                        </ul>
                                        <br>
                                        <img src="../../../../assets/Image/Data/SC_PB_01.png" width="60%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程特色 -->
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>課程特色</h3>
                                        <ul class="fs-6">
                                            <li>
                                                介紹各項裝備對於浮力的影響，如何調整適當配重。
                                            </li>
                                            <li>
                                                海洋課程期間提供調節器、B.C.D.、電腦錶。
                                            </li>
                                            <li>
                                                領導級人員對學員比例 1：3，教練時時刻刻都在您身旁。
                                            </li>
                                            <li>
                                                數位教材伴您一生，不必擔心知識忘記。
                                            </li>
                                            <li>
                                                學員上課期間，購買潛水器材享有學員優惠價。
                                            </li>
                                            <li>
                                                於iDiving上完AA可享完美浮力課程優惠。
                                            </li>
                                        </ul>
                                        <br>
                                        <img src="../../../../assets/Image/Data/SC_PB_02.png" width="100%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程規劃 -->
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td width="10%">
                                                    </td>
                                                    <td>
                                                        <p class="fs-6">
                                                            DAY 1 | 學科 19:00~22:00<br>
                                                        </p>
                                                        <hr>
                                                        <p class="fs-6">
                                                            不同款式的浮力補償裝置介紹<br>
                                                            浮力的原理與適當配重的優點<br>
                                                            如何調整合適的中性浮力<br>
                                                            完美浮力對潛水員與環境的影響
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="10%">
                                                    </td>
                                                    <td>
                                                        <p class="fs-6">
                                                            <br>DAY2  | 術科訓練 08:00~21:00<br>
                                                        </p>
                                                        <hr>
                                                        <p class="fs-6">
                                                            適當配重調整、呼吸控制的練習<br>
                                                            蛙鞋使用時機與浮力控制<br>
                                                            不同蛙鞋踢法的使用時機
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br>
                                        <img class="d-block" src="../../../../assets/Image/Data/Schedule_PB.png" width="100%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程學費 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程學費</h3>
                                        <hr>
                                        <ul class="fs-6">
                                            <li style="color: green;">定價 7,000 元。</li>
                                            <li>現金優惠，折扣 300 元。</li>
                                            <li style="color: red;">已完成iDiving的AA課程之學員可減免1,000元。</li>
                                            <li>費用包含：線上教材、國際數位證照申請、教練費、重裝租借費用、東北角海洋實習費用與保險費。</li>
                                            <li>費用不含：海洋實習交通與餐食費用、輕裝租借。</li>
                                        </ul>
                                        <br>
                                    </div>
                                    <!-- 報名方式 -->
                                    <div>
                                        <br>
                                        <app-paymentmethod></app-paymentmethod>
                                        <br>
                                    </div>
                                    <!-- 報名須知 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">報名須知</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>具備開放水域潛水員OWD證照（不分系統）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>年齡需滿12歲以上，健康狀況良好</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <a href="https://drive.google.com/file/d/13Lltu9vx3duMY8BWTbkFMDUY5FbmipIw/view?pli=1">健康聲明書在此請點擊</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 上課地點 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">上課地點</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>海洋地點，夏季東北角(iDiving 東北角龍洞店) 冬季南港運動中心 或 移地訓練。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                                        <p>教室學科:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>iDiving 士林前港店（台北市士林區前港街 8 號）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>海洋實習:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>iDiving 東北角龍洞店（新北市貢寮區龍洞街 66-1 號）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="color:green;vertical-align:text-top;">
                                                        <p>註: </p>
                                                    </td>
                                                    <td class="fs-6" style="color:green;">
                                                        <p>
                                                            上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修等），若有額外增加的費用本中心將自行吸收，不會另外收費，但學員不可因課程時間調整而要求退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 特約課程 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">特約課程</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學科部份，每另外約一日課程加價 300 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>術科部份，每另外約一日課程加價 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>三人同行，一起約定特殊時間上課，不須額外加價。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 課程更改 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程更改</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>課程須於開始上課後的兩個月內完成。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期之更改須在 10 天前告知</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 課程取消 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程取消</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>若因身體因素不適合從事潛水活動，並開立醫生證明者，除了NT 3,000元購買數位教材的訂金，其餘將會退還。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br>
                                    </div>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="4">
                                <a class="fs-4" ngbNavLink>深潛</a>
                                <ng-template ngbNavContent class="custom-class">
                                    <br>
                                    <h3 style="font-family:DFKai-sb;">深潛 Deep Diving (DD)</h3>
                                    <hr>
                                    <!-- 課程內容 -->
                                    <div>
                                        <br>
                                        <a style="color: red;">報名條件：建議潛水支數25支後再參加此課程。</a>
                                        <br>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">開課內容</h3>
                                        <ul>
                                            <li class="fs-6">
                                                學科（3hr）<br>
                                                裝備對於深潛的重要性 / 規劃合適的深潛行程<br>
                                                深潛時特定技巧的掌控 / 深潛的潛在風險<br>
                                                重複進行深潛的安排
                                            </li>
                                            <li class="fs-6">
                                                海洋訓練（7hr）<br>
                                                利用電腦錶進行潛水計畫 / 深度改變時的浮力控制<br>
                                                監控免減壓時間的改變 / 計算水面耗氣速率
                                            </li>
                                        </ul>
                                        <br>
                                        <img src="../../../../assets/Image/Data/SC_DD_01.png" width="60%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程特色 -->
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>課程特色</h3>
                                        <ul class="fs-6">
                                            <li>
                                                最大潛水深度可達休閒潛水的極限40米。
                                            </li>
                                            <li>
                                                海洋課程期間提供調節器、B.C.D.、電腦錶。
                                            </li>
                                            <li>
                                                平日 / 假日 均有開課，課程安排很方便。
                                            </li>
                                            <li>
                                                領導級人員對學員比例 1：3，教練時時刻刻都在您身旁。
                                            </li>
                                            <li>
                                                數位教材伴您一生，不必擔心知識忘記。
                                            </li>
                                            <li>
                                                學員上課期間，購買潛水器材享有學員優惠價。
                                            </li>
                                            <li>
                                                搭配移地結訓，冬季也能完成課程。
                                            </li>
                                            <li>
                                                於iDiving上完AA可享深潛課程優惠。
                                            </li>
                                        </ul>
                                        <br>
                                        <img src="../../../../assets/Image/Data/SC_DD_02.png" width="100%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程規劃 -->
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>課程規劃(假日班)</h3>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td width="10%">
                                                    </td>
                                                    <td>
                                                        <p class="fs-6">
                                                            DAY 1 | 學科 19:00~22:00<br>
                                                        </p>
                                                        <hr>
                                                        <p class="fs-6">
                                                            裝備對於深潛的重要性、規劃合適的深潛行程<br>
                                                            深潛時特定技巧的掌控、深潛的潛在風險、重複進行深潛的安排
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    </td>
                                                    <td>
                                                        <p class="fs-6">
                                                            <br>DAY 2 | 開放水域 09:00~17:30<br>
                                                        </p>
                                                        <hr>
                                                        <p class="fs-6">
                                                            2支岸潛<br>
                                                            利用電腦錶進行潛水計畫、深度改變時的浮力控制<br>
                                                            監控免減壓時間的改變、計算水面耗氣速率、水層中施打浮力棒
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    </td>
                                                    <td>
                                                        <p class="fs-6">
                                                            <br>DAY 3 | 開放水域 07:00~16:00<br>
                                                        </p>
                                                        <hr>
                                                        <p class="fs-6">
                                                            2支船潛<br>
                                                            利用電腦錶進行潛水計畫、深度改變時的浮力控制<br>
                                                            監控免減壓時間的改變、計算水面耗氣速率、水層中施打浮力棒
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <h3 style="font-family:DFKai-sb;"><br>課程規劃(平日班)</h3>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td width="10%">
                                                    </td>
                                                    <td>
                                                        <p class="fs-6">
                                                            DAY 1 | 學科 10:00~12:00 開放水域 13:00~17:30<br>
                                                        </p>
                                                        <hr>
                                                        <p class="fs-6">
                                                            裝備對於深潛的重要性、規劃合適的深潛行程<br>
                                                            深潛時特定技巧的掌控、深潛的潛在風險、重複進行深潛的安排<br>
                                                            2支岸潛<br>
                                                            利用電腦錶進行潛水計畫、深度改變時的浮力控制<br>
                                                            監控免減壓時間的改變、計算水面耗氣速率、水層中施打浮力棒
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    </td>
                                                    <td>
                                                        <p class="fs-6">
                                                            <br>DAY 2 | 開放水域 07:00~12:00<br>
                                                        </p>
                                                        <hr>
                                                        <p class="fs-6">
                                                            2支船潛<br>
                                                            與東北角船潛合併執行，需與客服另約時間<br>
                                                            利用電腦錶進行潛水計畫、深度改變時的浮力控制<br>
                                                            監控免減壓時間的改變、計算水面耗氣速率、水層中施打浮力棒
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <br>
                                        <div id="carouselExampleIndicators" class="carousel slide">
                                            <div class="carousel-indicators">
                                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                            </div>
                                            <div class="carousel-inner">
                                                <div class="carousel-item active">
                                                    <img src="../../../../assets/Image/Data/Schedule_DD_01.png" class="d-block w-100" alt="...">
                                                </div>
                                                <div class="carousel-item">
                                                    <img src="../../../../assets/Image/Data/Schedule_DD_02.png" class="d-block w-100" alt="...">
                                                </div>
                                            </div>
                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Previous</span>
                                            </button>
                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Next</span>
                                            </button>
                                        </div>
                                        <br>

                                        <!-- <br>
                                        <mdb-carousel class="carousel slide carousel-fade" interval="false" [animation]="'fade'">
                                            <mdb-carousel-item *ngFor="let DDSchedule of DDSchedules; let i = index">
                                                <div class="view">
                                                    <img class="d-block" src="{{DDSchedule.img}}" width="100%" style="display:block; margin:auto;" alt="First slide">
                                                </div>
                                            </mdb-carousel-item>
                                        </mdb-carousel>
                                        <br> -->

                                    </div>
                                    <!-- 課程學費 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程學費</h3>
                                        <hr>
                                        <ul class="fs-6">
                                            <li style="color: green;">方案一：東北角結訓（2支岸潛、2支船潛）費用為10,000 元。</li>
                                            <li style="color: green;">方案二：搭配出團上課，費用為團費 + 7,500 元。</li>
                                            <li style="color: green;">方案三：與船潛合併上課，兩天直接完成兩門課程術科，iDiving並加贈訓練期間住宿一晚，費用為 14000元。</li>
                                            <li>現金優惠，折扣 300 元。</li>
                                            <li style="color: red;">已完成 iDiving AA 課程之學員可減免 1,000 元。</li>
                                            <li>費用包含：SSI數位教材、國際數位證照申請、教練費、訓練期間潛水重裝使用（浮力調整裝置、調節器、電腦錶）、術科課程期間保險、兩支船潛與氣瓶之費用。</li>
                                            <li>費用不含：海洋實習交通與餐食費用、輕裝租借、移地結訓時之旅遊團費（請參考各地旅遊活動的費用）。</li>
                                        </ul>
                                        <br>
                                    </div>
                                    <!-- 報名方式 -->
                                    <div>
                                        <br>
                                        <app-paymentmethod></app-paymentmethod>
                                        <br>
                                    </div>
                                    <!-- 報名須知 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">報名須知</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>具備開放水域潛水員OWD證照（不分系統）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>年齡需滿12歲以上，健康狀況良好</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <a href="https://drive.google.com/file/d/13Lltu9vx3duMY8BWTbkFMDUY5FbmipIw/view?pli=1">健康聲明書在此請點擊</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 上課地點 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">上課地點</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                                        <a>教室學科:</a>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>iDiving 士林前港店（台北市士林區前港街 8 號）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>海洋實習:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>iDiving 東北角龍洞店（新北市貢寮區龍洞街 66-1 號）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="color:green;vertical-align:text-top;">
                                                        <p>註: </p>
                                                    </td>
                                                    <td class="fs-6" style="color:green;">
                                                        <p>
                                                            上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修等），
                                                            若有額外增加的費用本中心將自行吸收，不會另外收費，但學員不可因課程時間調整而要求退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 特約課程 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">特約課程</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學科部份，每另外約一日課程加價 300 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>術科部份，每另外約一日課程加價 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>三人同行，一起約定特殊時間上課，不須額外加價。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 課程更改 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程更改</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>課程須於開始上課後的兩個月內完成。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期之更改須在 10 天前告知</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 課程取消 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程取消</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>若因身體因素不適合從事潛水活動，並開立醫生證明者，除了NT 3,000元購買數位教材的訂金，其餘將會退還。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>

                        <div [ngbNavOutlet]="nav" class="mt-2"></div>

                        <!-- <pre>Active: {{ active }}</pre> -->

                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a class="fs-4" ngbNavLink>選修專長</a>
                    <ng-template ngbNavContent>

                        <ul ngbNav #nav="ngbNav" [(activeId)]="active_02" class="nav-tabs">
                            <li [ngbNavItem]="1">
                                <a class="fs-4" ngbNavLink>船潛</a>
                                <ng-template ngbNavContent>

                                    <br>
                                    <h3 style="font-family:DFKai-sb;">船潛 Boat Diving (BD)</h3>
                                    <hr>
                                    <!-- 課程內容 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">開課內容</h3>
                                        <ul>
                                            <li class="fs-6">
                                                學科（3hr）<br>
                                                不同類型的潛水船 / 船潛行前準備<br>
                                                登船程序與船上禮儀 / 船潛出入水程序<br>
                                                船潛結束時的注意事項
                                            </li>
                                            <li class="fs-6">
                                                海洋訓練（7hr）<br>
                                                學習登船程序 / 有效率的收納裝備<br>
                                                制定合適的船潛行程 / 水中浮力棒使用<br>
                                                船潛出入水的注意事項
                                            </li>
                                        </ul>
                                        <br>
                                        <img src="../../../../assets/Image/Data/SC_BD_01.png" width="60%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程特色 -->
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>課程特色</h3>
                                        <ul class="fs-6">
                                            <li>
                                                海洋課程期間提供調節器、B.C.D.、電腦錶、浮力袋與手輪。
                                            </li>
                                            <li>
                                                領導級人員對學員比例 1：3，教練時時刻刻都在您身旁。
                                            </li>
                                            <li>
                                                數位教材伴您一生，不必擔心知識忘記。
                                            </li>
                                            <li>
                                                學員上課期間，購買潛水器材享有學員優惠價。
                                            </li>
                                            <li>
                                                搭配移地結訓，冬季也能完成課程，還可享有課程優惠。
                                            </li>
                                            <li>
                                                於iDiving上完AA可享船潛課程優惠。
                                            </li>
                                        </ul>
                                        <br>
                                        <img src="../../../../assets/Image/Data/SC_BD_02.png" width="100%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程規劃 -->
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td width="10%">
                                                    </td>
                                                    <td>
                                                        <p class="fs-6">
                                                            DAY 1 | 學科 19:00~22:00<br>
                                                        </p>
                                                        <hr>
                                                        <p class="fs-6">
                                                            不同類型的潛水船、船潛行前準備<br>
                                                            登船程序與船上禮儀、船潛出入水程序<br>
                                                            船潛結束時的注意事項
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    </td>
                                                    <td>
                                                        <p class="fs-6">
                                                            <br>DAY 2 | 開放水域 07:00~14:00<br>
                                                        </p>
                                                        <hr>
                                                        <p class="fs-6">
                                                            學習登船程序、有效率的收納裝備<br>
                                                            制定合適的船潛行程、水中浮力棒使用<br>
                                                            船潛出入水的注意事項
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br>
                                        <div id="carouselExampleIndicators" class="carousel slide">
                                            <div class="carousel-indicators">
                                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                            </div>
                                            <div class="carousel-inner">
                                                <div class="carousel-item active">
                                                    <img src="../../../../assets/Image/Data/Schedule_BD_01.png" class="d-block w-100" alt="...">
                                                </div>
                                            </div>
                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Previous</span>
                                            </button>
                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Next</span>
                                            </button>
                                        </div>
                                        <br>
                                        <!-- <br>
                                        <img class="d-block" src="../../../../assets/Image/Data/BD_Schedule.png" width="100%" style="display:block; margin:auto;"> -->
                                    </div>
                                    <!-- 課程學費 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程學費</h3>
                                        <hr>
                                        <ul class="fs-6">
                                            <li style="color: green;">方案一：東北角結訓（2支船潛）費用為7,000 元。</li>
                                            <li style="color: green;">方案二：搭配出團上課，費用為團費 + 4,500 元。</li>
                                            <li style="color: green;">方案三：與深潛合併上課，兩天直接完成兩門課程術科，iDiving並加贈訓練期間住宿一晚，費用為 14,000元。</li>
                                            <li>現金優惠，折扣 300 元。</li>
                                            <li style="color: red;">已完成 iDiving AA 課程之學員可減免 1,000 元。</li>
                                            <li>
                                                費用包含：SSI數位教材、國際數位證照申請、教練費、訓練期間潛水重裝使用（浮力調整裝置、調節器、電腦錶）、術科課程期間保險、兩支船潛與氣瓶之費用。
                                            </li>
                                            <li>費用不含：海洋實習交通與餐食費用、輕裝租借、移地結訓時之旅遊團費（請參考各地旅遊活動的費用）。</li>
                                        </ul>
                                        <br>
                                    </div>
                                    <!-- 報名方式 -->
                                    <div>
                                        <br>
                                        <app-paymentmethod></app-paymentmethod>
                                        <br>
                                    </div>
                                    <!-- 報名須知 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">報名須知</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>具備開放水域潛水員OWD證照（不分系統）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>年齡需滿12歲以上，健康狀況良好</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <a href="https://drive.google.com/file/d/13Lltu9vx3duMY8BWTbkFMDUY5FbmipIw/view?pli=1">健康聲明書在此請點擊</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 上課地點 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">上課地點</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                                        <p>教室學科:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>iDiving 士林前港店（台北市士林區前港街 8 號）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>海洋實習:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>iDiving 東北角龍洞店（新北市貢寮區龍洞街 66-1 號）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="color:green;vertical-align:text-top;">
                                                        <p>註: </p>
                                                    </td>
                                                    <td class="fs-6" style="color:green;">
                                                        <p>
                                                            上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修等），
                                                            若有額外增加的費用本中心將自行吸收，不會另外收費，但學員不可因課程時間調整而要求退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 特約課程 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">特約課程</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學科部份，每另外約一日課程加價 300 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>術科部份，每另外約一日課程加價 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>三人同行，一起約定特殊時間上課，不須額外加價。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 課程更改 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程更改</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>課程須於開始上課後的兩個月內完成。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期之更改須在 10 天前告知</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 課程取消 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程取消</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>若因身體因素不適合從事潛水活動，並開立醫生證明者，除了NT 3,000元購買數位教材的訂金，其餘將會退還。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br>
                                    </div>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2">
                                <a class="fs-4" ngbNavLink>導航</a>
                                <ng-template ngbNavContent class="custom-class">

                                    <br>
                                    <h3 style="font-family:DFKai-sb;">導航 Navigation (NV)</h3>
                                    <hr>
                                    <!-- 課程內容 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">開課內容</h3>
                                        <ul>
                                            <li class="fs-6">
                                                學科（3hr）<br>
                                                自然導航 / 指北針導航<br>
                                                自然導航搭配指北針使用<br>
                                                需要特殊導航技術的狀況<br>
                                                導航技巧的實際應用
                                            </li>
                                            <li class="fs-6">
                                                海洋訓練（5hr）<br>
                                                水中距離計算 / 自然導航練習<br>
                                                指北針導航練習
                                            </li>
                                        </ul>
                                        <br>
                                        <img src="../../../../assets/Image/Data/SC_NV_01.png" width="60%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程特色 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程特色</h3>
                                        <ul class="fs-6">
                                            <li>
                                                加強指北針使用，練習水中方向辨認技巧，安全往返目的地。
                                            </li>
                                            <li>
                                                海洋課程期間提供調節器、B.C.D.、電腦錶。
                                            </li>
                                            <li>
                                                平日 / 假日 均有開課，課程安排很方便。
                                            </li>
                                            <li>
                                                領導級人員對學員比例 1：3，教練時時刻刻都在您身旁。
                                            </li>
                                            <li>
                                                數位教材伴您一生，不必擔心知識忘記。
                                            </li>
                                            <li>
                                                學員上課期間，購買潛水器材享有學員優惠價。
                                            </li>
                                            <li>
                                                於iDiving上完AA可享導航課程優惠。
                                            </li>
                                        </ul>
                                        <br>
                                        <img src="../../../../assets/Image/Data/SC_NV_02.png" width="100%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程規劃 -->
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td width="10%">
                                                    </td>
                                                    <td>
                                                        <p class="fs-6">
                                                            DAY 1 | 學科 19:00~22:00<br>
                                                        </p>
                                                        <hr>
                                                        <p class="fs-6">
                                                            自然導航 / 指北針導航<br>
                                                            自然導航搭配指北針使用<br>
                                                            需要特殊導航技術的狀況<br>
                                                            導航技巧的實際應用
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    </td>
                                                    <td>
                                                        <p class="fs-6">
                                                            <br>DAY 2 | 開放水域 08:00~13:00<br>
                                                        </p>
                                                        <hr>
                                                        <p class="fs-6">
                                                            水中距離計算、自然導航練習<br>
                                                            指北針導航練習
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br>
                                        <div id="carouselExampleIndicators" class="carousel slide">
                                            <div class="carousel-indicators">
                                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                            </div>
                                            <div class="carousel-inner">
                                                <div class="carousel-item active">
                                                    <img src="../../../../assets/Image/Data/Schedule_NV_01.png" class="d-block w-100" alt="...">
                                                </div>
                                                <div class="carousel-item">
                                                    <img src="../../../../assets/Image/Data/Schedule_NV_02.png" class="d-block w-100" alt="...">
                                                </div>
                                            </div>
                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Previous</span>
                                            </button>
                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Next</span>
                                            </button>
                                        </div>
                                        <br>
                                        <!-- <br>
                                        <img class="d-block" src="../../../../assets/Image/Data/NV_Schedule.png" width="100%" style="display:block; margin:auto;"> -->
                                    </div>
                                    <!-- 課程學費 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程學費</h3>
                                        <hr>
                                        <ul class="fs-6">
                                            <li style="color: green;">定價 6,500 元。</li>
                                            <li>現金優惠，折扣 300 元。</li>
                                            <li style="color: red;">已完成iDiving的AA課程之學員可減免1,000元。</li>
                                            <li>費用包含：SSI數位教材、國際數位證照申請、教練費、訓練期間潛水重裝使用（浮力調整裝置、調節器、電腦錶）、術科課程期間保險。</li>
                                            <li>費用不含：海洋實習交通與餐食費用、輕裝租借。</li>
                                        </ul>
                                        <br>
                                    </div>
                                    <!-- 報名方式 -->
                                    <div>
                                        <br>
                                        <app-paymentmethod></app-paymentmethod>
                                        <br>
                                    </div>
                                    <!-- 報名須知 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">報名須知</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>具備開放水域潛水員OWD證照（不分系統）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>年齡需滿12歲以上，健康狀況良好</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <a href="https://drive.google.com/file/d/13Lltu9vx3duMY8BWTbkFMDUY5FbmipIw/view?pli=1">健康聲明書在此請點擊</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 上課地點 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">上課地點</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                                        <p>教室學科:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>iDiving 士林前港店（台北市士林區前港街 8 號）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>海洋實習:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>iDiving 東北角龍洞店（新北市貢寮區龍洞街 66-1 號）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="color:green;vertical-align:text-top;">
                                                        <p>註: </p>
                                                    </td>
                                                    <td class="fs-6" style="color:green;">
                                                        <p>
                                                            上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修等），
                                                            若有額外增加的費用本中心將自行吸收，不會另外收費，但學員不可因課程時間調整而要求退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 特約課程 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">特約課程</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學科部份，每另外約一日課程加價 300 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>術科部份，每另外約一日課程加價 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>三人同行，一起約定特殊時間上課，不須額外加價。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 課程更改 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程更改</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>課程須於開始上課後的兩個月內完成。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期之更改須在 10 天前告知</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 課程取消 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程取消</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>若因身體因素不適合從事潛水活動，並開立醫生證明者，除了NT 3,000元購買數位教材的訂金，其餘將會退還。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br>
                                    </div>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="3">
                                <a class="fs-4" ngbNavLink>水下攝影</a>
                                <ng-template ngbNavContent class="custom-class">

                                    <br>
                                    <h3 style="font-family:DFKai-sb;">水下攝影 Photo & Video (PV)</h3>
                                    <hr>
                                    <!-- 課程內容 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">開課內容</h3>
                                        <ul>
                                            <li class="fs-6">
                                                學科（6hr）<br>
                                                關於水中攝影與錄影 / 認識水中數位攝影與錄影器材 / 你的水中攝影裝備組裝與維護 / 針對水中攝影與錄影的相機設定 / <br>
                                                辨識並了解水中生物 / 如何拍出絕佳的水中照片與影片
                                            </li>
                                        </ul>
                                        <br>
                                        <img src="../../../../assets/Image/Data/SC_PV_01.png" width="60%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程特色 -->
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>課程特色</h3>
                                        <ul class="fs-6">
                                            <li>
                                                教你水下攝影器材的介紹與常用設定。
                                            </li>
                                            <li>
                                                針對水下光線的物理原理等知識。
                                            </li>
                                            <li>
                                                數位教材伴您一生，不必擔心知識忘記。
                                            </li>
                                            <li>
                                                學員上課期間，購買潛水器材享有學員優惠價。
                                            </li>
                                        </ul>
                                        <br>
                                        <img src="../../../../assets/Image/Data/SC_PV_02.png" width="100%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程規劃 -->
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td width="10%">
                                                    </td>
                                                    <td>
                                                        <p class="fs-6">
                                                            DAY 1 | 學科與術科 09:00 ~ 15:00<br>
                                                        </p>
                                                        <hr>
                                                        <p class="fs-6">
                                                            關於水中攝影與錄影 / 認識水中數位攝影與錄影器材 / 你的水中攝影裝備組裝與維護<br>
                                                            微距拍攝技巧練習 / 廣角拍攝技巧練習
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    </td>
                                                    <td>
                                                        <p class="fs-6">
                                                            <br>DAY 2 | 學科與術科 09:00 ~ 15:00<br>
                                                        </p>
                                                        <hr>
                                                        <p class="fs-6">
                                                            針對水中攝影與錄影的相機設定 / 辨識並了解水中生物 / 如何拍出絕佳的水中照片與影片<br>
                                                            微距拍攝技巧練習 / 廣角拍攝技巧練習
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br>
                                        <img class="d-block" src="../../../../assets/Image/Data/PV_Schedule.png" width="100%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程學費 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程學費</h3>
                                        <hr>
                                        <ul class="fs-6">
                                            <li style="color: green;">定價 7,000 元。</li>
                                            <li>現金優惠，折扣 300 元。</li>
                                            <li>費用包含：SSI數位教材、國際數位證照申請、教練費、訓練期間潛水重裝使用（浮力調整裝置、調節器、電腦錶）、旅遊平安保險。</li>
                                        </ul>
                                        <br>
                                    </div>
                                    <!-- 報名方式 -->
                                    <div>
                                        <br>
                                        <app-paymentmethod></app-paymentmethod>
                                        <br>
                                    </div>
                                    <!-- 報名須知 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">報名須知</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>具備開放水域潛水員OWD證照（不分系統）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>年齡需滿12歲以上，健康狀況良好</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <a href="https://drive.google.com/file/d/13Lltu9vx3duMY8BWTbkFMDUY5FbmipIw/view?pli=1">健康聲明書在此請點擊</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 上課地點 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">上課地點</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                                        <p>教室學科:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>iDiving 士林前港店（台北市士林區前港街 8 號）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>海洋實習:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>iDiving 東北角龍洞店（新北市貢寮區龍洞街 66-1 號）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="color:green;vertical-align:text-top;">
                                                        <p>註: </p>
                                                    </td>
                                                    <td class="fs-6" style="color:green;">
                                                        <p>
                                                            上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修等），
                                                            若有額外增加的費用本中心將自行吸收，不會另外收費，但學員不可因課程時間調整而要求退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 特約課程 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">特約課程</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學科部份，每另外約一日課程加價 300 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>術科部份，每另外約一日課程加價 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>三人同行，一起約定特殊時間上課，不須額外加價。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 更改辦法 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">更改辦法</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>課程須於開始上課後的兩個月內完成。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期之更改須在 10 天前告知</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 取消辦法 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">取消辦法</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>若因身體因素不適合從事潛水活動，並開立醫生證明者，除了NT 3,000元購買數位教材的訂金，其餘將會退還。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br>
                                    </div>
                                </ng-template>
                            </li>
                            <!-- <li [ngbNavItem]="3">
                                <a class="fs-4" ngbNavLink>裝備維修</a>
                                <ng-template ngbNavContent class="custom-class">

                                </ng-template>
                            </li> -->
                        </ul>

                        <div [ngbNavOutlet]="nav" class="mt-2"></div>

                        <!-- <pre>Active: {{ active }}</pre> -->

                    </ng-template>
                </li>
                <li [ngbNavItem]="3">
                    <a class="fs-4" ngbNavLink>安全專長</a>
                    <ng-template ngbNavContent>

                        <ul ngbNav #nav="ngbNav" [(activeId)]="active_03" class="nav-tabs">
                            <li [ngbNavItem]="1">
                                <a class="fs-4" ngbNavLink>正確反應員</a>
                                <ng-template ngbNavContent>

                                    <br>
                                    <h3 style="font-family:DFKai-sb;">正確反應員 React Right (RR)</h3>
                                    <hr>
                                    <!-- 課程內容 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">開課內容</h3>
                                        <ul>
                                            <li class="fs-6">
                                                學科（6hr）<br>
                                                首要評估 / 心肺復甦術 / 首要救護技術<br>
                                                自動體外除顫器 / 緊急供氧
                                            </li>
                                        </ul>
                                        <br>
                                        <img src="../../../../assets/Image/Data/SC_RR.jpg" width="60%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程特色 -->
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>課程特色</h3>
                                        <ul class="fs-6">
                                            <li>
                                                學會潛水與其他事故發生時的處置方法。
                                            </li>
                                            <li>
                                                數位教材伴您一生，不必擔心知識忘記。
                                            </li>
                                            <li>
                                                學員上課期間，購買潛水器材享有學員優惠價。
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- 課程規劃 -->
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td width="10%">
                                                    </td>
                                                    <td>
                                                        <p class="fs-6">
                                                            DAY 1 | 學科 13:00~19:00<br>
                                                        </p>
                                                        <hr>
                                                        <p class="fs-6">
                                                            首要評估 / 心肺復甦術 / 首要救護技術<br>
                                                            自動體驗除顫器 / 緊急供氧純氧
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br>
                                        <img class="d-block" src="../../../../assets/Image/Data/Schedule_RR.png" width="100%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程學費 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程學費</h3>
                                        <hr>
                                        <ul class="fs-6">
                                            <li style="color: green;">定價 5,000 元 </li>
                                            <li>現金優惠，折扣 300 元。</li>
                                            <li style="color: red;">費用包含：SSI數位教材、國際數位證照申請、教練費 CPR / AED / 緊急供氧 設備使用。</li>
                                        </ul>
                                        <br>
                                    </div>
                                    <!-- 報名方式 -->
                                    <div>
                                        <br>
                                        <app-paymentmethod></app-paymentmethod>
                                        <br>
                                    </div>
                                    <!-- 報名須知 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">報名須知</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>具備開放水域潛水員OWD證照（不分系統）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>年齡需滿12歲以上，健康狀況良好</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <a href="https://drive.google.com/file/d/13Lltu9vx3duMY8BWTbkFMDUY5FbmipIw/view?pli=1">健康聲明書在此請點擊</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 上課地點 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">上課地點</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>教室學科：iDiving士林前港店（台北市士林區前港街8號）</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 特約課程 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">特約課程</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學科部份，每另外約一日課程加價 300 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>三人同行，一起約定特殊時間上課，不須額外加價。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 課程更改 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程更改</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>課程須於開始上課後的兩個月內完成</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期之更改須在 10 天前告知</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 課程取消 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程取消</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>若因身體因素不適合從事潛水活動，並開立醫生證明者，除了NT 3,000元購買數位教材的訂金，其餘將會退還。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br>
                                    </div>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2">
                                <a class="fs-4" ngbNavLink>壓力與救援</a>
                                <ng-template ngbNavContent>

                                    <h3 style="font-family:DFKai-sb;">壓力與救援  Stress & Rescue (SR)</h3>
                                    <hr>
                                    <!-- 課程內容 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">開課內容</h3>
                                        <ul>
                                            <li class="fs-6">
                                                學科（3hr）<br>
                                                壓力的定義 / 潛水時壓力的原因與預防<br>
                                                壓力的偵測及排除 / 事故管理<br>
                                                救援技巧 / 導致救援困難的情況
                                            </li>
                                        </ul>
                                        <br>
                                        <img src="../../../../assets/Image/Data/SC_SR_01.jpg" width="60%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程特色 -->
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>課程特色</h3>
                                        <ul class="fs-6">
                                            <li>
                                                學習壓力偵測，即早排除潛在問題。
                                            </li>
                                            <li>了解如何協助有狀況的潛水員。
                                            </li>
                                            <li>
                                                海洋課程期間提供調節器、B.C.D.、電腦錶。
                                            </li>
                                            <li>
                                                領導級人員對學員比例 1：3，教練時時刻刻都在您身旁。
                                            </li>
                                            <li>
                                                數位教材伴您一生，不必擔心知識忘記。
                                            </li>
                                            <li>
                                                學員上課期間，購買潛水器材享有學員優惠價。
                                            </li>
                                        </ul>
                                        <br>
                                        <img src="../../../../assets/Image/Data/SC_SR_02.jpg" width="100%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程規劃 -->
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td width="10%">
                                                    </td>
                                                    <td>
                                                        <p class="fs-6">
                                                            DAY 1 | 學科 19:00~22:00<br>
                                                        </p>
                                                        <hr>
                                                        <p class="fs-6">
                                                            壓力的定義、潛水時壓力的原因與預防<br>
                                                            壓力的偵測及排除、事故管理<br>
                                                            救援技巧、導致救援困難的情況
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="10%">
                                                    </td>
                                                    <td>
                                                        <p class="fs-6">
                                                            <br>DAY 2 | 平靜水域 08:00 ~ 11:00 <br>
                                                        </p>
                                                        <hr>
                                                        <p class="fs-6">
                                                            潛水技巧演練、自救技巧演練<br>
                                                            潛伴協助與救援技巧演練<br>
                                                            救援無意識潛水員技巧演練
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="10%">
                                                    </td>
                                                    <td>
                                                        <p class="fs-6">
                                                            <br>DAY 3 |  開放水域 13:00 ~ 17:00 <br>
                                                        </p>
                                                        <hr>
                                                        <p class="fs-6">
                                                            潛水技巧練習、自救技巧練習<br>
                                                            潛伴協助與救援技巧練習<br>
                                                            救援無意識潛水員技巧練習
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br>
                                        <img class="d-block" src="../../../../assets/Image/Data/Schedule_SR.png" width="100%" style="display:block; margin:auto;">
                                    </div>
                                    <!-- 課程學費 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程學費</h3>
                                        <hr>
                                        <ul class="fs-6">
                                            <li style="color: green;">定價 13,000 元 （壓力與救援 + 正確反應員）</li>
                                            <li>現金優惠，壓力與救援可折扣300元，正確反應員可折扣300元。</li>
                                            <li>費用包含：SSI數位教材、國際數位證照申請、教練費、訓練期間潛水重裝使用（浮力調整裝置、調節器、電腦錶）、術科課程期間保險。</li>
                                            <li>費用不含：海洋實習交通與餐食費用、輕裝租借。</li>
                                        </ul>
                                        <br>
                                    </div>
                                    <!-- 報名方式 -->
                                    <div>
                                        <br>
                                        <app-paymentmethod></app-paymentmethod>
                                        <br>
                                    </div>
                                    <!-- 報名須知 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">報名須知</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>具備開放水域潛水員OWD證照（不分系統）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>年齡需滿12歲以上，健康狀況良好</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <a href="https://drive.google.com/file/d/13Lltu9vx3duMY8BWTbkFMDUY5FbmipIw/view?pli=1">健康聲明書在此請點擊</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 上課地點 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">上課地點</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                                        <p>教室學科:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>iDiving 士林前港店（台北市士林區前港街 8 號）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>海洋實習:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>iDiving 東北角龍洞店（新北市貢寮區龍洞街 66-1 號）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="color:green;vertical-align:text-top;">
                                                        <p>註: </p>
                                                    </td>
                                                    <td class="fs-6" style="color:green;">
                                                        <p>
                                                            上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修等），若有額外增加的費用本中心將自行吸收，不會另外收費，但學員不可因課程時間調整而要求退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 特約課程 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">特約課程</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學科部份，每另外約一日課程加價 300 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>術科部份，每另外約一日課程加價 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>三人同行，一起約定特殊時間上課，不須額外加價。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 課程更改 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程更改</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>課程須於開始上課後的兩個月內完成。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期之更改須在 10 天前告知</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 課程取消 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">課程取消</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>若因身體因素不適合從事潛水活動，並開立醫生證明者，除了NT 3,000元購買數位教材的訂金，其餘將會退還。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>

                        <div [ngbNavOutlet]="nav" class="mt-2"></div>

                        <!-- <pre>Active: {{ active }}</pre> -->

                    </ng-template>
                </li>
            </ul>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>

        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>