<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">學潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">CCR 延伸領域（CCR ER）</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-8">

            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>CCR Diving(SF2)</a>
                    <ng-template ngbNavContent class="custom-class">
                        <br>
                        <h3 style="font-family:DFKai-sb;">全密閉循環水肺潛水員 CCR Diving(SF2)</h3>
                        <hr>
                        <!-- 課程內容 -->
                        <div>
                            <a>報名條件：已擁有24次的潛水紀錄、並取得深潛專長(DD)與高氧專長(EAN40)。</a>
                            <br>
                            <br>
                            <h3 style="font-family:DFKai-sb;">開課內容</h3>
                            <ul>
                                <li class="fs-6">
                                    學科(6hr)<br>
                                    循環水肺原理、潛水物理學、潛水生理學、SF2裝備介紹、CCR潛水計畫、備用計畫
                                </li>
                                <li class="fs-6">
                                    平靜水域(2次)<br>
                                    CCR組裝與調整、呼吸迴路最小化、中性浮力、踢動練習，喉管排水、階段氣瓶操作、切換氣瓶
                                </li>
                                <li class="fs-6">
                                    海洋訓練(6次)<br>
                                    團隊檢查、安全檢查、耗氣量檢測、潛水計畫、備用計畫、逃生程序、部屬SMB
                                </li>
                            </ul>
                            <br>
                            <img src="../../../../assets/Image/Data/CCR_DIVING_01.png" width="60%"
                                style="display:block; margin:auto;">
                        </div>
                        <!-- 課程特色 -->
                        <div>
                            <h3 style="font-family:DFKai-sb;"><br>課程特色</h3>
                            <ul class="fs-6">
                                <li>
                                    使用循環水肺大幅接近與生物之間的距離，可觀察生物不一樣的行為。
                                </li>
                                <li>
                                    暢遊30米以內的無聲海洋世界，大幅延長水下時間。
                                </li>
                                <li>
                                    課程已含CCR設備，免買裝備無壓力。
                                </li>
                                <li>
                                    裝備租借價格透明，課程使用裝備無負擔。
                                </li>
                                <li>
                                    長時間的操作練習，讓你穩定後才結訓。
                                </li>
                            </ul>
                            <br>
                            <img src="../../../../assets/Image/Data/CCR_DIVING_02.png" width="100%"
                                style="display:block; margin:auto;">
                        </div>
                        <!-- 課程規劃 -->
                        <div>
                            <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td width="10%">
                                        </td>
                                        <td>
                                            <a class="fs-6">
                                                DAY 1 | 學科 19:00~22:00<br>
                                                <hr>
                                                循環水肺原理、潛水物理學、潛水生理學
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <a class="fs-6">
                                                <br>DAY 2 | 學科 19:00~22:00<br>
                                                <hr>
                                                SF2裝備介紹、CCR潛水計畫、備用計畫
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <a class="fs-6">
                                                <br>DAY 3 | 平靜水域08:00~18:00<br>
                                                <hr>
                                                CCR組裝與調整、呼吸迴路最小化、中性浮力練習、踢動練習，喉管排水、階段氣瓶操作
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <a class="fs-6">
                                                <br>DAY 4-5-6 | 開放水域08:00~18:00<br>
                                                <hr>
                                                平靜水域動作驗證、中性浮力調整、踢動練習、交換階段氣瓶、團隊檢查、安全檢查、耗氣量檢測、逃生程序、部屬SMB
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <img class="d-block" src="../../../../assets/Image/Data/CCR_DIVING_Schedule.png" width="100%"
                                style="display:block; margin:auto;">
                        </div>
                        <!-- 課程學費 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">課程學費</h3>
                            <hr>
                            <ul class="fs-6">
                                <li style="color: green;">定價： 40000元</li>
                                <li>現金優惠，送1.1米封閉式浮力袋。</li>
                                <li>兩人以上同行，折扣4,000元。</li>
                                <li style="color: red;">以上現金優惠、證照優惠可合併使用。</li>
                                <li>費用包含：SSI數位教材、國際數位證照申請、教練費、訓練期間潛水重裝使用（CCR裝備、階段氣瓶與調節器）、吸附劑與氣瓶、旅遊平安保險。</li>
                                <li>費用不含：浮潛裝備租借、海洋實習交通與用餐費用。</li>
                                <li>如未能順利通過考試標準，複訓一天4000（含裝備）元。</li>
                            </ul>
                            <br>
                        </div>
                        <!-- 報名方式 -->
                        <div>
                            <br>
                            <app-paymentmethod></app-paymentmethod>
                            <br>
                        </div>
                        <!-- 報名須知 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">報名須知</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <p>
                                                基本條件需年滿18歲、已擁有50次的潛水紀錄，並具有深潛與高氧證照，健康狀況良好
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <a
                                                href="https://drive.google.com/file/d/13Lltu9vx3duMY8BWTbkFMDUY5FbmipIw/view?pli=1">健康聲明書在此請點擊</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 上課地點 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">上課地點</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <a>往返之交通請自行處理，若有需要可以詢問是否能代為安排</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                            <a>教室學科:</a>
                                        </td>
                                        <td class="fs-6">
                                            <a>iDiving 士林前港店（台北市士林區前港街 8 號）</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <a>海洋實習:</a>
                                        </td>
                                        <td class="fs-6">
                                            <a>iDiving 東北角龍洞店（新北市貢寮區龍洞街 66-1 號）</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="color:green;vertical-align:text-top;">
                                            <a>註: </a>
                                        </td>
                                        <td class="fs-6" style="color:green;">
                                            <a>
                                                上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修等），若有額外增加的費用本中心將自行吸收，不會另外收費，但學員不可因課程時間調整而要求退費。
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 特約課程 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">特約課程</h3>
                            <hr>
                            <ul>
                                <li>學科部份，每另外約一日課程加價 300 元</li>
                                <li>術科部份，每另外約一日課程加價 500 元</li>
                                <li>三人同行，一起約定特殊時間上課，不須額外加價。</li>
                                <li>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</li>
                            </ul>
                        </div>
                        <!-- 課程更改 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">課程更改</h3>
                            <hr>
                            <ul>
                                <li>課程須於開始上課後的兩個月內完成。</li>
                                <li>上課日期之更改須在 10 天前告知。</li>
                                <li>上課日期 10 天內調整，每次需收取調整費 500 元。</li>
                                <li>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</li>
                                <li>因身體不適，出示醫院掛號單據者則不收取。</li>
                                <li>女性夥伴參加課程前請自行評估生理期影響。</li>
                            </ul>
                        </div>
                        <!-- 課程取消 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">課程取消</h3>
                            <hr>
                            <ul>
                                <li>學員自行放棄課程，本訓練中心將不予退費。</li>
                                <li>若因身體因素不適合從事潛水活動，並開立醫生證明者，除了NT 3,000元購買數位教材的訂金，其餘將會退還。</li>
                            </ul>
                            <br>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink>CCR ER (Air/Trimix)</a>
                    <ng-template ngbNavContent class="custom-class">
                        <br>
                        <h3 style="font-family:DFKai-sb;">全密閉循環水肺延伸領域 CCR Extended Range Air/Trimix</h3>
                        <hr>
                        <!-- 課程內容 -->
                        <div>
                            <a>報名條件：已取得CCR（SF2）執照，並使用CCR(SF2) 30小時的潛水紀錄 或 已完成OC 減壓30小時。</a>
                            <br>
                            <br>
                            <h3 style="font-family:DFKai-sb;">開課內容</h3>
                            <ul>
                                <li class="fs-6">
                                    學科 (6hr + 3hr TRMIX)<br>
                                    AIR：減壓潛水生理學、減壓理論、CCR減壓潛水計畫、減壓備用計畫<br>
                                    Trimix：增加課程氦氮氧混合氣理論、CCR氦氮氧混合計計算
                                </li>
                                <li class="fs-6">
                                    平靜/開放水域 (8次)<br>
                                    氧氣沖刷、稀釋氣體沖刷、氧濃度過高、氧濃度過低、高碳酸血症、SCR模式、連接離機氣體、BOOM、逃生程序練習、O2 Sensor檢驗、團隊檢查、安全檢查、耗氣量檢測、引導繩設置、BC故障、潛水計畫執行、備用計畫執行
                                </li>
                            </ul>
                            <br>
                            <img src="../../../../assets/Image/Data/CCR_ER_01.png" width="60%"
                                style="display:block; margin:auto;">
                        </div>
                        <!-- 課程特色 -->
                        <div>
                            <h3 style="font-family:DFKai-sb;"><br>課程特色</h3>
                            <ul class="fs-6">
                                <li>
                                    學會使用循環水肺設備的減壓操作。
                                </li>
                                <li>
                                    無須購買裝備，課程包含裝備使用。
                                </li>
                                <li>
                                    安全使用CCR設備，遇到任何問題都能順利解決。
                                </li>
                                <li>
                                    可暢遊40/45米以內執行25分鐘的無聲潛水。
                                </li>
                                <li>
                                    一同去未知的深海探險，尋找水下沈船與遺跡。
                                </li>
                            </ul>
                            <br>
                            <img src="../../../../assets/Image/Data/CCR_ER_02.png" width="100%"
                                style="display:block; margin:auto;">
                        </div>
                        <!-- 課程規劃 -->
                        <div>
                            <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td width="10%">
                                        </td>
                                        <td>
                                            <a class="fs-6">
                                                DAY 1 | 學科 19:00~22:00<br>
                                                <hr>
                                                潛水生理學、減壓理論
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <a class="fs-6">
                                                <br>DAY 2 | 學科 19:00~22:00<br>
                                                <hr>
                                                CCR潛水減壓計畫、減壓備用計畫
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <a class="fs-6">
                                                <br>DAY 3-4 | 平靜水域08:00~18:00<br>
                                                <hr>
                                                氧氣沖刷、稀釋氣體沖刷、氧濃度過高、氧濃度過低、高碳酸血症、SCR模式、連接離機氣體、BOOM、逃生程序練習、O2 Sensor檢驗、團隊檢查、安全檢查、耗氣量檢測、引導繩設置、BC故障、減壓計畫執行、備用計畫執行
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <a class="fs-6">
                                                <br>DAY * | 開放水域08:00~18:00 (TRIMIX 選修 )<br>
                                                <hr>
                                                氦氣的優缺點、氦氣的風險、氦氣減壓計畫
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <a class="fs-6">
                                                <br>DAY 5-6 | 開放水域08:00~18:00<br>
                                                <hr>
                                                潛水計畫執行、備用計畫執行
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <img class="d-block" src="../../../../assets/Image/Data/CCR_ER_Schedule.png" width="100%"
                                style="display:block; margin:auto;">
                        </div>
                        <!-- 課程學費 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">課程學費</h3>
                            <hr>
                            <ul class="fs-6">
                                <li style="color: green;">AIR定價 40,000 元、Trimix定價50,000元</li>
                                <li>現金優惠，送30米鋁合金手輪。</li>
                                <li>多人同行優惠：兩人以上同行，免收船潛費。</li>
                                <li style="color: red;">以上現金優惠、證照優惠可合併使用。</li>
                                <li>費用包含：SSI數位教材、國際數位證照申請、教練費、訓練期間潛水重裝使用（CCR裝備、階段氣瓶與調節器）、吸附劑與氣體、旅遊平安保險。</li>
                                <li>費用不含：浮潛裝備租借、海洋實習交通與用餐費用、兩支船潛3000元。</li>
                                <li style="color: red;">如未能順利通過考試標準，複訓一天4000（含裝備）元。</li>
                            </ul>
                            <br>
                        </div>
                        <!-- 報名方式 -->
                        <div>
                            <br>
                            <app-paymentmethod></app-paymentmethod>
                            <br>
                        </div>
                        <!-- 報名須知 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">報名須知</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <p>
                                                基本條件需年滿18歲、已擁有50次的潛水紀錄，並具有深潛與高氧證照，健康狀況良好
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <a
                                                href="https://drive.google.com/file/d/13Lltu9vx3duMY8BWTbkFMDUY5FbmipIw/view?pli=1">健康聲明書在此請點擊</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 上課地點 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">上課地點</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <a>往返之交通請自行處理，若有需要可以詢問是否能代為安排</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                            <a>教室學科:</a>
                                        </td>
                                        <td class="fs-6">
                                            <a>iDiving 士林前港店（台北市士林區前港街 8 號）</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <a>海洋實習:</a>
                                        </td>
                                        <td class="fs-6">
                                            <a>iDiving 東北角龍洞店（新北市貢寮區龍洞街 66-1 號）</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="color:green;vertical-align:text-top;">
                                            <a>註: </a>
                                        </td>
                                        <td class="fs-6" style="color:green;">
                                            <a>
                                                上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修等），若有額外增加的費用本中心將自行吸收，不會另外收費，但學員不可因課程時間調整而要求退費。
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 特約課程 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">特約課程</h3>
                            <hr>
                            <ul>
                                <li>學科部份，每另外約一日課程加價 300 元</li>
                                <li>術科部份，每另外約一日課程加價 500 元</li>
                                <li>三人同行，一起約定特殊時間上課，不須額外加價。</li>
                                <li>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</li>
                            </ul>
                        </div>
                        <!-- 課程更改 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">課程更改</h3>
                            <hr>
                            <ul>
                                <li>課程須於開始上課後的兩個月內完成。</li>
                                <li>上課日期之更改須在 10 天前告知。</li>
                                <li>上課日期 10 天內調整，每次需收取調整費 500 元。</li>
                                <li>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</li>
                                <li>因身體不適，出示醫院掛號單據者則不收取。</li>
                                <li>女性夥伴參加課程前請自行評估生理期影響。</li>
                            </ul>
                        </div>
                        <!-- 課程取消 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">課程取消</h3>
                            <hr>
                            <ul>
                                <li>學員自行放棄課程，本訓練中心將不予退費。</li>
                                <li>若因身體因素不適合從事潛水活動，並開立醫生證明者，除了NT 3,000元購買數位教材的訂金，其餘將會退還。</li>
                            </ul>
                            <br>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3">
                    <a ngbNavLink>CCR (Diving + ER)</a>
                    <ng-template ngbNavContent class="custom-class">
                        <br>
                        <h3 style="font-family:DFKai-sb;">全密閉循環水肺延伸領域 CCR Diving + CCR Extended Range Air/Trimix</h3>
                        <hr>
                        <!-- 課程內容 -->
                        <div>
                            <a>報名條件：減壓滿30小時。</a>
                            <br>
                            <br>
                            <h3 style="font-family:DFKai-sb;">開課內容</h3>
                            <ul>
                                <li class="fs-6">
                                    學科(9hr + 3hr TRMIX)<br>
                                    AIR：循環水肺原理、潛水物理學、潛水生理學、SF2裝備介紹、減壓潛水生理學、減壓理論、CCR減壓潛水計畫、減壓備用計畫<br>
                                    Trimix：增加課程氦氮氧混合氣理論、CCR氦氮氧混合計計算
                                </li>
                                <li class="fs-6">
                                    平靜/開放水域 (10次)<br>
                                    氧氣沖刷、稀釋氣體沖刷、氧濃度過高、氧濃度過低、高碳酸血症、SCR模式、連接離機氣體、安全檢查、耗氣量檢測、潛水計畫、備用計畫、逃生程序、部屬SMB、一口氣15m踢動
                                </li>
                            </ul>
                            <br>
                            <img src="../../../../assets/Image/Data/CCR_DIVINGER_01.png" width="60%"
                                style="display:block; margin:auto;">
                        </div>
                        <!-- 課程特色 -->
                        <div>
                            <h3 style="font-family:DFKai-sb;"><br>課程特色</h3>
                            <ul class="fs-6">
                                <li>
                                    從OC進入CC，延續你的減壓潛水。
                                </li>
                                <li>
                                    學會使用循環水肺設備的減壓操作。
                                </li>
                                <li>
                                    無須購買裝備，課程包含裝備使用。
                                </li>
                                <li>
                                    安全使用CCR設備，學會解決CCR會遇到的問題。
                                </li>
                                <li>
                                    可暢遊40/45米以內執行25分鐘的無聲潛水。
                                </li>
                                <li>
                                    一同去未知的深海探險，尋找水下沈船與遺跡。
                                </li>
                            </ul>
                            <br>
                            <img src="../../../../assets/Image/Data/CCR_DIVINGER_02.png" width="100%"
                                style="display:block; margin:auto;">
                        </div>
                        <!-- 課程規劃 -->
                        <div>
                            <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td width="10%">
                                        </td>
                                        <td>
                                            <a class="fs-6">
                                                DAY 1-3 | 學科 19:00~22:00<br>
                                                <hr>
                                                循環水肺原理、潛水生理學、減壓理論、SF2裝備介紹、CCR潛水減壓計畫、減壓備用計畫
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <a class="fs-6">
                                                <br>DAY * | 19:00~22:00 (TRMIX 選修 )<br>
                                                <hr>
                                                氦氣的優缺點、氦氣的風險、氦氣減壓計畫
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <a class="fs-6">
                                                <br>DAY 4 5 | 平靜/開放水域08:00~18:00<br>
                                                <hr>
                                                CCR組裝與調整、呼吸迴路最小化、中性浮力練習、踢動練習，喉管排水、階段氣瓶操作
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <a class="fs-6">
                                                <br>DAY 6 7 | 平靜/開放水域08:00~18:00<br>
                                                <hr>
                                                中性浮力調整、踢動練習、交換階段氣瓶、逃生程序練習、氧氣沖刷、稀釋氣體沖刷、氧濃度過高、氧濃度過低、高碳酸血症、SCR模式、連接離機氣體
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <a class="fs-6">
                                                <br>DAY 8 9 | 開放水域08:00~18:00<br>
                                                <hr>
                                                BOOM、逃生程序練習、O2 Sensor檢驗、團隊檢查、安全檢查、耗氣量檢測、逃生程序、部屬SMB、BC故障、潛水計畫執行、備用計畫執行
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <img class="d-block" src="../../../../assets/Image/Data/CCR_DIVINGER_Schedule.png" width="100%"
                                style="display:block; margin:auto;">
                        </div>
                        <!-- 課程學費 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">課程學費</h3>
                            <hr>
                            <ul class="fs-6">
                                <li style="color: green;">AIR定價 72,000 元、Trimix定價82,000元</li>
                                <li>現金優惠，送30米鋁合金手輪。</li>
                                <li>兩人以上同行，折扣2,000元並免收船潛費用。</li>
                                <li style="color: red;">以上現金優惠、證照優惠可合併使用。</li>
                                <li>費用包含：SSI數位教材、國際數位證照申請、教練費、訓練期間潛水重裝使用（CCR裝備、階段氣瓶與調節器）、吸附劑與氣瓶、旅遊平安保險。</li>
                                <li>費用不含：浮潛裝備租借、海洋實習交通與用餐費用、兩支船潛3000元。</li>
                                <li style="color: red;">如未能順利通過考試標準，複訓一天4000（含裝備）元。</li>
                            </ul>
                            <br>
                        </div>
                        <!-- 報名方式 -->
                        <div>
                            <br>
                            <app-paymentmethod></app-paymentmethod>
                            <br>
                        </div>
                        <!-- 報名須知 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">報名須知</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <p>
                                                基本條件需年滿18歲、已擁有50次的潛水紀錄，並具有深潛與高氧證照，健康狀況良好
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <a
                                                href="https://drive.google.com/file/d/13Lltu9vx3duMY8BWTbkFMDUY5FbmipIw/view?pli=1">健康聲明書在此請點擊</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 上課地點 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">上課地點</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <a>往返之交通請自行處理，若有需要可以詢問是否能代為安排</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                            <a>教室學科:</a>
                                        </td>
                                        <td class="fs-6">
                                            <a>iDiving 士林前港店（台北市士林區前港街 8 號）</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <a>海洋實習:</a>
                                        </td>
                                        <td class="fs-6">
                                            <a>iDiving 東北角龍洞店（新北市貢寮區龍洞街 66-1 號）</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="color:green;vertical-align:text-top;">
                                            <a>註: </a>
                                        </td>
                                        <td class="fs-6" style="color:green;">
                                            <a>
                                                上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修等），若有額外增加的費用本中心將自行吸收，不會另外收費，但學員不可因課程時間調整而要求退費。
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 特約課程 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">特約課程</h3>
                            <hr>
                            <ul>
                                <li>學科部份，每另外約一日課程加價 300 元</li>
                                <li>術科部份，每另外約一日課程加價 500 元</li>
                                <li>三人同行，一起約定特殊時間上課，不須額外加價。</li>
                                <li>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</li>
                            </ul>
                        </div>
                        <!-- 課程更改 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">課程更改</h3>
                            <hr>
                            <ul>
                                <li>課程須於開始上課後的兩個月內完成。</li>
                                <li>上課日期之更改須在 10 天前告知。</li>
                                <li>上課日期 10 天內調整，每次需收取調整費 500 元。</li>
                                <li>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</li>
                                <li>因身體不適，出示醫院掛號單據者則不收取。</li>
                                <li>女性夥伴參加課程前請自行評估生理期影響。</li>
                            </ul>
                        </div>
                        <!-- 課程取消 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">課程取消</h3>
                            <hr>
                            <ul>
                                <li>學員自行放棄課程，本訓練中心將不予退費。</li>
                                <li>若因身體因素不適合從事潛水活動，並開立醫生證明者，除了NT 3,000元購買數位教材的訂金，其餘將會退還。</li>
                            </ul>
                            <br>
                        </div>
                    </ng-template>
                </li>
            </ul>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>

            <!-- <pre>Active: {{ active }}</pre> -->

        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>