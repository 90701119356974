<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">愛潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">輕裝</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <!-- https://mdbootstrap.com/docs/angular/content/images/ -->
    <!-- https://coreui.io/docs/4.1/components/card/ -->
    <!-- https://www.codegrepper.com/code-examples/csharp/mdb+bootstrap+cards -->
    <!-- photoalbum -->
    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>面鏡/呼吸管</a>
                    <ng-template ngbNavContent class="custom-class">
                        <br>
                        <div class="row justify-content-around">
                            <div class="col-6 col-sm-4 mb-4" *ngFor="let equipmentms of equipmentmss | async" style="width:350px">
                                <div class="card">
                                    <br>
                                    <img class="card-img-top" src='{{equipmentms.img}}' alt="Card image" style="width:100%;">
                                    <!-- " left:200px;top:200px;"> -->
                                    <div class="card-body">
                                        <p class="fs-6 card-text">{{equipmentms.label}}</p>
                                        <h5 class="card-title">{{equipmentms.name}}</h5>
                                        <p class="card-text" style="white-space: pre">{{equipmentms.price}}</p>
                                        <p class="card-text">{{equipmentms.title}}</p>
                                        <!-- <a href="https://www.facebook.com/janicewa2002" class="btn btn-primary">This button</a> -->
                                        <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink>防寒衣/配件</a>
                    <ng-template ngbNavContent>
                        <br>
                        <div class="row justify-content-around">
                            <div class="col-6 col-sm-4 mb-4" *ngFor="let equipmenthb of equipmenthbs | async" style="width:350px">
                                <div class="card">
                                    <br>
                                    <img class="card-img-top" src='{{equipmenthb.img}}' alt="Card image" style="width:100%;">
                                    <!-- " left:200px;top:200px;"> -->
                                    <div class="card-body">
                                        <p class="fs-6 card-text">{{equipmenthb.label}}</p>
                                        <h5 class="card-title">{{equipmenthb.name}}</h5>
                                        <p class="card-text" style="white-space: pre">{{equipmenthb.price}}</p>
                                        <p class="card-text">{{equipmenthb.title}}</p>
                                        <!-- <a href="https://www.facebook.com/janicewa2002" class="btn btn-primary">This button</a> -->
                                        <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3">
                    <a ngbNavLink>蛙鞋</a>
                    <ng-template ngbNavContent>
                        <br>
                        <div class="row justify-content-around">
                            <div class="col-6 col-sm-4 mb-4" *ngFor="let equipmentl of equipmentls | async" style="width:350px">
                                <div class="card">
                                    <br>
                                    <img class="card-img-top" src='{{equipmentl.img}}' alt="Card image" style="width:100%;">
                                    <!-- " left:200px;top:200px;"> -->
                                    <div class="card-body">
                                        <p class="fs-6 card-text">{{equipmentl.label}}</p>
                                        <h5 class="card-title">{{equipmentl.name}}</h5>
                                        <p class="card-text" style="white-space: pre">{{equipmentl.price}}</p>
                                        <p class="card-text">{{equipmentl.title}}</p>
                                        <!-- <a href="https://www.facebook.com/janicewa2002" class="btn btn-primary">This button</a> -->
                                        <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>

            <!-- <pre>Active: {{ active }}</pre> -->

        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>