import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-j100',
  templateUrl: './j100.component.html',
  styleUrl: './j100.component.scss'
})

export class J100Component implements OnInit {

  active = 1;
  active1 = 1;
  active2 = 1;

  constructor() { }

  ngOnInit(): void {
  }
}
