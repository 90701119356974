<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">學潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">課程年度計畫</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <table class="table table-striped">
                <thead>
                    <!-- <th *ngFor="let head of headElements" scope="col">{{head}} </th> -->
                    <th colspan="2">
                        <h4>課程年度計畫 </h4>
                    </th>
                </thead>
                <tbody>
                    <!-- <tr mdbTableCol *ngFor="let el of elements"> -->
                        <!-- <th scope="row">{{el.id}}</th>
                        <td>{{el.first}}</td>
                        <td>{{el.last}}</td>
                        <td>{{el.handle}}</td>-->
                    <tr *ngFor="let course of courses | async ">
                        <!-- <th scope="row">{{course.id}}</th> -->
                        <td class="fs-6">{{course.name}}</td>
                        <td class="fs-6">{{course.date}}</td>
                        <td class="fs-6" style="white-space:nowrap">
                            <a href="/#/cz01" style="text-decoration:none;">
                                {{course.state}}
                            </a>
                        </td> 
                    </tr>
                </tbody>
            </table>
            <br>
            <button type="button" mdbBtn class="fs-6" color="primary" block="true" onclick="window.location.href='/#/'" mdbWavesEffect>回首頁</button>
            <br>
            <br>
            <br>
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>