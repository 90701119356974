import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dz02',
  templateUrl: './dz02.component.html',
  styleUrls: ['./dz02.component.scss']
})
export class Dz02Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
