<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">玩潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">馬爾地夫</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <h3 style="font-family:DFKai-sb;">馬爾地夫</h3>
            <hr>
            <br>
            <!-- 景點介紹 -->
            <div>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="font-family:DFKai-sb;">【景點介紹】</h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="70%" cellpadding="0" cellspacing="0" style="text-align: justify;">
                    <tr>
                        <td>
                            <table width="100%">
                                <tr>
                                    <td width="55%">
                                        <table cellpadding="0" cellspacing="0" width="100%">
                                            <tr>
                                                <td>
                                                    <strong>馬爾地夫</strong><br />
                                                    地理位置：位於印度洋赤道附近，距離斯里蘭卡670公里，印度南方600公里，斯里蘭卡西南方650公里，由1190個南北縱列之珊瑚礁小島組成，其中僅200個島嶼有人居住，各島平均不超過海拔1.8米。面積共九萬平方公里，其中海洋佔總面積之99％，而陸地僅佔01％。馬爾地夫的首都是馬列(Male)，人口約33萬。馬列本島有計程車、機車、腳踏車。島與島之間則以DHONI船與快艇聯繫。
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td><img src="../../../assets/Image/Data/Maledives_01.jpg"
                                            style="float: right; width: 70%"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <table width="100%">
                                <tr>
                                    <td width="55%">
                                        <table cellpadding="0" cellspacing="0" width="100%">
                                            <tr>
                                                <td>
                                                    <strong>氣候</strong><br />
                                                    熱帶氣候，終年溫度維持在24度至33度間，每年5月至10月為雨季，時有雷陣雨；11月至4月為乾季，年均溫28°C。乾季水溫穩定保持在28℃。雨季時，水溫受西南季風引響稍降。全年水下生物都很豐富，有些著名潛點還可以輕易與鯨鯊相遇，造就了馬爾地夫潛水天堂的美名。
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>人文</strong><br />
                                        馬爾地夫的語言是底貝希語(DHIVEHI)及英語。信仰為回教，治安良好，不過於當地旅遊住宿時，仍可將貴重品、現金及護照寄存於保險箱。流通之貨幣為Maldivian
                                        Rufiyaa（MVR）１MVR約合台幣２元；美金、信用卡也可以使用。電壓為220～240伏特，圓形和方形電插頭。
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td>
                            <img src="../../../assets/Image/Data/Maledives_02.jpg" style="float: right; width: 70%">
                        </td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>船宿：J & J Home</strong><br />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            J & J
                            Home是馬爾地夫知名的高級船宿潛水船。共有下層甲板8間雙人房及1間家庭房（可容納3人）和上層甲板3間海景房及1間蜜月房總共可容納25位潛水客，各房皆有獨立衛浴，每天潛水結束可享受一個熱水澡，洗淨一天的疲憊。上層甲板還有卡拉ok娛樂室及戶外酒吧，當然更少不了按摩浴缸。食的部分，船上有華裔大廚負責三餐，不用擔心吃不慣當地食物。潛水部分由一艘當地特殊的潛水船接送潛水，潛水船非常舒適，且全程使用高氧讓潛水過程更加安全。
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <table width="100%">
                                <tr>
                                    <td><img src="../../../assets/Image/Data/Maledives_03.jpg" width="100%"></td>
                                    <td>&nbsp;</td>
                                    <td><img src="../../../assets/Image/Data/Maledives_04.jpg" width="100%"></td>
                                    <td>&nbsp;</td>
                                    <td><img src="../../../assets/Image/Data/Maledives_05.jpg" width="100%"></td>
                                    <td>&nbsp;</td>
                                    <td><img src="../../../assets/Image/Data/Maledives_06.jpg" width="100%"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 著名潛點 -->
            <div>
                <br>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="font-family:DFKai-sb;">【著名潛點】</h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="70%" style="text-align: justify;">
                    <tr>
                        <td>
                            <ol>
                                <li>Angaga 位於南阿裡環礁的中間，在馬爾地夫海灘裡享受一種優美自然的氣氛，此地有許多庇護區、沙底環礁湖。</li>
                                <li>Eriyadu大多數形成一個緩坡下降至 30 米的礁，5個入口點圍繞整個環礁。珊瑚岩礁上的形成一個特別的海牆，東部邊緣又有著許多不同魚種。</li>
                                <li>Thulagiri 位於環礁北雄島的中間，從機場搭快艇只需 25 分鐘。
                                    棕櫚樹遮蔭內部一個海灘，使得大的環礁湖庇護工廠顯露出許多海裡小生物，讓北環礁島南端成為出色的潛水地點。</li>
                                <li>Helengeli 位於北環礁的遠端北東部邊緣。在過去 25
                                    的年來，此島已蔚，馬爾地夫最好潛水勝地的美譽。島嶼的形態和上邊緣環礁的位置組合，絕對讓潛水員的有一個很棒的潛水經驗。</li>
                                <li>Vilamendhoo 位於弗環礁的東南角，位於最驚險的潛水地點，代表著馬爾地夫的心。這個長橢圓形的小島位於兩個通道之間，擁有一個每年吸引潛水和浮潛後的特殊房子礁。
                                </li>
                                <li>Vilamendhoo 經過妥善管理和維護，有助於在島的自然外觀最佳的環境方案之一。</li>
                                <li>Reethi Beach位於北東邊緣的南 Maalhosmadulu 環礁島，在 Reethi 灣美麗的房子礁，結合卓越船潛地點的選擇。相比其他環礁島的馬爾代夫的環礁
                                    baa 島是較淺。強流產生大量的令人難忘的通道和 Thila 潛水。</li>
                                <li>Hanifaru 灣，西南季風啟動whalesharks 的環礁，為海洋資源管理受保護的區域。</li>
                            </ol>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table width="100%">
                                <tr>
                                    <td><img src="../../../assets/Image/Data/Maledives_07.jpg" width="100%"></td>
                                    <td>&nbsp;</td>
                                    <td><img src="../../../assets/Image/Data/Maledives_08.jpg" width="100%"></td>
                                    <td>&nbsp;</td>
                                    <td><img src="../../../assets/Image/Data/Maledives_09.jpg" width="100%"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 建議行程 -->
            <div>
                <br>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="font-family:DFKai-sb;">【建議行程】</h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="70%" style="text-align: justify;">
                    <tr>
                        <td>
                            第一天 台北/新加坡/馬爾地夫馬列 搭乘新加坡航空<br>
                            國際：TPE/SIN SQ877 14:20~18:55 飛行4小時35分 等待轉機時於機場用餐<br>
                            國際：SIN/MLE SQ452 20:45~22:10 飛行4小時25分（3小時時差）<br>
                            <br>
                            上午集合於中正機場，搭乘國際班機，經轉機前往藍色珊瑚礁島－馬爾地夫，放逐到馬爾地夫的渡假心情是愉悅而充滿憧憬的。您的人生故事，從此留有微醺的章節。飛抵馬列機場後馬上由船上人員接往船宿船(
                            J＆J Home)。在海風迎面的碼頭前卸下塵積已久陰霾與壓力，在珊瑚礁旁與色彩斑斕的熱帶魚相見歡。所有的夢想，馬爾地夫將它還原成真。<br>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            第二天(14/10/2019) Male Atoll<br>
                            <ol>
                                <li>Kurumba house reef</li>
                                <li>
                                    Lankan manta point<br>
                                    通過海峽到NorthAtoll需五個小時
                                </li>
                                <li>Maayaa Lagoon-manta night (North Ari Atoll)</li>
                            </ol>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            第三天(15/10/2019) North Ari Atoll<br>
                            <ol>
                                <li>Maayaa Thila</li>
                                <li>Kan Thila</li>
                                <li>Fish Head</li>
                                <li>Fesdu Bay night dive</li>
                            </ol>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            第四天(16/10/2019) North Ari Atoll<br>
                            <ol>
                                <li>Fesdu wreck</li>
                                <li>Moofushi Rock</li>
                                <li>Dhigga Thila (South Ari Atoll)</li>
                            </ol>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <table width="100%">
                                <tr>
                                    <td><img src="../../../assets/Image/Data/Maledives_10.jpg" width="100%"></td>
                                    <td>&nbsp;</td>
                                    <td><img src="../../../assets/Image/Data/Maledives_11.jpg" width="100%"></td>
                                    <td>&nbsp;</td>
                                    <td><img src="../../../assets/Image/Data/Maledives_12.jpg" width="100%"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            第五天(17/10/2019) South Ari Atoll<br>
                            <ol>
                                <li>Mahibadhoo Rock</li>
                                <li>Maamigili Beyru</li>
                            </ol>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            第六天( 03 / 10 / 2013 ) South Ari Atoll/North Ari Atoll /Male’ Atoll 2支船潛<br>
                            <ol>
                                <li>Dhidhoo Beyru</li>
                                <li>Dhigurah Beyru</li>
                                <li>Alimata Jettey night dive (Vaavu Atoll)</li>
                            </ol>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            第七天(19/10/2019) Vaavu Atoll<br>
                            <ol>
                                <li>Miyaru Kandu</li>
                                <li>Kandoomaa Thila (South Male)</li>
                            </ol>
                            最後一天上午完成潛水行程，下午回到馬列附近可在船上玩9m的充氣溜滑梯，並享受按摩浴缸及日光浴，也可在卡拉ok娛樂室高歌一曲，晚上繼續歡樂度過船上的最後一晚。
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            第八天(20/10/2019)<br>
                            早上10點check out 後，接駁船直接送抵馬列國際機場結束快樂的船宿假期。<br>
                            <br>
                            馬爾地夫/新加坡/台北<br>
                            國際：MLE/SIN SQ5481 12:50~20:40 飛行4小時50分 於新加坡樟宜機場過夜<br>
                            國際：SIN/TPE SQ876 08:20~13:10 飛行4小時50分
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <table width="100%">
                                <tr>
                                    <td><img src="../../../assets/Image/Data/Maledives_13.jpg" width="100%"></td>
                                    <td>&nbsp;</td>
                                    <td><img src="../../../assets/Image/Data/Maledives_14.jpg" width="100%"></td>
                                    <td>&nbsp;</td>
                                    <td><img src="../../../assets/Image/Data/Maledives_15.jpg" width="100%"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 注意事項 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【注意事項】</h3>
                <table>
                    <tr>
                        <td>
                            <ol class="fs-6">
                                <li>填寫＂Google 表單＂報名，並繳交團費<span class="Large Important"> NT$5,000
                                        的『定金』</span>才算完成報名流程，若以轉帳方式繳交定金，請將您的匯款日期及帳號末五碼與金額傳至iDiving官方Line
                                        &#64;帳號，以便確認。若以現金（or匯款）繳交全額費用，享有現金優惠！</li>
                                <li>國內潛水旅遊活動會有成團人數要求，含領隊需達10人才會出團；<span
                                        class="Large Important">若因報名人數不足而無法出團，最遲將於出團日14天以前通知已報名活動之團員，『定金』可全數退還。</span>
                                </li>
                                <li><span class="Large Important">出團日30天以前會於Line的活動群組提供行程資料，若有任何需求請提出討論。</span></li>
                                <li>當人數達到出團門檻，所有團員亦繳清費用時，則保證出團，若有人員臨時取消造成出團人數不足亦不受影響，而臨時退團人員的退費規則如下：<br />
                                    <span class="Large Important">A. 活動開始前第十五日以上取消者，須賠償團費的百分之十。</span><br />
                                    <span class="Large Important">B. 活動開始前第五日至第十四日取消者，須賠償團費的百分之三十。</span><br />
                                    <span class="Large Important">C. 活動開始前第二日至第四日取消者，須賠償團費的百分之五十。</span><br />
                                    <span class="Large Important">D. 於活動當日或前一日取消，或未通知不參加者，須賠償團費全額。</span><br />
                                    *若有溢繳或可退款的費用，可選擇來店退款或刷退（須視當時繳費方式），若要匯款轉帳退費須扣除手續費（每筆NT 30元），亦可選擇保留餘額轉至其他消費。
                                </li>
                                <li>若遇颱風、浪況不佳等不可抗拒之因素，基於安全考量，iDiving將保有取消活動之權利，已繳交的費用於扣除必要支出（如代訂大眾交通的退票手續費）後退還。</li>
                                <li>國內潛水旅遊皆為完整套裝行程，如有個人因素更動行程內容，恕不提供會員優惠。</li>
                                <li>住宿房間之房等差異（套房、雅房、通鋪等），如現場遇特殊情況，將由領隊視情況協調分配。若有特殊需求請事先提出。</li>
                                <li>須持有合格的潛水證照（國際潛水證照初級以上資格），若要參加特殊環境潛水（如：夜潛），需具備相關資格；部分活動可能會有額外條件要求。</li>
                                <li>活動行程以Line活動群組公布的資訊為準。潛點選擇由潛導視當時天候、海況、團隊狀況決定。進行潛水活動時採潛伴制進行，請彼此相互照應。</li>
                                <li>禁帶漁獵器材，亦禁止攜帶任何生物上岸，違者不得再參加後續潛水活動。</li>
                                <li>填寫＂Google表單＂報名者，視同同意以上所有條款。</li>
                            </ol>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 協辦旅行社 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【協辦旅行社】</h3>
                <div>iDiving 為了讓旅遊活動有保障，所有旅遊行程特別與旅行社偕同舉辦，讓你玩得開心又有保障。</div>
                <br />
                <table border="1" cellpadding="0" cellspacing="0" width="90%">
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">主辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">愛潛水股份有限公司</td>
                        <td>承辦人：愛潛水</td>
                        <td>行動電話：0909-097696</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2882-7696</td>
                        <td>FAX：02-2882-7656</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAILl：info&#64;idiving.com.tw</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市士林區前港街8號</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">協辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">萬陽旅行社有限公司</td>
                        <td>業務請洽：蘇恒璀</td>
                        <td>行動電話：0937-196518</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2517-1333</td>
                        <td>FAX：02-2508-1537</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAIL：tracy410&#64;ms45.hinet.net</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市南京東路3段91號四樓之三</td>
                    </tr>
                </table>
                <br>
            </div>
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>