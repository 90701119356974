<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">愛潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">教練團</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <!-- https://mdbootstrap.com/docs/angular/content/images/ -->
    <!-- https://coreui.io/docs/4.1/components/card/ -->
    <!-- photoalbum -->
    <div class="row d-flex justify-content-center">
        <div class="col-6 col-md-4 col-lg-2 p-2" *ngFor="let stand of stands" >
            <div class="card p-2">
                <a href={{stand.hrf}}>
                    <img src={{stand.img}} class="card-img-top" alt="...">
                    <div class="mask" style="background-color: rgba(251, 251, 251, 0.15);"></div>
                </a>
                <div class="card-body">
                    <h5 class="card-title">{{stand.name}}</h5>
                    <p class="card-text fs-6" >{{stand.title}}</p>
                    <p class="card-text fs-6" >{{stand.title01}}</p>
                    <p class="card-text fs-6" >{{stand.title02}}</p> <!-- style="white-space: pre" -->
                    <!-- <a href="https://www.facebook.com/janicewa2002" class="btn btn-primary">This button</a> -->
                    <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>