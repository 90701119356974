<div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <h2 class="hwarsayblock">最新消息</h2>
            <br>
            <div *ngFor="let hearsay of hearsays | async">
                <div>
                    <a class="d-inline p-2 {{hearsay.color}} text-white" href="/#/m100#vbn" style="border-radius:10px;padding-bottom: .5em;text-decoration:none;">{{hearsay.item}}</a>
                    &nbsp;
                    <a class="fs-6">{{hearsay.data}}</a>
                </div>
                <br>
                <p class="fs-6 newsblock">{{hearsay.title}}</p>
                <br>
            </div>
        </div>
        <div class="col-xl">
        </div>
    </div>

</div>