<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">玩潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">旅遊年度計畫</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <table class="table table-striped">
                <thead>
                    <th colspan="2">
                        <h4>旅遊年度計畫 </h4>
                    </th>
                </thead>
                <tbody>
                    <tr *ngFor="let activity of activitys | async">
                        <!-- <th scope="row">{{activity.id}}</th> -->
                        <td class="fs-6">{{activity.location}}</td>
                        <td class="fs-6">{{activity.date}}</td>
                        <td class="fs-6" style="white-space:nowrap">
                            <a href="https://goo.gl/forms/AFgsrUnZYexX7L1i1">
                                {{activity.state}}
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <button type="button" mdbBtn class="fs-6" color="primary" block="true" onclick="window.location.href='/#/'" mdbWavesEffect>回首頁</button>
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>