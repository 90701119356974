<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">愛潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">iDiving 前港店</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <br>
        <img src="../../../assets/Image/Data/Store01p01.jpg" width="90%"><br>
        <br>
        <br>
        <div class="col-xl">
            <!-- 前港店 介紹 -->
            <div style="text-align:center;">
                <br>
                <br>
                <h3 style="font-family:DFKai-sb;text-align:center;">iDiving 前港店</h3>
                <hr>
                <p class="fs-6">
                    如果您希望搭捷運就能進行潛水課程，位於台北市的前港店絕對是最佳的選擇！<br>
                    <br>
                    搭乘捷運或公車步行５分鐘即可抵達，利用空閒時間下班就能來上課，下課後還能享受夜市美食。<br>
                    <br>
                    若沒有開車，來前港店搭乘本公司的車即可前往東北角，就算一個人潛水也不怕麻煩<br>
                </p>
                <br>
                <br>
            </div>
            <!-- 前港店 設施服務 -->
            <div style="text-align:center;">
                <hr>
                <h3 style="font-family:DFKai-sb;text-align:center;">設施服務</h3>
                <br>
                <img src="../../../assets/Image/Data/Store01p02.jpg" width="70%">
                <br>
                <p class="fs-6">
                    <br>九位駐店正職教練，提供課程、活動、器材維修等服務<br>
                </p>
                <br>
                <img src="../../../assets/Image/Data/Store01p03.jpg" width="70%">
                <br>
                <p class="fs-6">
                    <br>兩間教室提供同學們一起上課，還有不定時環保講座<br>
                </p>
                <br>
                <img src="../../../assets/Image/Data/Store01p04.jpg" width="70%">
                <br>
                <p class="fs-6">
                    <br>上課地點位於台北市，鄰近各大游泳池，上課好方便<br>
                </p>
                <br>
                <img src="../../../assets/Image/Data/Store01p05.jpg" width="70%">
                <br>
                <p class="fs-6">
                    <br>提供各種尺寸的器材租借服務<br>
                </p>
                <br>
                <br>
            </div>
            <!-- 前港店 交通指南 -->
            <div style="text-align:center;">
                <hr>
                <h3 style="font-family:DFKai-sb;text-align:center;">交通指南</h3>
                <br>
                <img src="../../../assets/Image/Data/Store01p06.jpg" width="70%">
                <br>
                <p class="fs-6">
                    *停車場資訊：前港公園地下停車場、百齡高中地下停車場<br>
                    *搭乘大眾運輸工具，乘車資訊：台北捷運：劍潭站2號出口／3號出口，往承德路方向步行約5分鐘<br>
                    *公車：26、41、218、280、288、529、616、618、756等至圓山皇宮大廈，往前港街方向步行約3分鐘<br>
                    <br>
                    電話：(02)2882-7696<br>
                    地址： 台北市士林區前港街8號<br>
                    前港店街景：<a href="https://reurl.cc/mvlQXM" target="_blank">https://reurl.cc/mvlQXM</a><br>
                    <br>
                    營業時間：<br>
                    全年周一公休<br>
                    4月-10月 星期二至星期六 14:00~22:00 　周日13:00~19:00<br>
                    11月-3月 星期二至星期六 15:00~21:00 　周日13:00~19:00<br>
                    (以上時間為門市營業時間，潛水訓練時間請依照開課時之規劃)<br><br>
                    <br>
                    <br>
                </p>
            </div>
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>