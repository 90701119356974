<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">學潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">課程價格表</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">

            <div>
                <table  class="table table-striped">
                    <!-- <tr>
                        <th mat-sort-header="name">Dessert (100g)</th>
                        <th mat-sort-header="price">Calories</th>
                    </tr> -->
                    <!-- https://hackmd.io/@Heidi-Liu/angular-data-binding -->
                    <thead>
                        <!-- <th *ngFor="let head of headElements" scope="col">{{head}} </th> -->
                        <th colspan="2">
                            <h4>課程價格表 </h4>
                        </th>
                    </thead>
                    <tbody>
                        <tr [style.font-size.px]="dessert.type" *ngFor="let dessert of sortedData" >
                            <td class="fs-6">{{dessert.name}}</td>
                            <td> &nbsp; </td>
                            <td> &nbsp; </td>
                            <td> &nbsp; </td>
                            <td class="fs-6">{{dessert.price}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br>

        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>