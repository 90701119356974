import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-d420',
  templateUrl: './d420.component.html',
  styleUrls: ['./d420.component.scss']
})
export class D420Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
