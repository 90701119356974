<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">玩潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">澎湖</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <h3 style="font-family:DFKai-sb;">澎湖</h3>
            <hr>
            <br>
            <!-- <img src="../../../assets/Image/Data/Kenting_1..jpg" style="float: right; width: 80%">  class="fs-6" -->
            <!-- 景點介紹 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【景點介紹】</h3>
                <table cellpadding="0" cellspacing="0" style="text-align: justify;">
                    <tr>
                        <td class="fs-6">
                            <img style="float: right; margin: 0 0 50px 50px; vertical-align: text-top;" width="40%"
                                src="../../../assets/Image/Data/Penghu_01.jpg" />
                            <strong>澎湖-南方四島</strong><br />
                            澎湖群島位於臺灣海峽上，是臺灣唯一的島縣，由90座島嶼組成，全島面積126.864平方公里之資料。極東：查母嶼； 極西：花嶼； 極南：七美嶼；
                            極北：大蹺嶼，北回歸線23°27穿過群島之中的虎井嶼之南。火山玄武岩地形遍佈，造就不同於台灣其他地方潛域的特出景致。更因為淺灘海域中年日照充足，加上溫暖的黑潮支流流經，提供此處軟硬珊瑚充足的養分，進而吸引魚群群聚。<br>
                            <br>
                            <strong>氣候</strong><br />
                            澎湖群島的地形低且平，加上位於臺灣海峽中央，因此，氣候 深受雨量與季風的影響。<br><br>
                            雨量：澎湖群島平均年雨量約有1000毫米左右，80%集中在夏季。在陽光照射與東北季風強烈吹襲之下，雨量的蒸發量每年高達1800毫米。因此，群島上的植物自然的演化出較抗風、耐旱的生態系。<br><br>
                            季風：由於澎湖缺乏高山的屏障，冬天的風速較大，每年強風日數高達約100天。根據統計，10月至翌年1月，風速都維持在每秒6公尺以上；但是當結構完整的大陸冷氣團南下時，風速有時會達到8級以上，瞬間陣風則會達到12級左右。
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 著名潛點 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【著名潛點】</h3>
                <table cellpadding="0" cellspacing="0" style="text-align: justify;">
                    <tr>
                        <td class="fs-6">
                            <img style="float: right; margin: 0 0 50px 50px; vertical-align: text-top;" width="40%"
                                src="../../../assets/Image/Data/Penghu_02.jpg" />
                            南方四島的潛點分布主要分為兩個大區：東、西吉嶼潛域和東、西嶼坪潛域<br /><br />
                            <ul class="fs-6">
                                <li>
                                    東、西吉嶼潛域：<br>
                                    東吉嶼為玄武岩地形所構成，除了港口左右兩側為沙灘地形之外，多為海蝕崖等沿岸，不建議進行岸潛。珊瑚礁遍布於島嶼四周，東側淺灘為平靜水域，適合浮潛活動，東北角則是靠近海蝕平台，地形豐富且魚群豐富適合休閒潛水活動。<br>
                                    熱門潛點為：東宮前、大坪前<br>
                                    <br>
                                    西吉嶼珊瑚礁遍佈南側，海域玄武岩地形豐富，加上大型花環肉質軟珊瑚遍佈，魚群穿梭其間，非常適合安排潛水旅遊。<br>
                                    熱門潛點為：花環公園、西吉城堡、西吉斷層<br>
                                </li>
                                <li>
                                    東、西嶼坪潛域：<br>
                                    東嶼坪為一玄武岩方山地形，珊瑚以軸孔珊瑚科、微孔珊瑚科、鹿角珊瑚科為主，島嶼東側為巨石礁、五米礁等地形，礁岩由沙地突起，地形變化豐富。水流稍強由東南流向西北，此地海蝕溝、海蝕洞等海蝕作用明顯，魚群豐富。<br>
                                    熱門潛點為：塔仔北、瀨仔尾、五米礁、巨石礁、鐘仔西、二塭南<br>
                                    <br>
                                    西嶼坪是澎湖自然生態保留區，無法進行登島與周遭海域的潛水活動，但其高聳的海崖地形與週遭島嶼，亦是很值得一訪的熱們潛點。根據2009年的調查報告，西嶼坪的珊瑚覆蓋率為南方四島中最高的，因此此處的珊瑚生態嶼海洋物種相對豐富。<br>
                                    熱門潛點為：生態長廊、擎天岩、西嶼坪北、沙道迷宮
                                </li>
                            </ul>
                        </td>
                    </tr>
                </table>
                <br>
                <table style="text-align: justify;">
                    <tr>
                        <td><img src="../../../assets/Image/Data/Penghu_03.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Penghu_04.jpg" width="100%"></td>
                    </tr>
                </table>
            </div>
            <!-- 建議行程 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【建議行程】</h3>
                <!-- 行程說明 -->
                <div>
                    <h3 style="font-family:DFKai-sb;">行程說明</h3>
                    <table style="text-align: justify;">
                        <tr>
                            <td class="fs-6">
                                第一天<br>
                                第一天上午05:40在松山機場集合，預計搭乘06:35的飛機前往澎湖，馬公機場，中午搭專船接往將軍嶼，隨既環境簡介及分配住宿，午餐後準備兩支船潛，潛點視風浪狀況決定之。<br>
                                <br>
                                <img style="float: right; margin: 0 0 0 50px; vertical-align: text-top;" width="40%"
                                    src="../Image/Data/Penghu_05.jpg" />
                                第二天<br>
                                上午兩支船潛，下午一支船潛。晚餐後自由活動或自費夜潛。<br>
                                <br>
                                第三天<br>
                                （潛水集合時間，會以當地導潛判斷何時適合為主，故無法提前告知集合時間）<br>
                                <br>
                                第四天<br>
                                上午7點準備搭專船返回馬公碼頭，開始馬公一日遊，下午4點，準備搭飛機回台北，結束快樂旅程。（預計航班：AE370/16:20-17:15）
                            </td>
                        </tr>
                    </table>
                </div>
                <!-- 費用說明 -->
                <div>
                    <h3 style="font-family:DFKai-sb;">費用說明</h3>
                    <div class="fs-6">
                        行程費用及更多活動詳情請洽iDiving Line&#64;
                        <a href="https://lin.ee/ge19YeP">
                            <img src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png" height="36" border>
                        </a>
                    </div>
                    <div>
                        <ul class="fs-6">
                            <li>團費原價 : $21,000</li>
                            <li>現金優惠價/會員價：$20,500</li>
                            <li><span class="Large Important">會員現金優惠價：$20,000</span></li>
                        </ul>
                    </div>
                    <table style="text-align: justify;">
                        <tr class="fs-6">
                            <td>
                                註：以上費用活動期間的食宿、三佰萬旅遊平安險,當地將提供機車代步。<br>
                                但不包含潛水裝備租借的費用及個人性質的花費。 澎湖團費用已包含台北～馬公機場機票費用（大約 $4,100）。
                            </td>
                        </tr>
                        <tr>
                            <td style="white-space:nowrap">
                                <a target="_blank" href="https://goo.gl/forms/AFgsrUnZYexX7L1i1">立即報名</a>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <!-- 注意事項 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【注意事項】</h3>
                <table>
                    <tr>
                        <td>
                            <ol class="fs-6">
                                <li>填寫＂Google 表單＂報名，並繳交團費<span class="Large Important"> NT$5,000
                                        的『定金』</span>才算完成報名流程，若以轉帳方式繳交定金，請將您的匯款日期及帳號末五碼與金額傳至iDiving官方Line
                                        &#64;帳號，以便確認。若以現金（or匯款）繳交全額費用，享有現金優惠！</li>
                                <li>國內潛水旅遊活動會有成團人數要求，含領隊需達10人才會出團；<span
                                        class="Large Important">若因報名人數不足而無法出團，最遲將於出團日14天以前通知已報名活動之團員，『定金』可全數退還。</span>
                                </li>
                                <li><span class="Large Important">出團日30天以前會於Line的活動群組提供行程資料，若有任何需求請提出討論。</span></li>
                                <li>當人數達到出團門檻，所有團員亦繳清費用時，則保證出團，若有人員臨時取消造成出團人數不足亦不受影響，而臨時退團人員的退費規則如下：<br />
                                    <span class="Large Important">A. 活動開始前第十五日以上取消者，須賠償團費的百分之十。</span><br />
                                    <span class="Large Important">B. 活動開始前第五日至第十四日取消者，須賠償團費的百分之三十。</span><br />
                                    <span class="Large Important">C. 活動開始前第二日至第四日取消者，須賠償團費的百分之五十。</span><br />
                                    <span class="Large Important">D. 於活動當日或前一日取消，或未通知不參加者，須賠償團費全額。</span><br />
                                    *若有溢繳或可退款的費用，可選擇來店退款或刷退（須視當時繳費方式），若要匯款轉帳退費須扣除手續費（每筆NT 30元），亦可選擇保留餘額轉至其他消費。
                                </li>
                                <li>若遇颱風、浪況不佳等不可抗拒之因素，基於安全考量，iDiving將保有取消活動之權利，已繳交的費用於扣除必要支出（如代訂大眾交通的退票手續費）後退還。</li>
                                <li>國內潛水旅遊皆為完整套裝行程，如有個人因素更動行程內容，恕不提供會員優惠。</li>
                                <li>住宿房間之房等差異（套房、雅房、通鋪等），如現場遇特殊情況，將由領隊視情況協調分配。若有特殊需求請事先提出。</li>
                                <li>須持有合格的潛水證照（國際潛水證照初級以上資格），若要參加特殊環境潛水（如：夜潛），需具備相關資格；部分活動可能會有額外條件要求。</li>
                                <li>活動行程以Line活動群組公布的資訊為準。潛點選擇由潛導視當時天候、海況、團隊狀況決定。進行潛水活動時採潛伴制進行，請彼此相互照應。</li>
                                <li>禁帶漁獵器材，亦禁止攜帶任何生物上岸，違者不得再參加後續潛水活動。</li>
                                <li>填寫＂Google表單＂報名者，視同同意以上所有條款。</li>
                            </ol>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 協辦旅行社 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【協辦旅行社】</h3>
                <div>iDiving 為了讓旅遊活動有保障，所有旅遊行程特別與旅行社偕同舉辦，讓你玩得開心又有保障。</div>
                <br />
                <table border="1" cellpadding="0" cellspacing="0">
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">主辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">愛潛水股份有限公司</td>
                        <td>承辦人：愛潛水</td>
                        <td>行動電話：0909-097696</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2882-7696</td>
                        <td>FAX：02-2882-7656</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAILl：info&#64;idiving.com.tw</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市士林區前港街8號</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">協辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">萬陽旅行社有限公司</td>
                        <td>業務請洽：蘇恒璀</td>
                        <td>行動電話：0937-196518</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2517-1333</td>
                        <td>FAX：02-2508-1537</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAIL：tracy410&#64;ms45.hinet.net</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市南京東路3段91號四樓之三</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>