import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cfd130',
  templateUrl: './cfd130.component.html',
  styleUrls: ['./cfd130.component.scss']
})
export class Cfd130Component implements OnInit {

  active = 1;

  //美人魚 平日 假日
  /*Weekdays = [
    {img: '../../../../assets/Image/Data/FD_MM_01.png'},
    {img: '../../../../assets/Image/Data/FD_MM_03.png'},
    {img: '../../../../assets/Image/Data/FD_MM_02.png'},
    {img: '../../../../assets/Image/Data/FD_MM_04.png'}
  ];*/

  constructor() { }

  ngOnInit(): void {
  }

}
