<div class="container">

  <div class="row">
    <div class="col">
      <nav class="navbar navbar-expand-lg navbar-light idiving-header-color fixed-top">
        <div class="container-fluid">

          <a class="navbar-brand text-dark" href="#">
            <img style="z-index:3" src="../../../../assets/Image/Logo.png" />
            <!-- <h1 class="text-hide" style="background-image: url('../../../../assets/Image/Logo.png'); width: 250px; height: 90px;">MDBootstrap</h1> -->
            <!-- <span>全方位潛水中心</span> -->
          </a>

          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDarkDropdown" aria-controls="navbarNavDarkDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarNavDarkDropdown">
            <ul class="navbar-nav ms-auto">

              <!-- <li class="nav-item dropdown has-megamenu flex-row-reverse">
                <a class="nav-link dropdown-toggle fs-5 text-dark" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  學潛水
                </a>
                <ul class="dropdown-menu megamenu" role="menu" aria-labelledby="navbarDarkDropdownMenuLink">
                  <div class="row">
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold">自由潛水</h6>
                      <li><a class="dropdown-item fs-6" href="/#/cfd110"> Freediver </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/cfd210"> Advanced Freediver </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/cfd310"> Performance Freediver </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/cfd131"> 美人魚體驗 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/cfd130"> 美人魚課程 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/cfd120"> FD 初階專長 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/cfd220"> FD 進階專長 </a></li>
                      <li><a class="dropdown-item fs-6" href=""> <br> </a></li>
                    </div>
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold">水肺潛水</h6>
                      <li><a class="dropdown-item fs-6" href="/#/c120"> OWD潛水課程 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/c210"> 進階探險潛水員 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/c310"> 各項專長潛水員 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/c312"> 環保專長潛水員 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/c313"> 高階專長潛水員 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/c101"> 體驗潛水/水肺複習 </a></li>
                      <li><a class="dropdown-item fs-6" href=""> <br> </a></li>
                    </div>
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold">技術潛水</h6>
                      <li><a class="dropdown-item" href="/#/coc100"> 延伸領域潛水課程 </a></li>
                      <li><a class="dropdown-item" href="/#/ccr100"> 全密閉循環潛水課程 </a></li>
                      <li><a class="dropdown-item" href=""> <br> </a></li>
                    </div>
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold">潛水教練課程</h6>
                      <li><a class="dropdown-item fs-6" href="/#/c410"> 水肺教練培訓 </a></li> -->
                      <!-- <li><a class="dropdown-item fs-6" href="/#/c420"> 水肺專業教練課程 </a></li> -->
                      <!-- <li><a class="dropdown-item fs-6" href="/#/c430"> 水肺高階教練課程 </a></li> -->
                      <!-- <li><a class="dropdown-item fs-6" href="/#/cfdc210"> 自潛教練培訓 </a></li>
                      <li><a class="dropdown-item fs-6" href=""> <br> </a></li>
                    </div>
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold">課程相關</h6>
                      <li><a class="dropdown-item fs-6" href="/#/cz01"> 線上報名 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/bz01"> 課程年度計畫 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/cp01"> 課程價格表 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/qac001"> 課程Q&A </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/c610"> 獎勵認證 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/health001"> 健康聲明 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/dz05"> 自評表 </a></li>
                      <li><a class="dropdown-item fs-6" href=""> <br> </a></li>
                    </div>
                  </div>
                </ul>
              </li> -->

              <li class="nav-item dropdown has-megamenu flex-row-reverse">
                <a class="nav-link dropdown-toggle fs-5 text-dark" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  美人魚
                </a>
                <ul class="dropdown-menu megamenu" role="menu" aria-labelledby="navbarDarkDropdownMenuLink">
                  <div class="row">
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold"></h6>
                      <li><a class="dropdown-item fs-6" href="/#/cfd131"> 美人魚體驗 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/cfd130"> 美人魚課程 </a></li>
                      <li><a class="dropdown-item fs-6" href=""> <br> </a></li>
                    </div>
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold"> </h6>
                      <li><a class="dropdown-item fs-6" href="">  </a></li>
                      <li><a class="dropdown-item fs-6" href=""></a></li>
                    </div>
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold"> </h6>
                      <li><a class="dropdown-item fs-6" href=""></a></li>
                    </div>
                  </div>
                </ul>
              </li>

              <li class="nav-item dropdown has-megamenu">
                <a class="nav-link dropdown-toggle fs-5 text-dark" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  自由潛水
                </a>
                <ul class="dropdown-menu megamenu" role="menu" aria-labelledby="navbarDarkDropdownMenuLink">
                  <div class="row">
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold"></h6>
                      <li><a class="dropdown-item fs-6" href="/#/cfd110"> 自由潛水員 Freediver (初階課程) </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/cfd210"> 進階自由潛水員 Advanced Freediver </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/cfd310"> 卓越自由潛水員 Performance Freediver </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/cfd120"> FD 初階專長 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/cfd220"> FD 進階專長 </a></li>
                      <li><a class="dropdown-item fs-6" href=""> <br> </a></li>
                    </div>
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold"> </h6>
                      <li><a class="dropdown-item fs-6" href="/#/cfdc210"> 自潛教練培訓 </a></li>
                      <li><a class="dropdown-item fs-6" href=""></a></li>
                    </div>
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold"> </h6>
                      <li><a class="dropdown-item fs-6" href=""></a></li>
                    </div>
                  </div>
                </ul>
              </li>

              <li class="nav-item dropdown has-megamenu">
                <a class="nav-link dropdown-toggle fs-5 text-dark" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  水肺潛水
                </a>
                <ul class="dropdown-menu megamenu" role="menu" aria-labelledby="navbarDarkDropdownMenuLink">
                  <div class="row">
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold"></h6>
                      <li><a class="dropdown-item fs-6" href="/#/c120"> OWD潛水課程 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/c210"> 進階探險潛水員 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/c310"> 各項專長潛水員 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/c312"> 環保專長潛水員 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/c313"> 高階專長潛水員 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/c101"> 體驗潛水/水肺複習 </a></li>
                      <li><a class="dropdown-item fs-6" href=""> <br> </a></li>
                    </div>
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold"> </h6>
                      <li><a class="dropdown-item fs-6" href="/#/c410"> 水肺教練培訓 </a></li>
                      <li><a class="dropdown-item fs-6" href=""></a></li>
                    </div>
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold"> </h6>
                      <li><a class="dropdown-item fs-6" href=""></a></li>
                    </div>
                  </div>
                </ul>
              </li>

              <li class="nav-item dropdown has-megamenu">
                <a class="nav-link dropdown-toggle fs-5 text-dark" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  技術潛水
                </a>
                <ul class="dropdown-menu megamenu" role="menu" aria-labelledby="navbarDarkDropdownMenuLink">
                  <div class="row">
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold"></h6>
                      <li><a class="dropdown-item fs-6" href="/#/coc100"> 延伸領域潛水課程 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/ccr100"> 全密閉循環潛水課程 </a></li>
                      <li><a class="dropdown-item fs-6" href=""> <br> </a></li>
                    </div>
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold"> </h6>
                      <li><a class="dropdown-item fs-6" href=""></a></li>
                    </div>
                  </div>
                </ul>
              </li>

              <!-- <li class="nav-item dropdown has-megamenu">
                <a class="nav-link dropdown-toggle fs-5 text-dark" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  潛水教練課程
                </a>
                <ul class="dropdown-menu megamenu" role="menu" aria-labelledby="navbarDarkDropdownMenuLink">
                  <div class="row">
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold"></h6>
                      <li><a class="dropdown-item fs-6" href="/#/c410"> 水肺教練培訓 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/cfdc210"> 自潛教練培訓 </a></li>
                      <li><a class="dropdown-item fs-6" href=""> <br> </a></li>
                    </div>
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold"> </h6>
                      <li><a class="dropdown-item fs-6" href=""></a></li>
                    </div>
                  </div>
                </ul>
              </li> -->

              <li class="nav-item dropdown has-megamenu">
                <a class="nav-link dropdown-toggle fs-5 text-dark" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  課程報名專區
                </a>
                <ul class="dropdown-menu megamenu" role="menu" aria-labelledby="navbarDarkDropdownMenuLink">
                  <div class="row">
                    <div class="col">
                      <!-- <h6 class="sub-title text-uppercase font-weight-bold">台灣潛點</h6> -->
                      <li><a class="dropdown-item fs-6" href="/#/cz01"> 線上報名 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/bz01"> 課程年度計畫 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/cp01"> 課程價格表 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/qac001"> 課程Q&A </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/c610"> 獎勵認證 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/health001"> 健康聲明 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/dz05"> 自評表 </a></li>
                      <li><a class="dropdown-item fs-6" href=""> <br> </a></li>
                    </div>
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold"> </h6>
                      <li><a class="dropdown-item fs-6" href=""></a></li>
                    </div>
                  </div>
                </ul>
              </li>

              <li class="nav-item dropdown has-megamenu">
                <a class="nav-link dropdown-toggle fs-5 text-dark" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  玩潛水
                </a>
                <ul class="dropdown-menu megamenu" role="menu" aria-labelledby="navbarDarkDropdownMenuLink">
                  <div class="row">
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold">台灣潛點</h6>
                      <li><a class="dropdown-item fs-6" href="/#/d120"> 墾丁 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/d130a"> 小琉球 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/d140"> 綠島 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/d150"> 蘭嶼 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/d160"> 澎湖 </a></li>
                      <li><a class="dropdown-item fs-6" href=""> <br> </a></li>
                    </div>
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold">國外潛點</h6>
                      <li><a class="dropdown-item fs-6" href="/#/d230"> 杜馬蓋地 </a></li>
                      <!-- <li><a class="dropdown-item fs-6" href="#"> 圖巴塔哈 </a></li> -->
                      <li><a class="dropdown-item fs-6" href="/#/d310"> 峇里島 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/d420"> 馬爾地夫 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/d410"> 帛琉 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/d340"> 西巴丹 </a></li>
                      <li><a class="dropdown-item fs-6" href=""> <br> </a></li>
                    </div>
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold">活動相關</h6>
                      <li><a class="dropdown-item fs-6" href="/#/dz04"> 自由潛水活動報名 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/dz02"> 東北角水肺活動報名表 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/dz03"> 國內外水肺活動報名表 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/b400"> 俱樂部 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/dz01"> 旅遊年度計畫 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/b240"> 活動照片 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/qaa001"> 活動Q&A </a></li>
                      <li><a class="dropdown-item fs-6" href=""> <br> </a></li>
                    </div>
                  </div>
                </ul>
              </li>

              <li class="nav-item dropdown has-megamenu">
                <a class="nav-link dropdown-toggle fs-5 text-dark" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  愛潛水
                </a>
                <ul class="dropdown-menu megamenu" role="menu" aria-labelledby="navbarDarkDropdownMenuLink">
                  <div class="row">
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold">裝備</h6>
                      <li><a class="dropdown-item fs-6" href="/#/b310"> 輕裝 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/b320"> 重裝 </a></li>
                      <!-- <li><a class="dropdown-item fs-6" href="/#/b330"> 套裝 </a></li> -->
                      <li><a class="dropdown-item fs-6" href="https://reurl.cc/1Yovx8"> 線上商品型錄 </a></li>
                      <li><a class="dropdown-item fs-6" href=""> <br> </a></li>
                    </div>
                    <!-- <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold">冬季活動</h6>
                      <li><a class="dropdown-item fs-6" href="#"> 滑雪 </a></li>
                      <li><a class="dropdown-item fs-6" href=""> <br> </a></li>
                    </div> -->
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold">環保</h6>
                      <li><a class="dropdown-item fs-6" href="/#/b411"> 環保里程碑 </a></li>
                      <li><a class="dropdown-item fs-6" href=""> <br> </a></li>
                    </div>
                    <div class="col">
                      <h6 class="sub-title text-uppercase font-weight-bold" style="white-space:nowrap">關於iDiving</h6>
                      <li><a class="dropdown-item fs-6" href="/#/m100"> 最新消息 </a></li>
                      <!--<li><a class="dropdown-item fs-6" href="/#/b210"> 介紹 </a></li>-->
                      <li><a class="dropdown-item fs-6" href="/#/b211"> 前港店介紹 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/b212"> 龍洞店介紹 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/b230"> 教練團 </a></li>
                      <li><a class="dropdown-item fs-6" href="/#/about001"> 關於我們 </a></li>
                      <li><a class="dropdown-item fs-6" href=""> <br> </a></li>
                    </div>
                  </div>
                </ul>
              </li>

            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>

</div>