<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">學潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">青少年</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>課程一覽</a>
                    <ng-template ngbNavContent class="custom-class">
                        <br>
                        <div>
                            <p class="fs-6">SSI也有小朋友可以學的潛水課程啦!</p>
                            <p class="fs-6">依據年齡可安排的證照課程</p>
                            <br />
                            <p class="fs-6">兒童探索潛水課程🔅</p>
                            <ul class="fs-6">
                                <li>
                                    Scuba Diver 水肺潛水員。
                                </li>
                                <li>
                                    Freediver 自由潛水員。
                                </li>
                                <li>
                                    Mermaid 人魚。
                                </li>
                            </ul>
                            <p>青少年潛水課程🔅</p>
                            <ul class="fs-6">
                                <li>
                                    Open Water Diver 開放水域潛水員。
                                </li>
                                <li>
                                    Freediver 自由潛水員。
                                </li>
                                <li>
                                    Mermaid 人魚。
                                </li>
                            </ul>
                            <br />
                            <p class="fs-6">
                                SSI希望可以透過課程讓不管是大朋友還是小朋友都可以在探索潛水世界時，安全且歡樂自在放鬆的。
                                我們也在今年暑假期間開了2個梯次的夏令營活動，讓爸爸媽媽可以趁暑假期間讓小孩們體驗看看潛水的樂趣。
                                🤿🌊🐟🐡🦀🐠🦑☀️
                            </p>
                            <br />
                            <img src="../../../../assets/Image/Data/teenager_01.jpg" width="100%" />
                            <img src="../../../../assets/Image/Data/teenager_02.jpg" width="100%" />
                            <img src="../../../../assets/Image/Data/teenager_03.jpg" width="100%" />
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink>6-11歲</a>
                    <ng-template ngbNavContent>
                        <ul ngbNav #nav="ngbNav" [(activeId)]="active1" class="nav-tabs">
                            <li [ngbNavItem]="1">
                                <a ngbNavLink>水肺</a>
                                <ng-template ngbNavContent class="custom-class">
                                    <br />
                                    <h3 style="font-family:DFKai-sb;">水肺探險家（8-9歲，10歲以上者，請往12-15歲水肺頁簽）</h3>
                                    <br />
                                    <div>
                                        <p>
                                            Scuba Explorer水肺探險家課程是一個專為小朋友設計的潛水體驗課程，
                                            讓孩子們在專業教練的指導下，開啟他們的水下世界之旅。這門課程結合了學習與娛樂，讓孩子們在安全的環境中，
                                            體驗水肺潛水的樂趣，並培養他們對海洋和水下生物的興趣。學會基本的安全知識，讓安全與樂趣並存。
                                        </p>
                                    </div>
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>【課程規劃】</h3>
                                        <br />
                                        <img src="../../../../assets/Image/Data/childexp_01.jpg" width="100%" />
                                        <br />
                                        <p class="fs-6" style="color:red;">
                                            <br>註:實際下水時間將依照現場情況調整
                                        </p>
                                    </div>
                                    <div>
                                        <br />
                                        <h3 style="font-family:DFKai-sb;"><br>【課程特色】</h3>
                                        <ul>
                                            <li>專業指導：由經驗豐富的潛水教練全程陪伴，確保每一位孩子的安全與學習效果。</li>
                                            <li>簡單有趣的學習：課程內容通俗易懂，適合8至9歲的兒童，讓他們在輕鬆愉快的氛圍中學習水肺潛水的基本技能。</li>
                                            <li>水下世界探險：孩子們將親自體驗在淺水區進行簡單的水肺潛水，與海洋生物零距離接觸，探索水下世界的奧秘。</li>
                                            <li>增進自信心與合作精神：在團隊合作和教練的支持下，孩子們將學會如何掌握基本潛水技能，增強自信心與獨立性。</li>
                                            <li>強調安全：高品質教學人數比 1:2，教練全程陪伴課程設計注重兒童的身心發展，並且將安全放在首位，讓家長放心，孩子開心。</li>
                                        </ul>
                                        <br />
                                        <img src="../../../../assets/Image/Data/childexp_02.png" width="100%" />
                                        <br />
                                    </div>
                                    <div>
                                        <br />
                                        <h3 style="font-family:DFKai-sb;"><br>2025年</h3>
                                        <br />
                                        <img src="../../../../assets/Image/Data/childexp_03.png" width="100%" />
                                        <br />
                                        <img src="../../../../assets/Image/Data/childexp_04.png" width="100%" />
                                        <br />
                                    </div>
                                    <div>
                                        <app-paymentmethod></app-paymentmethod>
                                    </div>
                                    <!-- 上課地點 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">上課地點</h3>
                                        <hr>
                                        <Scrolltop />
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                                        <p>教室學科:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>iDiving士林前港店</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>泳池術科:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>各配合泳池（需先到iDiving集合）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>海洋實習: </p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>夏季東北角（iDiving 東北角龍洞店）冬季潛立方（台中市西屯區安和西路 69 號）或 各移地結訓地</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>註: </p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>
                                                            ＊如遇天候不佳或泳池場地整修等，上課地點與時間可能視狀況調整，若有額外增加的場地費用，本中心將自行吸收（大台北區轉換場地之費用，台中泳池費用），不會另外收費。
                                                            墾丁離島移地訓練產生之費用需自付。
                                                            <br />
                                                            ＊學員不可因課程之"地點/時間"有調整而拒學並要求退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 特約課程 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">特約課程</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學科部份，每另外約一日課程加價 300 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>術科部份，每另外約一日課程加價 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>指定教練費: 指定教練，加價 500 元/日，教練如果已安排既定課程或是出團，恕無法指定。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 更改辦法 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">更改辦法</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>課程須於開始上課後的兩個月內完成。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期之更改須在 10 天前告知</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 取消辦法 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">取消辦法</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>
                                                            若因身體因素而不適合從事潛水活動，並開立醫生證明者，繳交之費用退費方式如下: NT 3,000
                                                            元訂金用做購買線上教材，無法退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>
                                                            學員自行放棄課程，本訓練中心將不予退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <br>

                                    </div>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2">
                                <a ngbNavLink>自潛/人魚</a>
                                <ng-template ngbNavContent>
                                    <br />
                                    <h3 style="font-family:DFKai-sb;">自潛/人魚</h3>
                                    <br />
                                    <div>
                                        <p>
                                            Freediving / Mermaid Explorer自潛/人魚探險家課程是一個專為小朋友設計的潛水體驗課程，讓孩子們在專業教練的指導下，開啟他們的水下之旅。這門課程結合了學習與娛樂，
                                            讓孩子們在安全的環境中，體驗潛水的樂趣，並培養他們對海洋和水下生物的興趣。學會基本的安全知識，讓安全與樂趣並存。
                                        </p>
                                    </div>
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>【課程規劃】</h3>
                                        <br />
                                        <img src="../../../../assets/Image/Data/childexpm_01.jpg" width="100%" />
                                        <br />
                                        <p class="fs-6" style="color:red;">
                                            <br>註:實際下水時間將依照現場情況調整
                                        </p>
                                    </div>
                                    <div>
                                        <br />
                                        <h3 style="font-family:DFKai-sb;"><br>【課程特色】</h3>
                                        <ul>
                                            <li>專業指導：由經驗豐富的潛水教練全程陪伴，確保每一位孩子的安全與學習效果。</li>
                                            <li>簡單有趣的學習：課程內容通俗易懂，適合 6至11歲的兒童，讓他們在輕鬆愉快的氛圍中學習自潛或人魚潛水的基本技能。</li>
                                            <li>水下世界探險：孩子們將親自體驗在淺水區進行簡單的水肺潛水，與海洋生物零距離接觸，探索水下世界的奧秘。</li>
                                            <li>增進自信心與合作精神：在團隊合作和教練的支持下，孩子們將學會如何掌握基本潛水技能，增強自信心與獨立性。</li>
                                            <li>強調安全：高品質教學人數比 1:2，教練全程陪伴課程設計注重兒童的身心發展，並且將安全放在首位，讓家長放心，孩子開心。</li>
                                        </ul>
                                        <br />
                                        <img src="../../../../assets/Image/Data/childexpm_02.png" width="100%" />
                                        <br />
                                    </div>
                                    <div>
                                        <br />
                                        <h3 style="font-family:DFKai-sb;"><br>2025年</h3>
                                        <br />
                                        <img src="../../../../assets/Image/Data/childexpm_03.png" width="100%" />
                                        <br />
                                        <img src="../../../../assets/Image/Data/childexpm_04.png" width="100%" />
                                        <br />
                                    </div>
                                    <div>
                                        <app-paymentmethod></app-paymentmethod>
                                    </div>
                                    <!-- 上課地點 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">上課地點</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                                        <p>教室學科:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>iDiving士林前港店</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>泳池術科:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>各配合泳池（需先到iDiving集合）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>海洋實習: </p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>夏季東北角（iDiving 東北角龍洞店）冬季潛立方（台中市西屯區安和西路 69 號）或 各移地結訓地</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>註: </p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>
                                                            ＊如遇天候不佳或泳池場地整修等，上課地點與時間可能視狀況調整，若有額外增加的場地費用，本中心將自行吸收（大台北區轉換場地之費用，台中泳池費用），不會另外收費。
                                                            墾丁離島移地訓練產生之費用需自付。
                                                            <br>＊學員不可因課程之"地點/時間"有調整而拒學並要求退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 特約課程 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">特約課程</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學科部份，每另外約一日課程加價 300 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>術科部份，每另外約一日課程加價 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>指定教練費: 指定教練，加價 500 元/日，教練如果已安排既定課程或是出團，恕無法指定。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 更改辦法 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">更改辦法</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>課程須於開始上課後的兩個月內完成。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期之更改須在 10 天前告知</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 取消辦法 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">取消辦法</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>
                                                            若因身體因素而不適合從事潛水活動，並開立醫生證明者，繳交之費用退費方式如下: NT 3,000
                                                            元訂金用做購買線上教材，無法退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>
                                                            學員自行放棄課程，本訓練中心將不予退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <br>

                                    </div>
                                </ng-template>
                            </li>
                        </ul>

                        <div [ngbNavOutlet]="nav" class="mt-2"></div>

                        <!-- <pre>Active: {{ active }}</pre> -->

                    </ng-template>
                </li>
                <li [ngbNavItem]="3">
                    <a ngbNavLink>12-15歲</a>
                    <ng-template ngbNavContent>
                        <ul ngbNav #nav="ngbNav" [(activeId)]="active2" class="nav-tabs">
                            <li [ngbNavItem]="1">
                                <a ngbNavLink>水肺</a>
                                <ng-template ngbNavContent class="custom-class">
                                    <br />
                                    <h3 style="font-family:DFKai-sb;">Open Water Diver 開放水域潛水員</h3>
                                    <br />
                                    <div>
                                        <p>
                                            是否曾經夢想過，能在海底自由自在地遨遊？現在就有機會讓你實現！讓孩子們在專業教練的指導下，開啟他們的水下世界之旅。體驗不一樣的冒險，
                                            並學習如何在水下安全探索這片神秘的藍色世界。這門課程結合了學習與娛樂，讓孩子們在安全的環境中，感受水肺潛水的樂趣，並培養他們對海洋和水下生物的興趣。
                                            學會基本的安全知識，讓安全與樂趣並存。
                                        </p>
                                    </div>
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>【課程規劃】</h3>
                                        <br />
                                        <img src="../../../../assets/Image/Data/childj_01.png" width="100%" />
                                        <br />
                                        <p class="fs-6" style="color:red;">
                                            <br>註:實際下水時間將依照現場情況調整
                                        </p>
                                    </div>
                                    <div>
                                        <br />
                                        <h3 style="font-family:DFKai-sb;"><br>【課程特色】</h3>
                                        <ul>
                                            <li>專業指導：由經驗豐富的潛水教練全程陪伴，確保每一位孩子的安全與學習效果。</li>
                                            <li>適合年齡： 10-15歲，專為青少年設計的課程，輕鬆學習，快樂成長。</li>
                                            <li>全面裝備： 提供水肺設備，讓您專注於水下探險，而無需擔心裝備問題。</li>
                                            <li>實際操作： 進行海上及水下實操訓練，讓您快速掌握水肺技能。</li>
                                            <li>友善學習環境： 小班制教學，注重個別指導，讓每位學員都能輕鬆上手。</li>
                                        </ul>
                                        <br />
                                        <img src="../../../../assets/Image/Data/childj_02.png" width="100%" />
                                        <br />
                                    </div>
                                    <div>
                                        <br />
                                        <h3 style="font-family:DFKai-sb;"><br>2025年</h3>
                                        <br />
                                        <img src="../../../../assets/Image/Data/childj_03.png" width="100%" />
                                        <br />
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td colspan="3" class="fs-6">
                                                        <p style="color: green;"><br>定價 $22,800 元（含贈品）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" class="fs-6">
                                                        <ul>
                                                            <li>費用包含：輕裝組贈品(面鏡+蛙鞋+呼吸管+套鞋+手套+蛙鞋)、SSI線上教材、國際數位證照申請、教練費、
                                                                訓練期間潛水重裝使用（防寒衣+浮力調整裝置、調節器、電腦錶）、水域遊憩責任保險、10餐、海洋實習之交通、3晚住宿費。</li>
                                                            <li>費用不含：浮潛裝備租借450元/天。</li>
                                                            <li>自備輕裝 ( 不要贈品 ) 可折扣 3000 元，但自備之裝備需經教練確認安全無虞，才可在課程中使用！</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br />
                                        <h3 style="font-family:DFKai-sb;color: red;">優惠方案</h3>
                                        <br />
                                        <ul>
                                            <li>早鳥優惠 <a style="color: red;">＄1500 元</a> <a style="color: rgb(6, 128, 243);">*(5/31前完成報名，填寫表單+繳交定金,不得與其他優惠併用)</a></li>
                                            <li>多人優惠 <a style="color: red;">＄700 元</a>。</li>
                                            <li>現金優惠，課程尾款付現金 <a style="color: red;">＄300 元</a>。</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <app-paymentmethod></app-paymentmethod>
                                    </div>
                                    <!-- 上課地點 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">上課地點</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                                        <p>教室學科:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>iDiving士林前港店</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>泳池術科:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>各配合泳池（需先到iDiving集合）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>海洋實習: </p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>夏季東北角（iDiving 東北角龍洞店）冬季潛立方（台中市西屯區安和西路 69 號）或 各移地結訓地</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>註: </p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>
                                                            ＊如遇天候不佳或泳池場地整修等，上課地點與時間可能視狀況調整，若有額外增加的場地費用，本中心將自行吸收（大台北區轉換場地之費用，台中泳池費用），不會另外收費。
                                                            墾丁離島移地訓練產生之費用需自付。
                                                            <br>＊學員不可因課程之"地點/時間"有調整而拒學並要求退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 特約課程 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">特約課程</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學科部份，每另外約一日課程加價 300 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>術科部份，每另外約一日課程加價 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>指定教練費: 指定教練，加價 500 元/日，教練如果已安排既定課程或是出團，恕無法指定。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 更改辦法 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">更改辦法</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>課程須於開始上課後的兩個月內完成。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期之更改須在 10 天前告知</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 取消辦法 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">取消辦法</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>
                                                            若因身體因素而不適合從事潛水活動，並開立醫生證明者，繳交之費用退費方式如下: NT 3,000
                                                            元訂金用做購買線上教材，無法退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>
                                                            學員自行放棄課程，本訓練中心將不予退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <br>

                                    </div>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2">
                                <a ngbNavLink>自潛</a>
                                <ng-template ngbNavContent>
                                    <br>
                                    <h3 style="font-family:DFKai-sb;">Freediver自由潛水員</h3>
                                    <br />
                                    <div>
                                        <p>
                                            想過在海中與魚群共舞，感受海裡的寧靜嗎？青少年自潛課程為你提供一個無重力般的自由潛水讓孩子們在專業教練的指導下，開啟他們的水下世界之旅。
                                            體驗不一樣的冒險，並學習如何在水下安全探索這片神秘的藍色世界。這門課程結合了學習與娛樂，讓孩子們在安全的環境中，感受潛水的樂趣，
                                            並培養他們對海洋和水下生物的興趣。學會基本的安全知識，讓安全與樂趣並存。
                                        </p>
                                    </div>
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>【課程規劃】</h3>
                                        <br />
                                        <img src="../../../../assets/Image/Data/childjf_01.png" width="100%" />
                                        <br />
                                        <p class="fs-6" style="color:red;">
                                            <br>註:實際下水時間將依照現場情況調整
                                        </p>
                                    </div>
                                    <div>
                                        <br />
                                        <h3 style="font-family:DFKai-sb;"><br>【課程特色】</h3>
                                        <ul>
                                            <li>專業指導：由認證自潛教練帶領，保障學員的安全與學習效果。</li>
                                            <li>適合年齡：12-15歲，專為青少年設計的課程，輕鬆學習，快樂成長。</li>
                                            <li>全面裝備：提供自潛設備，讓您專注於水下探險，而無需擔心裝備問題。</li>
                                            <li>實際操作：進行海上及水下實操訓練，讓您快速掌握潛水技能。</li>
                                            <li>友善學習環境：小班制教學，注重個別指導，讓每位學員都能輕鬆上手。</li>
                                        </ul>
                                        <br />
                                        <img src="../../../../assets/Image/Data/childjf_02.png" width="100%" />
                                        <br />
                                    </div>
                                    <div>
                                        <br />
                                        <h3 style="font-family:DFKai-sb;"><br>2025年</h3>
                                        <br />
                                        <img src="../../../../assets/Image/Data/childjf_03.png" width="100%" />
                                        <br />
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td colspan="3" class="fs-6">
                                                        <p style="color: green;"><br>定價 $18,800 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" class="fs-6">
                                                        <ul>
                                                            <li>費用包含：SSI線上教材、國際數位證照申請、教練費、泳池場地費、東北角海洋實習及保險、訓練期間之裝備、水域遊憩責任保險、
                                                                10餐、海洋實習之交通、3晚住宿費。</li>
                                                            <li>費用不含：個人消費。</li>
                                                            <li>課程輕裝優惠，面鏡、呼吸管、配重帶原價2,580元，配合課程只需<a style="color: red;">加價1,400元</a>。</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br />
                                        <img src="../../../../assets/Image/Data/childjf_04.png" width="100%" />
                                        <br />
                                        <h3 style="font-family:DFKai-sb;color: red;">優惠方案</h3>
                                        <br />
                                        <ul>
                                            <li>早鳥優惠 <a style="color: red;">＄1500 元</a> <a style="color: rgb(6, 128, 243);">*(5/31前完成報名，填寫表單+繳交定金,不得與其他優惠併用)</a></li>
                                            <li>多人優惠 <a style="color: red;">＄700 元</a>。</li>
                                            <li>現金優惠，課程尾款付現金 <a style="color: red;">＄300 元</a>。</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <app-paymentmethod></app-paymentmethod>
                                    </div>
                                    <!-- 上課地點 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">上課地點</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                                        <p>教室學科:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>iDiving士林前港店</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>泳池術科:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>各配合泳池（需先到iDiving集合）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>海洋實習: </p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>夏季東北角（iDiving 東北角龍洞店）冬季潛立方（台中市西屯區安和西路 69 號）或 各移地結訓地</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>註: </p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>
                                                            ＊如遇天候不佳或泳池場地整修等，上課地點與時間可能視狀況調整，若有額外增加的場地費用，本中心將自行吸收（大台北區轉換場地之費用，台中泳池費用），不會另外收費。
                                                            墾丁離島移地訓練產生之費用需自付。
                                                            <br>＊學員不可因課程之"地點/時間"有調整而拒學並要求退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 特約課程 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">特約課程</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學科部份，每另外約一日課程加價 300 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>術科部份，每另外約一日課程加價 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>指定教練費: 指定教練，加價 500 元/日，教練如果已安排既定課程或是出團，恕無法指定。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 更改辦法 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">更改辦法</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>課程須於開始上課後的兩個月內完成。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期之更改須在 10 天前告知</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 取消辦法 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">取消辦法</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>
                                                            若因身體因素而不適合從事潛水活動，並開立醫生證明者，繳交之費用退費方式如下: NT 3,000
                                                            元訂金用做購買線上教材，無法退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>
                                                            學員自行放棄課程，本訓練中心將不予退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <br>

                                    </div>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="3">
                                <a ngbNavLink>人魚</a>
                                <ng-template ngbNavContent>
                                    <br>
                                    <h3 style="font-family:DFKai-sb;">人魚</h3>
                                    <br />
                                    <div>
                                        <p>
                                            曾經幻想自己擁有魚尾，在海洋中自由自在地游泳。現在人魚不再是夢想，一起踏上奇妙的水下冒險旅程，學習成為一名真正的人魚。
                                            體驗不一樣的冒險，並學習如何在水下安全探索這片神秘的藍色世界。這門課程結合了學習與娛樂，讓孩子們在安全的環境中，感受潛水的樂趣，
                                            並培養他們對海洋和水下生物的興趣。學會基本的安全知識，讓安全與樂趣並存。
                                        </p>
                                    </div>
                                    <div>
                                        <h3 style="font-family:DFKai-sb;"><br>【課程規劃】</h3>
                                        <br />
                                        <img src="../../../../assets/Image/Data/childjfm_01.png" width="100%" />
                                        <br />
                                        <p class="fs-6" style="color:red;">
                                            <br>註:實際下水時間將依照現場情況調整
                                        </p>
                                    </div>
                                    <div>
                                        <br />
                                        <h3 style="font-family:DFKai-sb;"><br>【課程特色】</h3>
                                        <ul>
                                            <li>專業指導：由認證人魚教練帶領，保障學員的安全與學習效果。</li>
                                            <li>適合年齡：12-15歲，專為青少年設計的課程，輕鬆學習，快樂成長。</li>
                                            <li>全面裝備：提供設備，讓您專注於水下探險，而無需擔心裝備問題。</li>
                                            <li>實際操作：進行海上及水下實操訓練，讓您快速掌握潛水技能。</li>
                                            <li>友善學習環境：小班制教學，注重個別指導，讓每位學員都能輕鬆上手。</li>
                                        </ul>
                                        <br />
                                        <img src="../../../../assets/Image/Data/childjfm_02.png" width="100%" />
                                        <br />
                                    </div>
                                    <div>
                                        <br />
                                        <h3 style="font-family:DFKai-sb;"><br>2025年</h3>
                                        <br />
                                        <img src="../../../../assets/Image/Data/childjfm_03.png" width="100%" />
                                        <br />
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td colspan="3" class="fs-6">
                                                        <p style="color: green;"><br>定價 $18,800 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" class="fs-6">
                                                        <ul>
                                                            <li>費用包含：SSI線上教材、國際數位證照申請、教練費、泳池場地費、東北角海洋實習及保險、訓練期間之裝備、水域遊憩責任保險、10餐、海洋實習之交通、3晚住宿費。</li>
                                                            <li>費用不含:個人消費。</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br />
                                        <h3 style="font-family:DFKai-sb;color: red;">優惠方案</h3>
                                        <br />
                                        <ul>
                                            <li>早鳥優惠 <a style="color: red;">＄1500 元</a> <a style="color: rgb(6, 128, 243);">*(5/31前完成報名，填寫表單+繳交定金,不得與其他優惠併用)</a></li>
                                            <li>多人優惠 <a style="color: red;">＄700 元</a>。</li>
                                            <li>現金優惠，課程尾款付現金 <a style="color: red;">＄300 元</a>。</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <app-paymentmethod></app-paymentmethod>
                                    </div>
                                    <!-- 上課地點 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">上課地點</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" colspan="2">
                                                        <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                                        <p>教室學科:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>iDiving士林前港店</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>泳池術科:</p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>各配合泳池（需先到iDiving集合）</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>海洋實習: </p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>夏季東北角（iDiving 東北角龍洞店）冬季潛立方（台中市西屯區安和西路 69 號）或 各移地結訓地</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>註: </p>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>
                                                            ＊如遇天候不佳或泳池場地整修等，上課地點與時間可能視狀況調整，若有額外增加的場地費用，本中心將自行吸收（大台北區轉換場地之費用，台中泳池費用），不會另外收費。
                                                            墾丁離島移地訓練產生之費用需自付。
                                                            <br>＊學員不可因課程之"地點/時間"有調整而拒學並要求退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 特約課程 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">特約課程</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學科部份，每另外約一日課程加價 300 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <p>術科部份，每另外約一日課程加價 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>指定教練費: 指定教練，加價 500 元/日，教練如果已安排既定課程或是出團，恕無法指定。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 更改辦法 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">更改辦法</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>課程須於開始上課後的兩個月內完成。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期之更改須在 10 天前告知</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- 取消辦法 -->
                                    <div>
                                        <br>
                                        <h3 style="font-family:DFKai-sb;">取消辦法</h3>
                                        <hr>
                                        <table borderless="true">
                                            <tbody>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>
                                                            若因身體因素而不適合從事潛水活動，並開立醫生證明者，繳交之費用退費方式如下: NT 3,000
                                                            元訂金用做購買線上教材，無法退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fs-6" style="vertical-align:text-top;">
                                                        <ul>
                                                            <li></li>
                                                        </ul>
                                                    </td>
                                                    <td class="fs-6">
                                                        <p>
                                                            學員自行放棄課程，本訓練中心將不予退費。
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <br>

                                    </div>
                                </ng-template>
                            </li>
                        </ul>

                        <div [ngbNavOutlet]="nav" class="mt-2"></div>

                        <!-- <pre>Active: {{ active }}</pre> -->

                    </ng-template>
                </li>
            </ul>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>

            <!-- <pre>Active: {{ active }}</pre> -->

        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>