import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cz01',
  templateUrl: './cz01.component.html',
  styleUrls: ['./cz01.component.scss']
})
export class Cz01Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
