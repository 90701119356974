import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-d310',
  templateUrl: './d310.component.html',
  styleUrls: ['./d310.component.scss']
})
export class D310Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
