import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-b400',
  templateUrl: './b400.component.html',
  styleUrls: ['./b400.component.scss']
})
export class B400Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
