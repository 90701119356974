<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                    <br>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">愛潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">最新消息</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                    <br>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <h3>最新消息</h3>
            <hr>
            <div class="container" *ngFor="let el of hearsaytotals | async; let i = index">
                <div class="row">
                    <div>
                        <br>
                        <p class="d-inline p-2 {{el.color}} text-white" style="border-radius:10px;padding-bottom: .5em;">{{el.item}}</p>
                    </div>
                </div>
                <div class="row">
                    <div>
                        <br>
                        <p class="fs-6">{{el.data}}</p>
                        <p class="fs-4">{{el.title}}</p>
                    </div>
                </div>
                <div class="row">
                    <div>
                        <br>
                        <img src="{{el.img}}" style="display:block;margin:5px 10px 15px 20px" class="img-thumbnail" />
                    </div>
                </div>
                <div class="row">
                    <div>
                        <br>
                        <p class="fs-6">{{el.detail}}</p>
                        <br>
                    </div>
                </div>
            </div>
            <br>
            <!-- <div class="container">
                <h3>最新消息</h3>
                <hr>
                <table>
                    <tbody>
                        <tr *ngFor="let el of hearsaytotals | async; let i = index">
                            <td>
                                <div>
                                    <div>
                                        <br>
                                        <p class="d-inline p-2 {{el.color}} text-white" style="border-radius:10px;padding-bottom: .5em;">{{el.item}}</p>
                                    </div>
                                    <div>
                                        <br>
                                        <p class="fs-6">{{el.data}}</p>
                                        <p class="fs-4">{{el.title}}</p>
                                    </div>
                                    <div>
                                        <img src="{{el.img}}" style="display:block;margin:5px 10px 15px 20px" class="img-thumbnail" align="right" alt="" />
                                    </div>
                                    <div>
                                        <br>
                                        <p class="fs-6">{{el.detail}}</p>
                                        <br>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> -->
            <br>
            <br>
            <br>
            <!--  <div class="container">
                <table> mdbTable borderless="true" #tableEl="mdbTable">
                    <tbody>
                        <tr *ngFor="let el of hearsaytotals | async; let i = index"> -->
                            <!-- <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" style="padding:2px;" width="50%">
                                <img src="{{el.img}}" style="display:block;margin:5px 10px 15px 20px" class="img-thumbnail" align="right" alt="" />
                            </td>
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                                <div>
                                    <div>
                                        <br>
                                        <a class="d-inline p-2 {{el.color}} text-white" style="border-radius:10px;padding-bottom: .5em;">{{el.item}}</a>
                                        <a class="fs-6">&nbsp;&nbsp; {{el.data}}</a>
                                        <a class="fs-4"><br> {{el.title}}</a>
                                    </div>
                                    <div>
                                        <img src="{{el.img}}" style="display:block;margin:5px 10px 15px 20px" class="img-thumbnail" align="right" alt="" />
                                    </div>
                                    <div>
                                        <br>
                                        <p class="fs-6">{{el.detail}}</p>
                                        <br>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot class="grey lighten-5 w-100">
                        <tr>
                            <td colspan="4">
                                <mdb-table-pagination [tableEl]="tableEl" [searchDataSource]="hearsaytotals" class="fs-6">
                                </mdb-table-pagination>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div> -->
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                    <br>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                    <br>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <h2 class="hwarsayblock">Facebook</h2>
            <br>
            <div style="text-align:center;">
                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FiDivingFans%2F&tabs=timeline&width=340&height=310&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1244040985675824" 
                width="340" height="640" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </div>
            </div>
        <div class="col-xl">
        </div>
    </div>

    <!-- <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                    <br>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <h2 class="hwarsayblock">Instagram</h2>
            <br>
            <div style="text-align: center;">
                <iframe width="320" height="440" src="http://instagram.com/p/idiving_taiwan/embed" frameborder="0"></iframe>
            </div>
        </div>
        <div class="col-xl">
        </div>
    </div> -->

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                    <br>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>