<div class="container-fluid">

  <app-head></app-head>

  <div class="row">
    <table>
      <tr>
        <td style="height: 12vh;">
        </td>
      </tr>
      <tr>
        <td>
        </td>
      </tr>
    </table>
  </div>

  <!-- <app-carousel></app-carousel> -->

  <div class="row">
    <div class="col-xl">
      <img src="../../../assets/Image/Data/homepageimage.jpg" class="img-fluid" alt="..." />
    </div>
  </div>

  <div class="row">
    <table>
      <tr>
        <td>
        </td>
      </tr>
      <tr>
        <td>
          <br>
        </td>
      </tr>
    </table>
  </div>

  <app-link></app-link>

  <div class="row">
    <table>
      <tr>
        <td>
        </td>
      </tr>
      <tr>
        <td>
          <br>
        </td>
      </tr>
    </table>
  </div>

  <app-hearsay></app-hearsay>

  <div class="row">
    <table>
      <tr>
        <td>
        </td>
      </tr>
      <tr>
        <td>
          <br>
        </td>
      </tr>
    </table>
  </div>

  <!-- 潛店介紹 -->
  <div class="row">
    <div class="col">
      <br>
      <div class="DMClass">
        <div>
          <br>
          <br>
          <br>
          <h2 style="text-align: center;color: white;">都市店與海邊店一次滿足</h2>
          <br>
          <p class="fs-4" style="text-align: center;color: white;">iDiving 前港店</p>
          <p class="fs-6" style="text-align: center;color: white;">都市店就近完成教室課、泳池課</p>
          <p class="fs-6" style="text-align: center;color: white;">讓工作繁忙的你能妥善利用空閒時間</p>
          <p class="fs-6" style="text-align: center;color: white;">課程問題、購買裝備不用大老遠找教練</p>
          <br>
          <p class="fs-4" style="text-align: center;color: white;">iDiving 龍洞店</p>
          <p class="fs-6" style="text-align: center;color: white;">一天來回東北角，不用休假就可以潛水</p>
          <p class="fs-6" style="text-align: center;color: white;">想要享受慢活步調，兩天一夜也可以</p>
          <br>
          <br>
          <img src="../../../assets/Image/Data/LocationMap.png" class="DMClass02" width="50%" alt="..." />
          <div class="DMClass03">
            <br>
            <a type="button" class="btn btn01 bg-outline-dark text-white fs-4" href="/#/b211">前港店</a>
            &nbsp;
            <a type="button" class="btn btn01 bg-outline-dark text-white fs-4" href="/#/b212">龍洞店</a>
            &nbsp;
            <br>
            <br>
            <br>
          </div>
        </div>
      </div>
      <br>
      <br>
      <br>
      <br>
      <img src="../../../../assets/Image/Data/begin2.png" width="99%">
      <br>
      <br>
      <br>
      <br>
      <p class="fs-4" style="text-align: center;">80人水肺自潛教練團</p>
      <p class="fs-6" style="text-align: center;">上課、活動教練人數高比例</p>
      <p class="fs-6" style="text-align: center;">客製上課時間好安排</p>
      <p class="fs-6" style="text-align: center;">駐店 SSI 教練訓練官從初級到教練培訓都包含</p>
      <p class="fs-6" style="text-align: center;">每年冬季教練資格更新復訓一項都沒少</p>
      <br>
      <img src="../../../../assets/Image/Data/begin3.jpg" width="99%">
      <br>
    </div>
  </div>

  <div class="row">
    <table>
      <tr>
        <td>
        </td>
      </tr>
      <tr>
        <td>
          <br>
        </td>
      </tr>
    </table>
  </div>
  
  <app-smallactivity></app-smallactivity>

  <div class="row">
    <table>
      <tr>
        <td>
        </td>
      </tr>
      <tr>
        <td>
          <br>
          <br>
        </td>
      </tr>
    </table>
  </div>

  <!-- <app-youtube></app-youtube>

  <h4 style="font-weight:bold;text-align: center;">Youtube頻道</h4>

  <div class="row">
    <div class="col-xl"></div>
    <div class="col-xl-10">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/K_z2K7zmihU" frameborder="0"style="text-align:center" allowfullscreen></iframe>
    </div>
    <div class="col-xl"></div>
  </div> -->

  <div class="row">
    <table>
      <tr>
        <td>
        </td>
      </tr>
      <tr>
        <td>
          <br>
          <br>
        </td>
      </tr>
    </table>
  </div>

  <!-- <app-photoalbum></app-photoalbum> -->
  <app-fewalbums></app-fewalbums>

  <div class="row">
    <table>
      <tr>
        <td>
        </td>
      </tr>
      <tr>
        <td>
          <br>
          <br>
        </td>
      </tr>
    </table>
  </div>

  <app-dealer></app-dealer>

  <div class="row">
    <table>
      <tr>
        <td>
        </td>
      </tr>
      <tr>
        <td>
          <br>
          <br>
        </td>
      </tr>
    </table>
  </div>

  <app-foot></app-foot>

</div>