import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cfd220',
  templateUrl: './cfd220.component.html',
  styleUrls: ['./cfd220.component.scss']
})
export class Cfd220Component implements OnInit {

  active = 1;

  constructor() { }

  ngOnInit(): void {
  }

}
