<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">學潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">水舞</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <button class="fs-6" ngbNavLink>初階水舞</button>
                    <ng-template ngbNavContent class="custom-class">
                        <br />
                        <h3 style="white-space:nowrap; overflow:hidden; text-overflow:ellipsis">初階水舞 Basic Underwater Dance</h3>
                        <hr />
                        <a>
                            水下舞蹈是一種在水中進行的表演藝術，結合了舞蹈、戲劇和游泳技術。舞者在水下<br />
                            進行流暢的動作，以表達情感和故事。讓潛水生涯開啟全新的挑戰，學習在水下展現<br />
                            優雅姿態及流暢動作的同時，學會基本的安全知識，讓安全與樂趣並存。
                        </a>
                        <br />
                        <br />
                        <img src="../../../assets/Image/Data/FD_ud1.png" width="90%" style="display: block; margin: auto" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                        <br />
                        <!-- 初階水舞 課程內容 -->
                        <div>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;">【課程內容】</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="1%"> <!-- style="vertical-align:text-top;"> -->
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                學科（3hr）<br />
                                                水舞相關知識、陸上伸展及形體練習
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="2%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                泳池訓練（8hr）<br />
                                                17個水舞技巧(中性浮力&負浮力技巧、下潛&安全部分、水舞律動組合)<br />
                                                水中舞蹈編排
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <img src="../../../../assets/Image/Data/FD_ud2.png" width="99%" style="display: block; margin: auto" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                        </div>
                        <!-- 初階水舞 課程特色 -->
                        <div>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;"><br>【課程特色】</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="1%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                補訓、團練搭配教練進行。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                高品質教學人數比 1:2，教練全程陪伴。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                強化水下姿態。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                學員上課期間購買潛水器材享有學員優惠價。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 初階水舞 課程規劃 -->
                        <div>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;"><br>【課程規劃】</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="2%">
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                DAY 1 | 學科 3hr<br>
                                            </p>
                                            <hr>
                                            <p>
                                                下舞蹈介紹、水下舞蹈潛水知識、水下舞蹈裝備、陸上伸展及形體練習
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 1 & 2 | 泳池 8hr<br>
                                            </p>
                                            <hr>
                                            <p>
                                                中性直立懸浮、中性懸浮上肢雙臂律動、複合空轉、中性平行蹬跳後展<br />
                                                後翻團身直立懸浮、魚雷划手下潛、直立失重下潛、縱身倒（俯向）<br />
                                                縱身倒（側向）、無手行走、舞姿手轉體、站姿舞蹈銜接（基礎）<br />
                                                蹬跳複合空轉、落體側臥、緊急摘除配重無蹼上升、無蹼抽筋解除<br />
                                                基礎水舞律動組合、水中舞蹈編排
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <p class="fs-6" style="color:red;">
                                                <br>註:<br>
                                                1. 無海洋課程<br>
                                                2. 實際泳池課程時段依據預約而定
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <img src="../../../../assets/Image/Data/FD_ud3.png" width="99%" style="display:block; margin:auto;" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                        </div>
                        <!-- 初階水舞 課程學費 -->
                        <!-- 方案一  -->
                        <div class="row">
                            <div class="col">
                                <br>
                                <br>
                                <h3 style="font-family:DFKai-sb;">【課程學費】</h3>
                                <table borderless="true">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" class="fs-6">
                                                <br />
                                                <h5>2025年 水舞 （採預約制）</h5>
                                                <a style="color: green;">
                                                    <br />
                                                    初階水舞 Underwater Dance : $14,000 元<br />
                                                    先決條件：
                                                </a>
                                                <ol style="color: green;">
                                                    <li>
                                                        需持有 <br />
                                                        SSI Mermaid 人魚 // SSI Basic Freediver基礎自潛員或以上 (或等同自由潛水效力之證照等級)
                                                    </li>
                                                    <li>徒手25m游泳能力</li>
                                                    <li>踩水40秒</li>
                                                </ol>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="fs-6">
                                                <ul>
                                                    <li style="color: red;">現金優惠：折扣300元。</li>
                                                    <li style="color: red;">人數優惠:兩人一起報名更優惠，每人優惠 700。</li>
                                                    <li>費用包含:國際數位證照申請、教練費、泳池場地費。</li>
                                                    <li>費用不含:裝備。</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br>
                            </div>
                        </div>
                        <!-- 初階水舞 報名方式 -->
                        <div>
                            <app-paymentmethod></app-paymentmethod>
                        </div>
                        <!-- 初階水舞 上課地點 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">【上課地點】</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="80px" style="vertical-align:text-top;">
                                            <p>教室學科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>iDiving 士林前港店。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="80px" style="vertical-align:text-top;">
                                            <p>泳池術科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>各配合泳池。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" width="4%" style="vertical-align:text-top;">
                                            <p>註:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                1.如遇天候不佳或泳池場地整修等，上課地點與時間可能視狀況調整，若有額外增加的場地
                                                費用，本中心將自行吸收 （大台北區轉換場地之費用，台中泳池費用），不會另外收費。
                                                墾丁離島移地訓練產生之費 用需自付。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            <p> </p>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                2.學員不可因課程之"地點/時間"有調整而拒學並要求退費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 初階水舞 特約課程 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">【特約課程】</h3>
                            <hr>
                            <a>學科部份，每另外約一日課程加價 300 元 </a>
                            <br />
                            <a>術科部份，每另外約一日課程加價 500 元</a>
                            <br />
                            <a>三人同行，一起約定特殊時間上課，不須額外加價。 </a>
                            <br />
                            <a>指定教練費: 指定教練，加價 500 元/日，教練如果已安排既定課程或是出團，恕無法指定。 </a>
                        </div>
                        <!-- 初階水舞 更改辦法 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">【更改辦法】</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>課程須於開始上課後的兩個月內完成。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期之更改須在 10 天前告知</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 初階水舞 取消辦法 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">【取消辦法】</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                若因身體因素而不適合從事潛水活動，並開立醫生證明者，繳交之費用退費方式如下: NT 3,000
                                                元訂金用做購買線上教材，無法退費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink>進階水舞</a>
                    <ng-template ngbNavContent>
                        <br />
                        <h3 style="white-space:nowrap; overflow:hidden; text-overflow:ellipsis">進階水舞 Advance Underwater Dance</h3>
                        <hr />
                        <br />
                        <br />
                        <img src="../../../assets/Image/Data/FD_ud1.png" width="90%" style="display: block; margin: auto" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                        <br />
                        <!-- 進階水舞 課程內容 -->
                        <div>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;">【課程內容】</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="1%"> <!-- style="vertical-align:text-top;"> -->
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                學科（3hr）<br />
                                                水舞相關知識、陸上伸展及形體練習
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="2%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                泳池訓練（12hr）<br />
                                                17個水舞技巧(中性浮力&負浮力技巧、下潛&安全部分、水舞律動組合)<br />
                                                水中舞蹈編排
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <img src="../../../../assets/Image/Data/FD_ud3.png" width="99%" style="display: block; margin: auto" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                        </div>
                        <!-- 進階水舞 課程特色 -->
                        <div>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;"><br>【課程特色】</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="1%" style="vertical-align:text-top;">
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                補訓、團練搭配教練進行。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                高品質教學人數比 1:2，教練全程陪伴。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                強化水下姿態。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li class="fs-6"></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                學員上課期間購買潛水器材享有學員優惠價。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table></div>
                        <!-- 進階水舞 課程規劃 -->
                        <div>
                            <table borderless="true">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <h3 style="font-family:DFKai-sb;"><br>【課程規劃】</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="2%">
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                DAY 1 | 學科 3hr<br>
                                            </p>
                                            <hr>
                                            <p>
                                                律動組合、水舞編舞、空間利用、服飾選擇與材質、陸上伸展及形體練習
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 1 & 2 & 3 | 泳池 12hr<br>
                                            </p>
                                            <hr>
                                            <p>
                                                中性懸浮下肢律動、中性懸浮完美律動、中性懸浮頭胸腰律動、中性懸浮划手轉體<br />
                                                縱身倒(仰) 、划手行進、跑躍、躺姿舞蹈、坐姿舞蹈、懸浮三點撐、跪站體式貫穿、<br />
                                                站姿舞蹈銜接(進階)、蹬跳後翻、團身直立(進階)、倒立團身腳向後翻、進階水舞律動組合
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <p class="fs-6" style="color:red;">
                                                <br />註:<br />
                                                1. 無海洋課程<br />
                                                2. 實際泳池課程時段依據預約而定
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <img src="../../../../assets/Image/Data/FD_ud2.png" width="99%" style="display:block; margin:auto;" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
                        </div>
                        <!-- 進階水舞 課程學費 -->
                        <!-- 方案一  -->
                        <div class="row">
                            <div class="col">
                                <br>
                                <br>
                                <h3 style="font-family:DFKai-sb;">【課程學費】</h3>
                                <table borderless="true">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" class="fs-6">
                                                <br />
                                                <h5>2025年 水舞 （採預約制）</h5>
                                                <a style="color: green;">
                                                    進階水舞Advanced Underwater Dance : $23,800 元<br />
                                                    先決條件：需持有 <br />
                                                    SSI Basic Underwater Dance 初階水舞
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="fs-6">
                                                <ul>
                                                    <li style="color: red;">現金優惠：折扣300元。</li>
                                                    <li style="color: red;">人數優惠:兩人一起報名更優惠，每人優惠 700。</li>
                                                    <li>費用包含:國際數位證照申請、教練費、泳池場地費。</li>
                                                    <li>費用不含:裝備。</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br>
                            </div>
                        </div>
                        <!-- 進階水舞 報名方式 -->
                        <div>
                            <app-paymentmethod></app-paymentmethod>
                        </div>
                        <!-- 進階水舞 上課地點 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">【上課地點】</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="80px" style="vertical-align:text-top;">
                                            <p>教室學科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>iDiving 士林前港店。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="80px" style="vertical-align:text-top;">
                                            <p>泳池術科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>各配合泳池。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" width="4%" style="vertical-align:text-top;">
                                            <p>註:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                1.如遇天候不佳或泳池場地整修等，上課地點與時間可能視狀況調整，若有額外增加的場地
                                                費用，本中心將自行吸收 （大台北區轉換場地之費用，台中泳池費用），不會另外收費。
                                                墾丁離島移地訓練產生之費 用需自付。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            <p> </p>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                2.學員不可因課程之"地點/時間"有調整而拒學並要求退費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 進階水舞 特約課程 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">【特約課程】</h3>
                            <hr>
                            <a>學科部份，每另外約一日課程加價 300 元 </a>
                            <br />
                            <a>術科部份，每另外約一日課程加價 500 元</a>
                            <br />
                            <a>三人同行，一起約定特殊時間上課，不須額外加價。 </a>
                            <br />
                            <a>指定教練費: 指定教練，加價 500 元/日，教練如果已安排既定課程或是出團，恕無法指定。 </a>
                        </div>
                        <!-- 進階水舞 更改辦法 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">【更改辦法】</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>課程須於開始上課後的兩個月內完成。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期之更改須在 10 天前告知</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 進階水舞 取消辦法 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">【取消辦法】</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                若因身體因素而不適合從事潛水活動，並開立醫生證明者，繳交之費用退費方式如下: NT 3,000
                                                元訂金用做購買線上教材，無法退費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-template>
                </li>
            </ul>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>

            <!-- <pre>Active: {{ active }}</pre> -->
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td></td>
            </tr>
            <tr>
                <td></td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>
</div>