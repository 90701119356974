import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ccr100',
  templateUrl: './ccr100.component.html',
  styleUrls: ['./ccr100.component.scss']
})
export class Ccr100Component implements OnInit {

  active = 2;
  
  constructor() { }

  ngOnInit(): void {
  }

}
