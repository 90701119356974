import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c313',
  templateUrl: './c313.component.html',
  styleUrls: ['./c313.component.scss']
})
export class C313Component implements OnInit {

  active = 1;

  constructor() { }

  ngOnInit(): void {
  }

}
