<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">愛潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">環保里程碑</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <!-- https://mdbootstrap.com/docs/angular/layout/grid-usage/ -->
    <!-- https://mdbootstrap.com/docs/angular/layout/grid-examples/ -->
    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <div>
                <h4>環保里程碑</h4>
                <a href="/#/c310">
                    <img src="../../../assets/Image/Data/milestone.png" usemap="#imagemap" />
                </a>
            </div>
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>