<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">愛潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">iDiving 龍洞店</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <br>
        <img src="../../../assets/Image/Data/Store02p01.jpg" width="90%"><br>
        <br>
        <br>
        <div class="col-xl">
            <!-- 龍洞店 介紹 -->
            <div style="text-align:center;">
                <br>
                <br>
                <h3 style="font-family:DFKai-sb;text-align:center;">iDiving 龍洞店</h3>
                <hr>
                <p class="fs-6">
                    位於新北市貢寮區的龍洞店，從台北出發只需要車程<br>
                    45~60分鐘，龍洞沿岸有多處的天然屏障地形，<br>
                    形成安全穩定的海域，是東北角沿海潛水聖地！<br><br>
                    龍洞店一樓設有獨立盥洗間、裝備清洗池、置物區<br>
                    相機桌、電池充電站、用餐區、電視等<br><br>
                    二樓提供瑜珈墊、懶骨頭、桌遊、Switch、<br>
                    飛鏢等交誼區，提供潛水員們舒適的環境，<br>
                    享受潛水完悠閒的時光<br>
                </p>
            </div>
            <!-- 龍洞店 設施服務 -->
            <div style="text-align:center;">
                <hr>
                <h3 style="font-family:DFKai-sb;text-align:center;">設施服務</h3>
                <br>
                <img src="../../../assets/Image/Data/Store02p02.jpg" width="70%">
                <br>
                <p class="fs-6">
                    <br>獨立裝備籃、置物區提供擺放裝備與盥洗衣物<br>
                </p>
                <br>
                <img src="../../../assets/Image/Data/Store02p03.jpg" width="70%">
                <br>
                <p class="fs-6">
                    <br>一樓設有五間淋浴間、兩間廁所、六個裝備清洗池<br>
                </p>
                <br>
                <img src="../../../assets/Image/Data/Store02p04.jpg" width="70%">
                <br>
                <p class="fs-6">
                    <br>二樓遊戲、交誼區，還有海景戶外陽台<br>
                </p>
                <br>
                <img src="../../../assets/Image/Data/Store02p05.jpg" width="70%">
                <br>
                <p class="fs-6">
                    <br>獨特手繪東北角明星生物與潛水員壁畫<br>
                </p>
            </div>
            <!-- 龍洞店 交通指南 -->
            <div style="text-align:center;">
                <hr>
                <h3 style="font-family:DFKai-sb;text-align:center;">交通指南</h3>
                <br>
                <img src="../../../assets/Image/Data/Store02p06.jpg" width="70%">
                <p class="fs-6">
                    *停車資訊：龍洞灣海洋公園大型停車場、濱海公路兩側路邊停車<br>
                    *搭乘大眾運輸工具，乘車資訊：<br>
                    *基隆火車站<br>
                    -基隆客運791(國家新城-福隆)→龍洞灣公園站下車<br>
                    *瑞芳火車站<br>
                    -基隆客運台灣好行黃金福隆線856(瑞芳火車站-福隆遊客中心)<br>
                    →龍洞灣公園站下車<br>
                    <a href="https://www.taiwantrip.com.tw/line/32?x=1&y=4" target="_blank">https://www.taiwantrip.com.tw/line/32?x=1&y=4</a><br>
                    -基隆客運886(瑞芳-和美國小)→龍洞灣公園站下車<br>
                    *台北火車站<br>
                    -國光客運1811(台北火車站-羅東) 或 <br>
                    1812(台北火車站-南方澳)→龍洞公園站下車。<br>
                    <a href="http://www.kingbus.com.tw/ticketPriceResult.php?sid=116" target="_blank">http://www.kingbus.com.tw/ticketPriceResult.php?sid=116</a><br>
                    *平日搭車資訊<br>
                    *貢寮火車站<br>
                    -新巴士F837(貢寮火車站-鼻頭里停車場) 或<br>
                    F839(貢寮火車站-鼻頭角)→龍洞灣公園站下車。<br>
                    <a href="https://ebus.gov.taipei/EBus/VsSimpleMap?rid=16971&sec=0#" target="_blank">https://ebus.gov.taipei/EBus/VsSimpleMap?rid=16971&sec=0#</a><br>
                    <br>
                    電話：(02)2491-1639<br>
                    地址： 新北市貢寮區龍洞街66-1號<br>
                    （導航路徑請設定 iDiving龍洞，濱海公路/台2縣，<br>
                    到龍洞灣公園對面巷子進去就抵達）<br>              
                    龍洞店街景：<a href="https://reurl.cc/KrM5Zq" target="_blank">https://reurl.cc/KrM5Zq（新味小吃左側巷子進入）</a><br>
                    iDiving 龍洞店：<a href="https://reurl.cc/GbAOx3" target="_blank">https://reurl.cc/GbAOx3</a><br>
                    <br>
                    營業時間：<br>
                    4月-10月 星期六至星期日 08:00~17:00<br>
                    <br>
                </p>
            </div>
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>