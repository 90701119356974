<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">玩潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">峇里島</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <h3 style="font-family:DFKai-sb;">峇里島</h3>
            <hr>
            <br>
            <!-- 景點介紹 -->
            <div>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="font-family:DFKai-sb;">【景點介紹】</h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table cellpadding="0" cellspacing="0" width="70%" style="text-align: justify;">
                    <tr>
                        <td>
                            <strong>印尼</strong><br />
                            印度尼西亞共和國，簡稱印尼，為東南亞國家；約由17,508個島嶼組成，是世界上最大的群島國家，疆域橫跨亞洲及大洋洲，別稱「萬島之國」也是東南亞最大的國家。印度尼西亞人口超過2.58億，為世界上人口第四多的國家。五個最大的島嶼分別是蘇門答臘島(Sumatra)、爪哇島(Java)、加里曼丹島(Kalimantan台灣俗稱：婆羅洲Borneo)、蘇拉威西島(Sulawesi)與巴布亞島(Papua)。首都是在爪哇島上的雅加達(Jakarta)。
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td><img src="../../../assets/Image/Data/Bali_01.jpg" width="100%"></td>
                    <tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>氣候</strong><br />
                            印尼地處赤道周邊，屬於熱帶性氣候，一年四季都是夏天的氣溫，根據季風而分成乾、濕季，11月到3月雨季時，幾乎天天都是暴雨，濕度非常高，4月到10月乾季時，整天都是烈日當空，非常炎熱。<br /><br />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>人文</strong><br />
                            印尼有300多個民族及七百多種方言，南島語族後裔與爪哇族為最大族群，但七成的商業與財富都掌握在僅占3~4%的印尼華人手上，因此曾長期發生排華運動。官方語言為印尼語，約90%的人都篤信伊斯蘭教，其他像基督教、天主教、印度教、佛教則佔少數(峇里島較特別，以印度教為主，伊斯蘭教則為少數)。由於大量的穆斯林的關係，飲食方面很少機會吃到豬肉，大部分以雞肉、牛肉、海鮮為主。而隨處可見的清真寺，會在一天中固定五次的禱告膜拜時間發送禱告誦經，不管在屋內還是走路都能聽到這些禱告聲，初次來印尼的可能會不習慣在半夜4~5點日出前就被晨禮的誦經聲吵醒了。<br /><br />
                        </td>
                    </tr>
                </table>
                <table cellpadding="0" cellspacing="0" width="70%" style="text-align: justify;">
                    <tr>
                        <td colspan="5">
                            <strong>地點</strong><br />
                            Bali峇里島，是印尼34個行政區之一，也是著名的旅遊勝地。全島大部分為山地，有四座以上的錐形火山峰，最高峰阿貢火山最為有名，也是仍在活動期的活火山。與台灣沒有時差，從台灣飛過去的路程大約為5小時。11月至隔年的3月為雨季，吹西北季風，4月至10月為乾季，也是最適合來此旅遊的時間點。一般來峇里島潛水都是到東北區的土蘭奔(Tulamben)看看自由號沈船與微距或者是去東南邊的三個小島(Nusa
                            Penida, Cenningan island與Nusa Lembongan)看翻車魚(僅8~10月有機會)。<br /><br />
                        </td>
                    </tr>
                    <tr>
                        <td><img src="../../../assets/Image/Data/Bali_02.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Bali_03.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Bali_04.jpg" width="100%"></td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td colspan="5">Liberty Dive
                            Resort擁有32間的獨棟木屋、花園、三個泳池，為您提供最乾淨舒適的住宿空間，有冷氣、Wifi，讓所有住宿的貴賓能夠放鬆心情，盡情享受渡假的悠閒，著名的潛點幾乎都以輕鬆的岸潛方式就可到達，還有人幫忙組裝與扛裝備的優質服務，每一潛結束就可以到泳池邊喝冰茶、吃點水果、泡泡泳池的水，馬上就可以恢復體力繼續下一支潛水，讓每一支都成為享受。
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 著名潛點 -->
            <div>
                <br>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="font-family:DFKai-sb;">【著名潛點】</h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table cellpadding="0" cellspacing="0" width="70%" style="text-align: justify;">
                    <tr>
                        <td>USAT Liberty Shipwreck</td>
                    </tr>
                    <tr>
                        <td>自由號沈船離土蘭奔卵石海灘僅40米，沈船長度約120米，船體分佈從深度3米~30米，遍佈各式軟硬珊瑚，雖然沒有很完整的船體，但在一入水淺淺的5M就可以辨識船尾的方向舵，沿著船體往船頭的方向前進，可常見JACKFISH魚群、隆頭鸚哥、梭魚、大石斑、葉魚、鬼龍、金黃色條紋或斑點的石鱸，專門啃珊瑚的隆頭鶯歌群，在深度較大的海扇上也住著豆丁海馬，這是個微距廣角都能拍到佳作的潛點。
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>Coral Garden</td>
                    </tr>
                    <tr>
                        <td>位於自由號沈船與斷層之間的一片長長的淺礁區，就是珊瑚花園，她是一片具有豐富生物的沙礁混合區，十字鬼龍、五彩鰻、三棘高身鮋、笛鯛、龍占魚都可以在這兒發現。</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>Seraya Secrets</td>
                    </tr>
                    <tr>
                        <td>在土藍奔東南部的一個海灣，是一個非常著名的微距潛水地點，表面上看起來是個其貌不樣的地方，因為一下水就是整片的黑沙地，缺乏主要的珊瑚，但是各式蛙蛙魚、十字鬼龍、海鰻、獅子魚、海葵蝦、海馬、花枝、秘密客、各式的海蛞蝓…..就是一個微距的天堂。
                        </td>
                    </tr>
                </table>
                <br>
                <table cellpadding="0" cellspacing="0" width="70%" style="text-align: justify;">
                    <tr>
                        <td><img src="../../../assets/Image/Data/Bali_05.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Bali_06.jpg" width="100%"></td>
                    </tr>
                </table>
            </div>
            <!-- 建議行程 -->
            <div>
                <br>
                <table borderless="true">
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="font-family:DFKai-sb;">【建議行程】</h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="70%" style="text-align: justify;">
                    <tr>
                        <td>
                            第一天<br>
                            台北／峇里島~ TULAMBEN CI-771 0915/1420<br>
                            集合於桃園機場，飛行約五個多小時，抵達美麗的渡假島嶼～峇里島，抵達後專車接往潛水聖地－TULAMBEN辦理CHECK IN手續。
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            第二天 (4支岸潛)<br>
                            享用早餐後，於TULAMBAN沉船或附近潛點潛水，潛水的空檔回到渡假村可以泡在泳池裡享受著冰涼的飲料與水果，午餐與晚餐皆在飯店享用，餐後可自由活動。
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            第三天 (3支岸潛+1支夜潛)<br>
                            享用早餐後，於TULAMBAN沉船或附近潛點潛水，潛水的空檔回到渡假村可以泡在泳池裡享受著冰涼的飲料與水果，午餐與晚餐皆在飯店享用，餐後可自由活動。
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            第四天 (2支岸潛)<br>
                            享用早餐後，於TULAMBAN沉船或附近潛點潛水，潛水的空檔回到渡假村可以泡在泳池裡享受著冰涼的飲料與水果，午餐與晚餐皆在飯店享用，餐後可自由活動。
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            第五天 峇里島／台北 CI-772 1530/2040<br>
                            享用早餐後，在依依不捨的告別聲中結束此難忘的巴里島潛水之旅，搭乘接駁專車前往機場搭機途中會在先在市區享用午餐，再返回溫暖的家。
                        </td>
                    </tr>
                </table>
                <br>
                <table cellpadding="0" cellspacing="0" width="70%" style="text-align: justify;">
                    <tr>
                        <td><img src="../../../assets/Image/Data/Bali_07.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Bali_08.jpg" width="100%"></td>
                    </tr>
                </table>

            </div>
            <!-- 注意事項 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【注意事項】</h3>
                <table>
                    <tr>
                        <td>
                            <ol class="fs-6">
                                <li>填寫＂Google 表單＂報名，並繳交團費<span class="Large Important"> NT$5,000
                                        的『定金』</span>才算完成報名流程，若以轉帳方式繳交定金，請將您的匯款日期及帳號末五碼與金額傳至iDiving官方Line
                                        &#64;帳號，以便確認。若以現金（or匯款）繳交全額費用，享有現金優惠！</li>
                                <li>國內潛水旅遊活動會有成團人數要求，含領隊需達10人才會出團；<span
                                        class="Large Important">若因報名人數不足而無法出團，最遲將於出團日14天以前通知已報名活動之團員，『定金』可全數退還。</span>
                                </li>
                                <li><span class="Large Important">出團日30天以前會於Line的活動群組提供行程資料，若有任何需求請提出討論。</span></li>
                                <li>當人數達到出團門檻，所有團員亦繳清費用時，則保證出團，若有人員臨時取消造成出團人數不足亦不受影響，而臨時退團人員的退費規則如下：<br />
                                    <span class="Large Important">A. 活動開始前第十五日以上取消者，須賠償團費的百分之十。</span><br />
                                    <span class="Large Important">B. 活動開始前第五日至第十四日取消者，須賠償團費的百分之三十。</span><br />
                                    <span class="Large Important">C. 活動開始前第二日至第四日取消者，須賠償團費的百分之五十。</span><br />
                                    <span class="Large Important">D. 於活動當日或前一日取消，或未通知不參加者，須賠償團費全額。</span><br />
                                    *若有溢繳或可退款的費用，可選擇來店退款或刷退（須視當時繳費方式），若要匯款轉帳退費須扣除手續費（每筆NT 30元），亦可選擇保留餘額轉至其他消費。
                                </li>
                                <li>若遇颱風、浪況不佳等不可抗拒之因素，基於安全考量，iDiving將保有取消活動之權利，已繳交的費用於扣除必要支出（如代訂大眾交通的退票手續費）後退還。</li>
                                <li>國內潛水旅遊皆為完整套裝行程，如有個人因素更動行程內容，恕不提供會員優惠。</li>
                                <li>住宿房間之房等差異（套房、雅房、通鋪等），如現場遇特殊情況，將由領隊視情況協調分配。若有特殊需求請事先提出。</li>
                                <li>須持有合格的潛水證照（國際潛水證照初級以上資格），若要參加特殊環境潛水（如：夜潛），需具備相關資格；部分活動可能會有額外條件要求。</li>
                                <li>活動行程以Line活動群組公布的資訊為準。潛點選擇由潛導視當時天候、海況、團隊狀況決定。進行潛水活動時採潛伴制進行，請彼此相互照應。</li>
                                <li>禁帶漁獵器材，亦禁止攜帶任何生物上岸，違者不得再參加後續潛水活動。</li>
                                <li>填寫＂Google表單＂報名者，視同同意以上所有條款。</li>
                            </ol>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 協辦旅行社 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【協辦旅行社】</h3>
                <div>iDiving 為了讓旅遊活動有保障，所有旅遊行程特別與旅行社偕同舉辦，讓你玩得開心又有保障。</div>
                <br />
                <table border="1" cellpadding="0" cellspacing="0" width="90%">
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">主辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">愛潛水股份有限公司</td>
                        <td>承辦人：愛潛水</td>
                        <td>行動電話：0909-097696</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2882-7696</td>
                        <td>FAX：02-2882-7656</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAILl：info&#64;idiving.com.tw</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市士林區前港街8號</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">協辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">萬陽旅行社有限公司</td>
                        <td>業務請洽：蘇恒璀</td>
                        <td>行動電話：0937-196518</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2517-1333</td>
                        <td>FAX：02-2508-1537</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAIL：tracy410&#64;ms45.hinet.net</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市南京東路3段91號四樓之三</td>
                    </tr>
                </table>
                <br>
            </div>
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>