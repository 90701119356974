import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-cfd120',
  templateUrl: './cfd120.component.html',
  styleUrls: ['./cfd120.component.scss']
})
export class Cfd120Component implements OnInit {

  active = 1;

  constructor() { }

  ngOnInit(): void {
  }

}
