<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                    <br>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">玩潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">活動 Q&A</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                    <br>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <div class="container">
                <h3>活動 Q&A</h3>
                <hr>
                <table>
                    <tbody>
                        <tr *ngFor="let qa of infos | async; let i = index">
                            <td>
                                <div>
                                    <div>
                                        <p class="fs-4"><br>Q: {{qa.q}}</p>
                                        <hr>
                                    </div>
                                    <div>
                                        <p class="fs-6">A: {{qa.a}}</p>
                                        <br>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br>
            <br>
            <br>
            <div class="container">
                <!-- <h2>活動 Q&A</h2> -->
                <hr>
                <!-- <table mdbTable borderless="true" #tableEl="mdbTable"> -->
                    <tbody>
                        <tr *ngFor="let qa of infos | async; let i = index">
                            <!-- <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" style="padding:2px;" width="50%">
                                <img src="{{el.img}}" style="display:block;margin:5px 10px 15px 20px" class="img-thumbnail" align="right" alt="" />
                            </td> -->
                            <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                                <div>
                                    <div>
                                        <a class="fs-4"><br>Q: {{qa.q}}</a>
                                    </div>
                                    <div>
                                        <br>
                                        <p class="fs-6">A: {{qa.a}}</p>
                                        <hr>
                                        <br>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <!-- <tfoot class="grey lighten-5 w-100">
                        <tr>
                            <td colspan="4">
                                <mdb-table-pagination [tableEl]="tableEl" [searchDataSource]="infos" class="fs-6">
                                </mdb-table-pagination>
                            </td>
                        </tr>
                    </tfoot>
                </table> -->
            </div>
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                    <br>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                    <br>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>