import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-d340',
  templateUrl: './d340.component.html',
  styleUrls: ['./d340.component.scss']
})
export class D340Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
