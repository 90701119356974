import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dz05',
  templateUrl: './dz05.component.html',
  styleUrls: ['./dz05.component.scss']
})
export class Dz05Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
