<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">學潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">活動照片</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row justify-content-around">
        <div class="card mb-4" *ngFor="let fewalbumsitem of fewalbumsitems | async " style="max-width: 400px;">
            <br>
            <div class="card-header">
                <h5 class="card-title fs-4 rgba-grey-strong">{{fewalbumsitem.name}}</h5>
            </div>
            <div class="bg-image hover-overlay">
                <a href={{fewalbumsitem.hrf}}>
                    <img src={{fewalbumsitem.img}} class="card-img-top" alt="...">
                </a>
            </div>
            <div class="card-body">
                <br>
                <a class="card-text fs-6" href={{fewalbumsitem.hrf}}>{{fewalbumsitem.title}}</a>
            </div>
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>