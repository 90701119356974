<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">學潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">高階專長潛水員</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a class="fs-4" ngbNavLink>推進器</a>
                    <ng-template ngbNavContent class="custom-class">
                        <br>
                        <h3>推進器 Scooter/ DPV Diving (DPV)</h3>
                        <hr>
                        <!-- 課程介紹 -->
                        <div>
                            <br>
                            <h3>課程介紹</h3>
                            <hr>
                            <table borderless="true">
                                <tr>
                                    <td>
                                        <p class="fs-6">
                                            每次花了許多時間才到目標潛點後沒完多久就要開始返程，推進器可以大幅減少往返時間與耗氣，增加玩樂時間，讓潛水旅程更加有趣。
                                        </p>
                                    </td>
                                </tr>
                            </table>
                            <br>
                            <img src="../../../assets/Image/Data/DPV_License.jpg" width="50%" style="display:block; margin:auto;"/>
                            <br>
                            <table>
                                <tr>
                                    <p class="fs-6">
                                        報名條件：<br />
                                        基本條件需年滿16歲、已擁有24次的潛水紀錄、已取得完美浮力專長或已可順利執行水平姿勢。
                                    </p>
                                </tr>
                            </table>
                        </div>
                        <!-- 課程規劃 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td width="10%">
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br> DAY 1 | 學科 09:00~11:00 開放水域 13:00~18:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                學科：推進器歷史、推進器組成、保養與運送、推進器操作原理與潛水計畫<br>
                                                術科：裝備調整、水面與水中技巧練習、故障排除、共生拖帶、裝備清潔與保養
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="10%">
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 2 | 開放水域 08:00~13:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                潛水計畫、緊急應變計畫、團隊控制。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <img src="../../../assets/Image/Data/Schedule_DPV.png" width="100%" style="display:block; margin:auto;" />
                            <br>
                        </div>
                        <!-- 開課日期 -->
                        <div>
                            <br>
                            <h3>開課日期</h3>
                            <hr>
                            <!-- <img src="../../../assets/Image/Data/DECO_Schedule.png" width="98%" /> -->
                        </div>
                        <!-- 超值學費 -->
                        <div>
                            <br>
                            <h3>超值學費</h3>
                            <hr>
                            <table width="100%" style="text-align: justify;">
                                <tr>
                                    <td class="fs-6" style="color: green;">定價：14,000元</td>
                                </tr>
                                <tr>
                                    <td class="fs-6" style="color: red;">現金優惠：折扣 300元</td>
                                </tr>
                            </table>
                            <br>
                            <table width="100%" style="text-align: justify;" border="0" cellpadding="0" cellspacing="0">
                                <tr>
                                    <td class="fs-6" style="vertical-align:text-top;" nowrap>費用包含：</td>
                                    <td class="fs-6">SSI數位教材、國際數位證照申請、教練費、氣瓶費、推進器使用、術科課程期間保險。</td>
                                </tr>
                                <tr>
                                    <td class="fs-6" style="vertical-align:text-top;">費用不含：</td>
                                    <td class="fs-6">輕裝租借、重裝租借（長短管調節器、背囊式BC、 電腦錶） 、移地海洋實習結訓之旅遊團費(交通食宿與出海船資)。
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="fs-6" style="vertical-align:text-top;">
                                        iDiving持證會員租用Seacraft Go推進器 2500半天(4hr)，4000 全天。若因個人因素未能順利完成課程，補課一天費用為4000元（含推進器）。
                                    </td>
                                </tr>
                            </table>
                            <br>
                        </div>
                        <!-- 報名方式 -->
                        <div>
                            <br>
                            <app-paymentmethod></app-paymentmethod>
                            <br>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a class="fs-4" ngbNavLink>減壓專長潛水員</a>
                    <ng-template ngbNavContent class="custom-class">
                        <br>
                        <h3>減壓專長潛水員 (DECO)</h3>
                        <!-- 課程內容 -->
                        <div>
                            <br>
                            <a style="color: red;">報名條件：基本條件需年滿16歲、已擁有24次的潛水紀錄、已取得深潛專長(DD)與高氧專長(EAN40)。</a>
                            <br>
                            <br>
                            <h3 style="font-family:DFKai-sb;">開課內容</h3>
                            <ul>
                                <li class="fs-6">
                                    學科（4hr）<br>
                                    潛水生理學、減壓設備、減壓理論
                                </li>
                                <li class="fs-6">
                                    海洋訓練（7hr）<br>
                                    裝備組裝、水平中性浮力、更換氣瓶
                                </li>
                                <li class="fs-6">
                                    海洋訓練（3次）<br>
                                    更換氣瓶、耗氣量計算、共用氣源練習、水層中停留、模擬減壓、減壓潛水
                                </li>
                            </ul>
                            <br>
                            <img src="../../../assets/Image/Data/DECO.jpg" width="50%" style="display:block; margin:auto;" />
                        </div>
                        <!-- 課程規劃 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td width="10%">
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br> DAY 1 | 學科 19:00-22:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                學科：潛水生理學、減壓設備、減壓理論。<br>
                                                術科：裝備調整。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="10%">
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 2 | 平靜水域 08：00-18：0<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                下水兩支 動作操作、減壓模擬，耗氣量計算，減壓計畫。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="10%">
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 3 | 開放水域07：00-14：00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                減壓潛水。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                        </div>
                        <!-- 開課日期 -->
                        <div>
                            <br>
                            <h3>開課日期</h3>
                            <hr>
                            <img src="../../../assets/Image/Data/Schedule_DECO.png" width="98%" />
                        </div>
                        <!-- 超值學費 -->
                        <div>
                            <br>
                            <h3>超值學費</h3>
                            <hr>
                            <table width="100%" style="text-align: justify;">
                                <tr>
                                    <td class="fs-6" style="color: green;">定價：15,000元</td>
                                </tr>
                                <tr>
                                    <td class="fs-6" style="color: red;">1.現金優惠：折扣 300元</td>
                                </tr>
                                <tr>
                                    <td class="fs-6" style="color: red;">2.多人同行優惠：</td>
                                </tr>
                                <tr>
                                    <td class="fs-6" style="color: red;">兩人同行，折扣 1,000 元。</td>
                                </tr>
                                <tr>
                                    <td class="fs-6" style="color: red;">三人同行，折扣 1,500 元。</td>
                                </tr>
                                <tr>
                                    <td class="fs-6" style="color: red;">四人同行，折扣 2,000 元。</td>
                                </tr>
                                <tr>
                                    <td class="fs-6" style="color: red;">折扣優惠：現金優惠可與多人優惠併用</td>
                                </tr>
                            </table>
                            <br>
                            <table width="100%" style="text-align: justify;" border="0" cellpadding="0" cellspacing="0">
                                <tr>
                                    <td class="fs-6" style="vertical-align:text-top;" nowrap>費用包含：</td>
                                    <td class="fs-6">重裝租借（含兩組調節器、氣瓶綁帶、與BC）、線上教材、國際數位證照申請、教練費、海洋實習氣瓶(背掛瓶與階段氣瓶)、保險費。</td>
                                </tr>
                                <tr>
                                    <td class="fs-6" style="vertical-align:text-top;">費用不含：</td>
                                    <td class="fs-6">
                                        輕裝租借、
                                        <samp style="color: red;">技術潛水電腦錶</samp>
                                        、移地海洋實習結訓之旅遊團費(交通食宿與出海船資)。
                                    </td>
                                </tr>
                            </table>
                            <br>
                        </div>
                        <!-- 報名方式 -->
                        <div>
                            <br>
                            <app-paymentmethod></app-paymentmethod>
                            <br>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3">
                    <a class="fs-4" ngbNavLink>側掛</a>
                    <ng-template ngbNavContent class="custom-class">
                        <br>
                        <h3>休閒側掛潛水員 (RSM)</h3>
                        <hr>
                        <!-- 課程內容 -->
                        <div>
                            <br>
                            <h3>開課內容</h3>
                            <ul>
                                <li class="fs-6">
                                    學科（6hr）<br>
                                    側掛的優缺點、側掛裝備的基本配置
                                </li>
                                <li class="fs-6">
                                    泳池（3hr）<br>
                                    裝備組裝、水平中性浮力、原地旋轉、氣瓶位置改變、共用氣源練習
                                </li>
                                <li class="fs-6">
                                    海洋訓練（3次）<br>
                                    裝備組裝、水平中性浮力、原地旋轉、氣瓶位置改變、共用氣源練習
                                </li>
                            </ul>
                            <br>
                            <img src="../../../../assets/Image/Data/SC_RSM_01.png" width="60%" style="display:block; margin:auto;">
                        </div>
                        <!-- 課程特色 -->
                        <div>
                            <h3><br>課程特色</h3>
                            <ul class="fs-6">
                                <li>
                                    教你如何使用側掛潛水裝備。
                                </li>
                                <li>
                                    海洋課程期間提供調節器、B.C.D.。
                                </li>
                                <li>
                                    平日 / 假日 均有開課，課程安排很方便。
                                </li>
                                <li>
                                    領導級人員對學員比例 1：2，教練時時刻刻都在您身旁。
                                </li>
                                <li>
                                    數位教材伴您一生，不必擔心知識忘記。
                                </li>
                                <li>
                                    學員上課期間，購買潛水器材享有學員優惠價。
                                </li>
                            </ul>
                            <br>
                            <img src="../../../../assets/Image/Data/SC_RSM_02.png" width="100%" style="display:block; margin:auto;">
                        </div>
                        <!-- 課程規劃 -->
                        <div>
                            <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td width="10%">
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                DAY 1 | 學科 19:00~22:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                側掛的由來、側掛的優勢、裝備介紹
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 2 | 裝備調整 19:00~22:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                調整側掛裝備至適當的狀態
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 3 | 平靜水域 13:00~17:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                裝備適應、中性浮力、氣瓶拆裝
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 4 | 開放水域 14:00~21:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                裝備組裝、水平中性浮力、原地旋轉、氣瓶位置改變、共用氣源練習
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <img class="d-block" src="../../../../assets/Image/Data/Schedule_RSM.png" width="100%" style="display:block; margin:auto;">
                        </div>
                        <!-- 課程學費 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">課程學費</h3>
                            <hr>
                            <ul class="fs-6">
                                <li style="color: green;">定價 12,000 元。</li>
                                <li>現金優惠，折扣 300 元。</li>
                                <li>費用包含：SSI數位教材、國際數位證照申請、教練費、訓練期間潛水重裝使用（浮力調整裝置、調節器、電腦錶）、旅遊平安保險。</li>
                                <li>費用不含：海洋實習交通與餐食費用、輕裝租借。</li>
                            </ul>
                            <br>
                        </div>
                        <!-- 報名方式 -->
                        <div>
                            <br>
                            <app-paymentmethod></app-paymentmethod>
                            <br>
                        </div>
                        <!-- 上課地點 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">上課地點</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                            <p>教室學科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>iDiving 士林前港店（台北市士林區前港街 8 號）</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>海洋實習:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>iDiving 東北角龍洞店（新北市貢寮區龍洞街 66-1 號）</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="color:green;vertical-align:text-top;">
                                            <p>註: </p>
                                        </td>
                                        <td class="fs-6" style="color:green;">
                                            <p>
                                                上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修等），若有額外增加的費用本中心將自行吸收，不會另外收費，但學員不可因課程時間調整而要求退費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 特約課程 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">特約課程</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>學科部份，每另外約一日課程加價 300 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>術科部份，每另外約一日課程加價 500 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>三人同行，一起約定特殊時間上課，不須額外加價。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 更改辦法 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">更改辦法</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>課程須於開始上課後的兩個月內完成。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期之更改須在 10 天前告知</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 取消辦法 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">取消辦法</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>若因身體因素不適合從事潛水活動，並開立醫生證明者，除了NT 3,000元購買數位教材的訂金，其餘將會退還。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4">
                    <a class="fs-4" ngbNavLink>乾衣</a>
                    <ng-template ngbNavContent class="custom-class">
                        <br>
                        <h3 style="font-family:DFKai-sb;">乾衣 (DRY)</h3>
                        <hr>
                        <!-- 課程內容 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">開課內容</h3>
                            <ul>
                                <li class="fs-6">
                                    學科（3hr）<br>
                                    乾式防寒衣與內裡的特色、材質，乾式防寒衣的配件、如何使用與保養
                                </li>
                                <li class="fs-6">
                                    泳池（3hr）<br>
                                    穿脫乾衣、乾衣充排閥練習、下潛與上升、中性浮力、倒立潛水員回復
                                </li>
                                <li class="fs-6">
                                    海洋訓練（3次）<br>
                                    穿脫乾衣、乾衣充排閥練習、下潛與上升、中性浮力、倒立潛水員回復
                                </li>
                            </ul>
                            <br>
                            <img src="../../../../assets/Image/Data/SC_DRY_01.png" width="60%" style="display:block; margin:auto;">
                        </div>
                        <!-- 課程特色 -->
                        <div>
                            <h3 style="font-family:DFKai-sb;"><br>課程特色</h3>
                            <ul class="fs-6">
                                <li>
                                    教你如何使用側掛潛水裝備。
                                </li>
                                <li>
                                    教你如何使用乾式防寒衣。
                                </li>
                                <li>
                                    海洋課程期間提供調節器、B.C.D.、乾式防寒衣（限定尺寸）。
                                </li>
                                <li>
                                    平日 / 假日 均有開課，課程安排很方便。
                                </li>
                                <li>
                                    領導級人員對學員比例 1：2，教練時時刻刻都在您身旁。
                                </li>
                                <li>
                                    數位教材伴您一生，不必擔心知識忘記。
                                </li>
                                <li>
                                    學員上課期間，購買潛水器材享有學員優惠價。
                                </li>
                            </ul>
                            <br>
                            <img src="../../../../assets/Image/Data/SC_DRY_02.png" width="100%" style="display:block; margin:auto;">
                        </div>
                        <!-- 課程規劃 -->
                        <div>
                            <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td width="10%">
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                DAY 1 | 學科 19:00~22:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                為什麼選用乾衣、如何挑選乾衣、乾衣配件介紹、乾衣試穿。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 2 | 平靜水域13:00~17:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                穿脫乾衣、乾衣充排閥練習、下潛與上升、中性浮力、倒立潛水員回復。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 3 | 開放水域08:00~12:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                穿脫乾衣、乾衣充排閥練習、下潛與上升、中性浮力、倒立潛水員回復。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <img class="d-block" src="../../../../assets/Image/Data/Schedule_DRY.png" width="100%" style="display:block; margin:auto;">
                        </div>
                        <!-- 課程學費 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">課程學費</h3>
                            <hr>
                            <ul class="fs-6">
                                <li style="color: green;">定價 12,000 元。</li>
                                <li style="color: green;">自備乾衣  $9,000。</li>
                                <li style="color: green;">購買乾衣  $6,000。</li>
                                <li>現金優惠，折扣 300 元。</li>
                                <li>一般休閒水肺課程全面提供免費的重裝備使用（專業選修除外）移地訓練裝備免費使用一天。</li>
                                <li>費用包含：SSI數位教材、國際數位證照申請、教練費、訓練期間潛水重裝使用（浮力調整裝置、調節器、電腦錶）、旅遊平安保險。</li>
                                <li>費用不含：海洋實習交通與餐食費用、輕裝租借。</li>
                            </ul>
                            <br>
                        </div>
                        <!-- 報名方式 -->
                        <div>
                            <br>
                            <app-paymentmethod></app-paymentmethod>
                            <br>
                        </div>
                        <!-- 上課地點 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">上課地點</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                            <p>教室學科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>iDiving 士林前港店（台北市士林區前港街 8 號）</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>海洋實習:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>iDiving 東北角龍洞店（新北市貢寮區龍洞街 66-1 號）</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="color:green;vertical-align:text-top;">
                                            <p>註: </p>
                                        </td>
                                        <td class="fs-6" style="color:green;">
                                            <p>
                                                上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修等），若有額外增加的費用本中心將自行吸收，不會另外收費，但學員不可因課程時間調整而要求退費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 特約課程 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">特約課程</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>學科部份，每另外約一日課程加價 300 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>術科部份，每另外約一日課程加價 500 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>三人同行，一起約定特殊時間上課，不須額外加價。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 更改辦法 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">更改辦法</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>課程須於開始上課後的兩個月內完成。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期之更改須在 10 天前告知</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 取消辦法 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">取消辦法</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>若因身體因素不適合從事潛水活動，並開立醫生證明者，除了NT 3,000元購買數位教材的訂金，其餘將會退還。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                        </div>
                    </ng-template>
                </li>
            </ul>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>

            <!-- <pre>Active: {{ active }}</pre> -->

        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>