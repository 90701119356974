<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">玩潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">蘭嶼</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <h3 style="font-family:DFKai-sb;">蘭嶼</h3>
            <hr>
            <br>
            <!-- <img src="../../../assets/Image/Data/Kenting_1..jpg" style="float: right; width: 80%">  class="fs-6" -->
            <!-- 景點介紹 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【景點介紹】</h3>
                <table cellpadding="0" cellspacing="0" style="text-align: justify;">
                    <tr>
                        <td class="fs-6" width="70%">
                            <strong>位置</strong><br />
                            蘭嶼位於西太平洋位置，在臺灣本島（達悟語稱做Ilaod）的東南方，在綠島的南方，南臨巴士海峽與菲律賓之巴丹群島遙遙相望。最早的名稱是【人之島】，後因島上盛產蝴蝶蘭而改其名為蘭嶼。位於蘭嶼島東南方三海浬處，有一小島稱小蘭嶼，面積僅1.6平方公里，島上缺乏水源及耕地，故至今無人居住。
                        </td>
                        <td><img src="../../../assets/Image/Data/Lanyu_01.jpg" style="float: right; width: 80%"></td>
                    </tr>
                </table>
                <table cellpadding="0" cellspacing="0" style="text-align: justify;">
                    <tr>
                        <td class="fs-6">
                            <strong>氣候</strong><br />
                            蘭嶼與台灣本島不同, 是全台灣唯一被歸類於熱帶雨林氣候的地區。年溫差小, 終年氣溫溫和但潮濕多雨,
                            同時也是全台灣日照時數最少的地區之一。山區與平地平均溫差3到4度(氣象站設置於標高324米的山丘上，因此氣溫較低)，山地年平均溫度約23度，平地年平均溫度約26度，冬季氣候溫和潮溼，夏季炎熱多雨。
                        </td>
                    </tr>
                </table>
                <br>
                <table cellpadding="0" cellspacing="0" style="text-align: justify;">
                    <tr>
                        <td><img src="../../../assets/Image/Data/Lanyu_02.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Lanyu_03.jpg" width="100%"></td>
                    </tr>
                </table>
                <br>
                <table cellpadding="0" cellspacing="0" style="text-align: justify;">
                    <tr>
                        <td class="fs-6">
                            <strong>地形</strong><br />
                            蘭嶼為火山島地形，島上丘陵綿亙，溪流分歧，只有沿海部分為平地、海岸線曲折，熱帶林木遍布。島上至高點位於中央偏西北處的紅頭山，海拔約552公尺，環島公路繞行全島總長35公里，高峻多變的山勢為茂密的雨林植被覆蓋，形成具有原始熱帶島嶼的地形景觀。
                            <br><br>
                            蘭嶼島河流不長且水量小，成放射狀水系。向西南流有椰油溪、漁人溪、紅頭溪；向北流有朗島溪；向東與東北流有東清溪、野銀溪。溪床礫石磊磊，常成伏流。
                        </td>
                    </tr>
                </table>
                <br>
                <table cellpadding="0" cellspacing="0" style="text-align: justify;">
                    <tr>
                        <td><img src="../../../assets/Image/Data/Lanyu_04.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Lanyu_05.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Lanyu_06.jpg" width="100%"></td>
                    </tr>
                </table>
                <br>
                <table cellpadding="0" cellspacing="0" style="text-align: justify;">
                    <tr>
                        <td class="fs-6">
                            <strong>地質</strong><br />
                        </td>
                    </tr>
                    <tr>
                        <td class="fs-6">
                            蘭嶼屬於台灣和呂宋之間的一系列火山島之一，構成蘭嶼基底的舊期岩類，可能是古第三紀漸新世末期開始噴發的火山活動所形成，但今天露出於海面以上的山體主要是由中新世以後再噴發的集塊岩和安山岩所構成。
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 著名潛點 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【著名潛點】</h3>
                <table style="text-align: justify;">
                    <tr>
                        <td><img src="../../../assets/Image/Data/Lanyu_07.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Lanyu_08.jpg" width="100%"></td>
                    </tr>
                </table>
                <br>
                <table style="text-align: justify;">
                    <tr>
                        <td class="fs-6">
                            南面潛點：
                            <ol class="fs-6">
                                <li>八代灣沉船：八代灣沉船為蘭嶼代表性潛點，最大深度40米，位於紅頭漁港外不遠處。1983年9月，韓國籍遠洋貨輪，將駛往新加地路上遇小蘭嶼天氣轉惡劣，誤觸礁石，海水灌入船艙，船長緩慢將船移至八代灣位置，任由船隻航向海底。可自在在船身遊動，隨時留意深度，進入船艙，易受雜物纏繞，請特別小心。
                                </li>
                                <li>四條溝：潛點位於蘭嶼的西南側，海域上方為青青草原，草原隨地形延伸成峭壁，峭壁裂隙珍成溝澗，如沙溝般瀉入海中，若從海底向岸邊望去，有四道明顯沙溝，故取名四條溝。本潛點主要享受放流過程產生的飄移感覺，屬高級潛點，不建議初級潛水員造訪，最佳造訪季節為3月至9月。
                                </li>
                                <li>椰油斷層：蘭嶼最佳岸潛的潛點，位在環島公路旁的椰島公路旁的椰油小漁港，因少有船隻進出，此處的卸船坡道，有著良好的入水環境。入水後，環境明顯好辨識，水流穩定。常有色彩鮮艷的棘穗軟珊瑚，搭配岩石崎嶇模樣，非常適合廣角拍攝。
                                </li>
                            </ol>
                        </td>
                    </tr>
                </table>
                <br>
                <table style="text-align: justify;">
                    <tr>
                        <td><img src="../../../assets/Image/Data/Lanyu_10.jpg" width="100%"></td>
                        <td>&nbsp;</td>
                        <td><img src="../../../assets/Image/Data/Lanyu_11.jpg" width="100%"></td>
                    </tr>
                </table>
                <br>
                <table style="text-align: justify;">
                    <tr>
                        <td class="fs-6">
                            北面潛點：
                            <ol class="fs-6">
                                <li>雙獅岩：位於蘭嶼島的正東北角，環島公路(東縣80線)11公里處，左獅穩臥、右獅氣凌，魚群奇岩盡收眼底。右獅位於來自東清往北洋流，與沿岸南流交匯所在，此地常可見大型牛港鰺趨近潛水員的畫面，海龜、海蛇更是常見。當近中午潛水時從水裡往水面看，可以清楚看到雙獅岩的型狀，非常美麗壯觀。
                                </li>
                                <li>玉女岩外礁：位於蘭嶼北方朗島部落中的玉女岩，傳說是一個孝順的孩子，有父母親爭吵時用紙體阻擋父母，但卻沒有成功，於是傷心無奈的孩子，化為一座佇立在海岸的岩石。北岸潛點以能見度好，水下岩石景觀特殊著稱。
                                </li>
                            </ol>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 建議行程 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【建議行程】</h3>
                <!-- 行程說明 -->
                <div>
                    <h3 style="font-family:DFKai-sb;">行程說明</h3>
                    <table style="text-align: justify;">
                        <tr>
                            <td class="fs-6">
                                出發<br />
                                交通方式：<br />
                                方案一、<br />
                                台北-台東：搭乘華信航空 AE-395 班機（17:30-18:35）前往台東，並於台東過夜一晚。<br />
                                台東-蘭嶼：行程當日上午搭乘渡輪前往蘭嶼（07:00-09:30）。<br />
                                <br />
                                方案二、<br />
                                台北-台東：搭乘台灣鐵路太魯閣 448（20:05-00:18）前往台東，並於台東過夜一晚。<br />
                                台東-蘭嶼：行程當日上午搭乘渡輪前往蘭嶼（07:00-09:30）。<br />
                                <br />
                                方案三、<br />
                                台北-台東：搭乘華信航空 AE-391 班機（07:15-08:20）前往台東。<br />
                                台東-蘭嶼：行程當日上午搭乘德安航空班機前往蘭嶼。<br />
                                ＊航班為 7503（09:25-09:55）或7505（10:10-10:40）<br />
                                <br />
                                方案四、<br />
                                台北-墾丁：搭乘台灣高鐵 0157 車次（19:31-21:05），再搭乘接駁車至墾丁（約2小時），並於墾丁過夜一晚。<br />
                                墾丁-蘭嶼：搭乘渡輪前往蘭嶼（07:30-10:00）
                            </td>
                            <td><img src="../../../assets/Image/Data/Lanyu_17.jpg" style="float: right; width: 80%">
                            </td>
                        </tr>
                    </table>
                    <br />
                    <table style="text-align: justify;">
                        <tr>
                            <td class="fs-6">
                                第一天<br />
                                抵達蘭嶼，將先至潛店整理裝備。11:30 於民宿享用簡易午餐，稍作休息後將安排一趟2支的船潛。晚餐於五爪貝餐廳享用。
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="fs-6">
                                第二天<br />
                                早餐於民宿享用，此日上午下午各會安排一趟船潛，因為潛水總共有四支，中午將會在碼頭附近享用簡餐，晚餐再由領隊帶領大家去附近的餐館享用風味餐點。
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="fs-6">
                                第三天<br />
                                早餐於民宿享用，此日上午將安排一趟（兩支）的船潛。潛水完稍作盥洗，於民宿享用午餐，下午即為自由活動時間。
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                    </table>
                    <table style="text-align: justify;">
                        <tr>
                            <td class="fs-6">
                                第四天<br />
                                將依照各位所訂的船班、航班，分批至碼頭、機場，結束完美的旅程。<br />
                                <br />
                                交通方式：<br />
                                <br />
                                方案一、
                                蘭嶼-台東：搭乘渡輪前往台東（15:30-18:00）。
                                台東-台北：搭乘華信航空AE-396（19:15-20:15）返回台北。<br />
                                <br />
                                方案二、<br />
                                蘭嶼-台東：搭乘渡輪前往台東（09:30-12:00），並於台東享用午餐。<br />
                                台東-台北：搭乘台鐵普悠瑪431列車（15:20~19:38）返回台北。<br />
                                <br />
                                方案三、<br />
                                蘭嶼-台東：搭乘德安航空班機返回台北<br />
                                ＊航班為7508（13:25-13:55）或7510（14:30-15:00）或7512（16:30-17:00）<br />
                                台東-台北：搭乘華信航空AE-396（19:15-20:15）返回台北。<br />
                                <br />
                                方案四、<br />
                                蘭嶼-墾丁：搭乘渡輪前往墾丁（10:00-12:30），於墾丁附近享用午餐。<br />
                                墾丁-台北：搭乘接駁車至左營（約2小時）再搭乘高鐵返回台北。
                            </td>
                            <td><img src="../../../assets/Image/Data/Lanyu_18.jpg" style="float: right; width: 80%">
                            </td>
                        </tr>
                    </table>
                </div>
                <!-- 費用說明 -->
                <div>
                    <h3 style="font-family:DFKai-sb;">費用說明</h3>
                    <div>
                        行程費用及更多活動詳情請洽iDiving Line&#64;
                        <a href="https://lin.ee/ge19YeP">
                            <img src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png" height="36" border>
                        </a>
                    </div>
                    <table width="70%" style="text-align: justify;">
                        <tr class="fs-6">
                            <td width="1%">費用包含：</td>
                            <td>３夜住宿、７簡餐、船潛４趟共８支、三佰萬旅遊平安險。</td>
                        </tr>
                        <tr class="fs-6">
                            <td style="white-space:nowrap">不包含：</td>
                            <td>潛水裝備租借的費用及個人性質的花費。台北來回蘭嶼的費用。</td>
                        </tr>
                        <tr>
                            <td colspan="2" style="white-space:nowrap"><a target="_blank" href="https://goo.gl/forms/AFgsrUnZYexX7L1i1">立即報名</a></td>
                        </tr>
                    </table>
                </div>
            </div>
            <!-- 注意事項 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【注意事項】</h3>
                <table>
                    <tr>
                        <td>
                            <ol class="fs-6">
                                <li>填寫＂Google 表單＂報名，並繳交團費<span class="Large Important"> NT$5,000
                                        的『定金』</span>才算完成報名流程，若以轉帳方式繳交定金，請將您的匯款日期及帳號末五碼與金額傳至iDiving官方Line
                                        &#64;帳號，以便確認。若以現金（or匯款）繳交全額費用，享有現金優惠！</li>
                                <li>國內潛水旅遊活動會有成團人數要求，含領隊需達10人才會出團；<span
                                        class="Large Important">若因報名人數不足而無法出團，最遲將於出團日14天以前通知已報名活動之團員，『定金』可全數退還。</span>
                                </li>
                                <li><span class="Large Important">出團日30天以前會於Line的活動群組提供行程資料，若有任何需求請提出討論。</span></li>
                                <li>當人數達到出團門檻，所有團員亦繳清費用時，則保證出團，若有人員臨時取消造成出團人數不足亦不受影響，而臨時退團人員的退費規則如下：<br />
                                    <span class="Large Important">A. 活動開始前第十五日以上取消者，須賠償團費的百分之十。</span><br />
                                    <span class="Large Important">B. 活動開始前第五日至第十四日取消者，須賠償團費的百分之三十。</span><br />
                                    <span class="Large Important">C. 活動開始前第二日至第四日取消者，須賠償團費的百分之五十。</span><br />
                                    <span class="Large Important">D. 於活動當日或前一日取消，或未通知不參加者，須賠償團費全額。</span><br />
                                    *若有溢繳或可退款的費用，可選擇來店退款或刷退（須視當時繳費方式），若要匯款轉帳退費須扣除手續費（每筆NT 30元），亦可選擇保留餘額轉至其他消費。
                                </li>
                                <li>若遇颱風、浪況不佳等不可抗拒之因素，基於安全考量，iDiving將保有取消活動之權利，已繳交的費用於扣除必要支出（如代訂大眾交通的退票手續費）後退還。</li>
                                <li>國內潛水旅遊皆為完整套裝行程，如有個人因素更動行程內容，恕不提供會員優惠。</li>
                                <li>住宿房間之房等差異（套房、雅房、通鋪等），如現場遇特殊情況，將由領隊視情況協調分配。若有特殊需求請事先提出。</li>
                                <li>須持有合格的潛水證照（國際潛水證照初級以上資格），若要參加特殊環境潛水（如：夜潛），需具備相關資格；部分活動可能會有額外條件要求。</li>
                                <li>活動行程以Line活動群組公布的資訊為準。潛點選擇由潛導視當時天候、海況、團隊狀況決定。進行潛水活動時採潛伴制進行，請彼此相互照應。</li>
                                <li>禁帶漁獵器材，亦禁止攜帶任何生物上岸，違者不得再參加後續潛水活動。</li>
                                <li>填寫＂Google表單＂報名者，視同同意以上所有條款。</li>
                            </ol>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 協辦旅行社 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【協辦旅行社】</h3>
                <div>iDiving 為了讓旅遊活動有保障，所有旅遊行程特別與旅行社偕同舉辦，讓你玩得開心又有保障。</div>
                <br />
                <table border="1" cellpadding="0" cellspacing="0">
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">主辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">愛潛水股份有限公司</td>
                        <td>承辦人：愛潛水</td>
                        <td>行動電話：0909-097696</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2882-7696</td>
                        <td>FAX：02-2882-7656</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAILl：info&#64;idiving.com.tw</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市士林區前港街8號</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">協辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">萬陽旅行社有限公司</td>
                        <td>業務請洽：蘇恒璀</td>
                        <td>行動電話：0937-196518</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2517-1333</td>
                        <td>FAX：02-2508-1537</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAIL：tracy410&#64;ms45.hinet.net</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市南京東路3段91號四樓之三</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>