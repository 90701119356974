<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">學潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">環保專長潛水員</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a class="fs-4" ngbNavLink>生態潛水員</a>
                    <ng-template ngbNavContent class="custom-class">

                        <h3 style="font-family:DFKai-sb;">生態潛水員 ECO DIVER (ECO)</h3>
                        <hr>
                        <!-- 課程內容 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">開課內容</h3>
                            <ul>
                                <li class="fs-6">
                                    學科（6hr）<br>
                                    珊瑚礁體檢的重要性 / 魚類辨識 / 無脊椎辨識 / 底質辨識
                                </li>
                            </ul>
                            <ul>
                                <li class="fs-6">
                                    術科（4支＋潮境智能海洋館）<br>
                                    生物辨識的實作 / 三種不同科學方法的操作 / 穿越線的實作練習
                                </li>
                            </ul>
                            <br>
                            <!-- <img src="../../../../assets/Image/Data/ECO_Schedule.png" width="100%" style="display:block; margin:auto;"> -->
                        </div>
                        <!-- 課程特色 -->
                        <div>
                            <h3 style="font-family:DFKai-sb;"><br>課程特色</h3>
                            <ul class="fs-6">
                                <li>
                                    學會生物的基礎辨識方法。
                                </li>
                                <li>
                                    瞭解如何執行一趟科學調查。
                                </li>
                            </ul>
                        </div>
                        <!-- 課程規劃 -->
                        <div>
                            <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td width="10%">
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br> DAY 1 | 學科 19:00~22:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                珊瑚礁體檢的重要性 / 無脊椎的辨識與考試
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="10%">
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 2 | 學科 19:00~22:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                魚類辨識與考試
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="10%">
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 3 | 學科 19:00~22:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                底質辨識與考試
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="10%">
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 4 | 術科 08:00~18:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                潮境智能海洋館生物辨識與水下調查動作操作
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="10%">
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 5 | 術科 08:00~18:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                水下調查動作操作與考試
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <img class="d-block" src="../../../../assets/Image/Data/Schedule_ECO.png" width="100%" style="display:block; margin:auto;">
                        </div>
                        <!-- 課程學費 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">課程學費</h3>
                            <hr>
                            <ul class="fs-6">
                                <li style="color: green;">定價 11,000 元 </li>
                                <li>現金優惠，折扣 300 元。</li>
                                <li>
                                    費用包含：電子教材、國際數位證照申請、調查紀錄版、教練費、東北角海洋實習費用、氣瓶、潮境工作站門票、
                                    考試費與保險費。費用不含：輕重裝租借、停車或餐飲費用。移地結訓時之旅遊團費（請參考各旅遊團實際出團報價）。
                                    (若需租借全套重裝，EcoDiver 優惠價為 1,000 元)
                                </li>
                            </ul>
                            <br>
                        </div>
                        <!-- 報名方式 -->
                        <div>
                            <br>
                            <app-paymentmethod></app-paymentmethod>
                            <br>
                        </div>
                        <!-- 報名須知 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">報名須知</h3>
                            <hr>
                            <p><br>限制條件：（需兩項都符合）</p>
                            <ul class="fs-6">
                                <li>
                                    擁有50支以上潛水經驗。
                                </li>
                                <li>
                                    具備AA,完美浮力或以上等級執照。
                                </li>
                            </ul>
                        </div>
                        <!-- 上課地點 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">上課地點</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                            <p>教室學科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>iDiving 士林前港店（台北市士林區前港街 8 號）</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>海洋實習:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>iDiving 東北角龍洞店（新北市貢寮區龍洞街 66-1 號）</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="color:green;vertical-align:text-top;">
                                            <p>註: </p>
                                        </td>
                                        <td class="fs-6" style="color:green;">
                                            <p>
                                                上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修等），
                                                若有額外增加的費用本中心將自行吸收，不會另外收費，但學員不可因課程時間調整而要求退費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 特約課程 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">特約課程</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <a>學科部份，每另外約一日課程加價 300 元</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <a>術科部份，每另外約一日課程加價 500 元</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <a>三人同行，一起約定特殊時間上課，不須額外加價。</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <a>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 課程更改 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">課程更改</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>課程須於開始上課後的兩個月內完成。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期之更改須在 10 天前告知</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 課程取消 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">課程取消</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>若因身體因素不適合從事潛水活動，並開立醫生證明者，除了NT 3,000元購買數位教材的訂金，其餘將會退還。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a class="fs-4" ngbNavLink>魚類辨識</a>
                    <ng-template ngbNavContent>

                        <h3 style="font-family:DFKai-sb;">魚類辨識 FISH IDENTIFICATION</h3>
                        <hr>
                        <!-- 課程內容 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">開課內容</h3>
                            <ul>
                                <li class="fs-6">
                                    學科（3hr）<br>
                                    物種說明 / 分類說明 / 辨識基礎概念 / 20多科魚類辨識解說
                                </li>
                            </ul>
                            <ul>
                                <li class="fs-6">
                                    術科（潮境智能海洋館）<br>
                                    生物辨識的實作
                                </li>
                            </ul>
                            <br>
                            <img src="../../../../assets/Image/Data/FISH_License.png" width="100%" style="display:block; margin:auto;">
                        </div>
                        <!-- 課程特色 -->
                        <div>
                            <h3 style="font-family:DFKai-sb;"><br>課程特色</h3>
                            <ul class="fs-6">
                                <li>
                                    藉由學科將會瞭解到要如何辨識魚類，配合影片與活體進行討論，加深各科生物的行為與形態。
                                </li>
                                <li>
                                    適合不懂得辨識生物的初學者/適合基礎瞭解部分魚類但是不知道遇到新物種該如何查找圖鑑的潛水員。
                                </li>
                            </ul>
                        </div>
                        <!-- 課程規劃 -->
                        <div>
                            <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td width="10%">
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br> DAY 1 | 學科 9:00~12:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                魚類辨識與學科考試
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="10%">
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 1 | 術科 14:30~17:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                潮境智能海洋館活體特徵與行為觀察
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                        </div>
                        <!-- 課程學費 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">課程學費</h3>
                            <hr>
                            <ul class="fs-6">
                                <li style="color: green;">定價 5,500 元 </li>
                                <li>現金優惠，折扣 300 元。</li>
                                <li>
                                    費用包含：電子教材+國際數位證照申請或海水魚圖鑑（二擇一）、教練費、潮境工作站門票、考試費與士林到潮境智能海洋館交通。
                                    費用不含：餐飲費用。移地結訓時之旅遊團費（請參考各旅遊團實際出團報價）。
                                </li>
                            </ul>
                            <br>
                        </div>
                        <!-- 報名方式 -->
                        <div>
                            <br>
                            <app-paymentmethod></app-paymentmethod>
                            <br>
                        </div>
                        <!-- 報名須知 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">報名須知</h3>
                            <hr>
                            <p>不限年齡，但內容很多，建議年滿10歲以上</p>
                        </div>
                        <!-- 上課地點 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">上課地點</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                            <p>教室學科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>iDiving 士林前港店（台北市士林區前港街 8 號）</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>術科實習:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>潮境智能海洋館</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="color:green;vertical-align:text-top;">
                                            <p>註: </p>
                                        </td>
                                        <td class="fs-6" style="color:green;">
                                            <p>
                                                上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修等），
                                                若有額外增加的費用本中心將自行吸收，不會另外收費，但學員不可因課程時間調整而要求退費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 特約課程 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">特約課程</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>學科部份，每另外約一日課程加價 300 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>術科部份，每另外約一日課程加價 500 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>三人同行，一起約定特殊時間上課，不須額外加價。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 課程更改 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">課程更改</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>課程須於開始上課後的兩個月內完成。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期之更改須在 10 天前告知</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 課程取消 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">課程取消</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <ap>學員自行放棄課程，本訓練中心將不予退費。</ap>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <ul>
                                                <li></li>
                                            </ul>
                                        </td>
                                        <td class="fs-6">
                                            <p>若因身體因素不適合從事潛水活動，並開立醫生證明者，除了NT 3,000元購買數位教材的訂金，其餘將會退還。</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                        </div>
                    </ng-template>
                </li>
            </ul>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>

            <!-- <pre>Active: {{ active }}</pre> -->

        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>