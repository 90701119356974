<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10" align="center">
            <div>
                <iframe src="https://forms.gle/9YbEY2CkRwV33NKN7" width="100%" height="2500" frameborder="0" marginheight="0" marginwidth="0">載入中…</iframe>
            </div>
            <div>
                <p class="fs-4">請往上滑動，繼續填寫</p>
            </div>
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>