<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">玩潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">綠島</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <h3 style="font-family:DFKai-sb;">綠島</h3>
            <hr>
            <br>
            <!-- 景點介紹 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【景點介紹】</h3>
                <table cellpadding="0" cellspacing="0" style="text-align: justify;">
                    <tr>
                        <td>
                            <table width="100%">
                                <tr>
                                    <td width="55%">
                                        <table cellpadding="0" cellspacing="0" width="100%">
                                            <tr>
                                                <td class="fs-6">
                                                    <strong>綠島</strong><br />
                                                    綠島原名火燒島，乃一火山集塊岩構成的島嶼，四周為裙狀珊瑚礁所環繞，位於台東東南方33公里海面上，面積約16平方公里環島長約20公里。綠島土質為棕紅壤土，每逢春夏之節東南風狹帶雲霧水氣席捲迎風帶，所經之處似焚如之禍，草木一片焦黃，秋冬之際強勁東北風肆虐，全島植物乾枯，山頭間紅土裸露，過往船隻遠眺島上，猶如火燒一般，因此稱之火燒島。
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td><img src="../../../assets/Image/Data/GreenIsland_01.jpg"
                                            style="float: right; width: 80%">
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td class="fs-6">
                            <strong>氣候</strong><br />
                            綠島屬亞熱帶季風氣候，全年溫暖、潮濕、多風，海洋性氣候濃厚四至八月受到西南氣流影響，七月至九月常有颱風侵襲，十月至翌年三月則是受到東北季風影響，全年強風天多達160餘天。由於綠島
                            蘭嶼位於黑潮北流的路線上因為黑潮屬於暖流即使冬天的水溫也有20度以上，非常適合水上活動發展。即便是夏天受到颱風外圍環流影響導致海底能見度不佳，也能藉由黑潮洋流快速帶走水中懸浮物，這也是綠島為何海底能見度可以長年維持25m～30m以上的原因。
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td class="fs-6">
                            <strong>陸遊景點</strong><br />
                            冬季的時候不冷不熱的天氣是最適合到台東跟綠島旅遊的季節，可以安排台東冬季賞花或騎鐵馬泡湯的行程，再搭配綠島的旅遊，感受不同於夏天熱鬧喧囂的綠島，一個屬於真正的綠島漁村寧靜的樂活生活。綠島監獄
                            朝日溫泉或者石朗夕陽都是非常棒的景點，跟隨當地民宿的夜間導覽，也可以欣賞不一樣的綠島生態。
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 著名潛點 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【著名潛點】</h3>
                <table style="text-align: justify;">
                    <tr>
                        <td>
                            <table width="100%">
                                <tr>
                                    <td><img src="../../../assets/Image/Data/GreenIsland_02.jpg" width="100%"></td>
                                    <td>&nbsp;</td>
                                    <td><img src="../../../assets/Image/Data/GreenIsland_03.jpg" width="100%"></td>
                                    <td>&nbsp;</td>
                                    <td><img src="../../../assets/Image/Data/GreenIsland_04.jpg" width="100%"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <ol class="fs-6">
                                <li>大峽谷：綠島頗負盛名的高級船潛點，大峽谷座落於綠島公館鼻外，因礁岩大裂縫神似壯闊大峽谷而得此名。此潛點流強須由熟悉潛點的船家挑選洋流較弱時間下水較安全，特別是進入峽谷時的強流需特別注意，流大時千萬不可貿然進入應採較安全的方式繞行外礁盤，峽谷內側及峽谷外側皆是廣角攝影的好地點。
                                </li>
                                <li>鋼鐵礁：綠島初級船潛點，鋼鐵礁由鋼鐵架與廢棄輪胎構成四座大型人工魚礁，許多魚群皆在魚礁附近活動，早期有多達七十多隻燕魚群進駐，因為人為盜獵只剩三～五隻燕魚，台灣的漁業觀光資源需要大家共同維護。
                                </li>
                                <li>雞仔礁：此潛點為高級船潛點，下水點有一突出岩石狀似雞頭而得名，礁盤上佈滿軟硬珊瑚，沿著礁岩往北前進會經過另一地標鱷魚嘴，雞仔礁潛點有許多奇岩怪石供水攝者發揮想像力拍出獨樹一格的廣角照。
                                </li>
                                <li>教堂：位於綠島西南的岸潛點，由馬蹄橋下入水，此區淺水海域多為硬珊瑚，並有海蝕礁柱
                                    洞穴以及拱門（教堂）地形，教堂內光線變化十分適合廣角拍攝，此外海浪拍打岸邊的聲音迴盪在洞穴內也非常震撼。</li>
                                <li>大香菇：大香菇位於石朗外海，他是世界上最大的活體團塊微孔珊瑚，置底約18公尺，大香菇頂部巨海面約8公尺以每年長高一公分來算以每年長高一公分來算，已有一千年歷史。2016年的莫蘭蒂颱風造成大香菇傾倒約四十五度，雖然大香菇珊瑚仍然可以存活只是整體形狀會改變。
                                </li>
                                <br>
                                <table width="100%">
                                    <tr>
                                        <td><img src="../../../assets/Image/Data/GreenIsland_05.jpg" width="100%"></td>
                                        <td>&nbsp;</td>
                                        <td><img src="../../../assets/Image/Data/GreenIsland_06.jpg" width="100%"></td>
                                        <td>&nbsp;</td>
                                        <td><img src="../../../assets/Image/Data/GreenIsland_07.jpg" width="100%"></td>
                                    </tr>
                                </table>
                            </ol>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 建議行程 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【建議行程】</h3>
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>水肺 3 天 2 夜</a>
                        <ng-template ngbNavContent class="custom-class">
                            <!-- 行程說明 -->
                            <div>
                                <h3 style="font-family:DFKai-sb;">行程說明</h3>
                                <table style="text-align: justify;">
                                    <tr>
                                        <td class="fs-6">
                                            出發<br>
                                            <ol>
                                                <li>行程前一天晚間 19:30 於台北車站集合搭火車前往台東，當晚於台東民宿住宿一晚。</li>
                                                <li>當天早上 06:30 於松山機場集合，搭乘華信航空(AE-391)前往台東。</li>
                                            </ol>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            第一天<br>
                                            早上 08:30 搭乘接駁車前往富岡碼頭，搭船時間約一小時，中午於綠島街上用餐，下午進行船潛 2
                                            支，盥洗後即享用晚餐。餐後可自由活動安排潮間帶陸遊或觀星。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            第二天<br>
                                            早上 7:30 用餐，8:30 集合潛水，早上船潛一趟 2 支，下午船潛一趟 2 支，午餐晚餐用餐時間現場公布。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            第三天<br>
                                            早上於民宿附近用完早餐後自行活動，中午集合用餐，收拾裝備後搭船回台東，結束此次的旅程。早上於民宿附近用完早餐後自行活動，中午集合用餐，收拾裝備後搭船回台東，結束此次的旅程。<br>
                                            交通方式：<br>
                                            <ol class="fs-6">
                                                <li>搭乘火車返回台北。（預計搭乘 17:00 前後的班次）</li>
                                                <li>搭乘飛機華信航空（AE-396）返回台北。</li>
                                            </ol>
                                        </td>
                                    </tr>
                                </table>
                                <br />
                                <table style="text-align: justify;">
                                    <tr>
                                        <td><img src="../../../assets/Image/Data/GreenIsland_08.jpg" width="100%"></td>
                                        <td>&nbsp;</td>
                                        <td><img src="../../../assets/Image/Data/GreenIsland_09.jpg" width="100%"></td>
                                        <td>&nbsp;</td>
                                        <td><img src="../../../assets/Image/Data/GreenIsland_10.jpg" width="100%"></td>
                                    </tr>
                                </table>
                            </div>
                            <!-- 費用說明 -->
                            <div>
                                <h3 style="font-family:DFKai-sb;">費用說明</h3>
                                <div>
                                    行程費用及更多活動詳情請洽iDiving Line&#64;
                                    <a href="https://lin.ee/ge19YeP">
                                        <img src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png"
                                            height="36" border>
                                    </a>
                                </div>
                                <table style="text-align: justify;">
                                    <tr>
                                        <td class="fs-6" width="10%">費用包含：</td>
                                        <td class="fs-6">２夜住宿、５簡餐、船潛３趟共６支、三佰萬旅遊平安險、台東－綠島船票。</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="white-space:nowrap">不包含：</td>
                                        <td class="fs-6">潛水裝備租借的費用及個人性質的花費。台北來回台東的費用。</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" colspan="2" style="white-space:nowrap"><a target="_blank"
                                                href="https://goo.gl/forms/AFgsrUnZYexX7L1i1">立即報名</a></td>
                                    </tr>
                                </table>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink>水肺 4 天 3 夜</a>
                        <ng-template ngbNavContent>
                            <!-- 行程說明 -->
                            <div>
                                <h3 style="font-family:DFKai-sb;">行程說明</h3>
                                <table style="text-align: justify;">
                                    <tr>
                                        <td class="fs-6">
                                            出發<br>
                                            交通方式
                                            <ol>
                                                <li>行程前一天晚間 19:30 於台北車站集合搭火車前往台東，當晚於台東民宿住宿一晚。</li>
                                                <li>當天早上 06:30 於松山機場集合，搭乘華信航空(AE-391)前往台東。</li>
                                            </ol>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            第一天<br>
                                            早上 08:30 搭乘接駁車前往富岡碼頭，搭船時間約一小時，中午於綠島街上用餐，下午進行船潛 2
                                            支，盥洗後即享用晚餐。餐後可自由活動安排潮間帶陸遊或觀。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            第二天<br>
                                            早上 08:30 搭乘接駁車前往富岡碼頭，搭船時間約一小時，中午於綠島街上用餐，下午進行船潛 2
                                            支，盥洗後即享用晚餐。餐後可自由活動安排潮間帶陸遊或觀。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            第三天<br>
                                            早上 7:30 用餐，8:30 集合潛水，早上船潛一趟 2 潛，下午自由活動，午餐晚餐用餐時間現場公布。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            第四天<br>
                                            早上民宿用完早餐後自行陸遊，中午集合用餐後休息片刻搭船回台東，結束此次的旅程。（預計搭乘 14:30 的船班）
                                            <ol class="fs-6">
                                                <li>搭乘火車返回台北。（預計搭乘 17:00 前後的班次）</li>
                                                <li>搭乘飛機華信航空（AE-396）返回台北。</li>
                                            </ol>
                                        </td>
                                    </tr>
                                </table>
                                <br />
                                <table style="text-align: justify;">
                                    <tr>
                                        <td><img src="../../../assets/Image/Data/GreenIsland_08.jpg" width="100%"></td>
                                        <td>&nbsp;</td>
                                        <td><img src="../../../assets/Image/Data/GreenIsland_09.jpg" width="100%"></td>
                                        <td>&nbsp;</td>
                                        <td><img src="../../../assets/Image/Data/GreenIsland_10.jpg" width="100%"></td>
                                    </tr>
                                </table>
                            </div>
                            <!-- 費用說明 -->
                            <div>
                                <h3 style="font-family:DFKai-sb;">費用說明</h3>
                                <div class="fs-6">
                                    行程費用及更多活動詳情請洽iDiving Line&#64;
                                    <a href="https://lin.ee/ge19YeP">
                                        <img src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png"
                                            height="36" border>
                                    </a>
                                </div>
                                <table style="text-align: justify;">
                                    <tr class="fs-6">
                                        <td width="1%">包　含：</td>
                                        <td>３夜住宿、７簡餐、船潛４趟共８支、三佰萬旅遊平安險、台東－綠島船票。</td>
                                    </tr>
                                    <tr>
                                        <td style="white-space:nowrap">不包含：</td>
                                        <td>潛水裝備租借的費用及個人性質的花費。台北來回台東的費用。</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" style="white-space:nowrap"><a target="_blank"
                                                href="https://goo.gl/forms/AFgsrUnZYexX7L1i1">立即報名</a></td>
                                    </tr>
                                </table>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                        <a ngbNavLink>自潛 4 天 3 夜</a>
                        <ng-template ngbNavContent>
                            <!-- 行程說明 -->
                            <div>
                                <h3 style="font-family:DFKai-sb;">行程說明</h3>
                                <table style="text-align: justify;">
                                    <tr>
                                        <td class="fs-6">
                                            第一天<br>
                                            台鐵408車：07:40-11:10＋船班 G21330：13:30-14:20 <br>
                                            下午入住海韻民宿兩人房，視情況岸潛熟悉裝備並確認配重。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            第二天<br>
                                            10:00-12:00 第1次下水; 14:00-17:00 第2次(船潛)下水。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            第三天<br>
                                            10:00-12:00 第4次下水; 14:00-17:00 第4次(船潛)下水。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            第三天<br>
                                            09:00-11:00 第7次下水<br>
                                            下午離開回程：船班 S1430：14:30-15:20＋台鐵441車：17:50-21:20 （或 439車：17:07-21:58）
                                        </td>
                                    </tr>
                                </table>
                                <br />
                                <table style="text-align: justify;">
                                    <tr>
                                        <td><img src="../../../assets/Image/Data/GreenIsland_08.jpg" width="100%"></td>
                                        <td>&nbsp;</td>
                                        <td><img src="../../../assets/Image/Data/GreenIsland_09.jpg" width="100%"></td>
                                        <td>&nbsp;</td>
                                        <td><img src="../../../assets/Image/Data/GreenIsland_10.jpg" width="100%"></td>
                                    </tr>
                                </table>
                            </div>
                            <!-- 費用說明 -->
                            <div>
                                <h3 style="font-family:DFKai-sb;">費用說明</h3>
                                <div class="fs-6">
                                    行程費用及更多活動詳情請洽iDiving Line&#64;
                                    <a href="https://lin.ee/ge19YeP">
                                        <img src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png"
                                            height="36" border>
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>

                <div [ngbNavOutlet]="nav" class="mt-2"></div>
                <!-- <pre>Active: {{ active }}</pre> -->

            </div>
            <!-- 注意事項 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【注意事項】</h3>
                <table>
                    <tr>
                        <td>
                            <ol class="fs-6">
                                <li>填寫＂Google 表單＂報名，並繳交團費<span class="Large Important"> NT$5,000
                                        的『定金』</span>才算完成報名流程，若以轉帳方式繳交定金，請將您的匯款日期及帳號末五碼與金額傳至iDiving官方Line
                                        &#64;帳號，以便確認。若以現金（or匯款）繳交全額費用，享有現金優惠！</li>
                                <li>國內潛水旅遊活動會有成團人數要求，含領隊需達10人才會出團；<span
                                        class="Large Important">若因報名人數不足而無法出團，最遲將於出團日14天以前通知已報名活動之團員，『定金』可全數退還。</span>
                                </li>
                                <li><span class="Large Important">出團日30天以前會於Line的活動群組提供行程資料，若有任何需求請提出討論。</span></li>
                                <li>當人數達到出團門檻，所有團員亦繳清費用時，則保證出團，若有人員臨時取消造成出團人數不足亦不受影響，而臨時退團人員的退費規則如下：<br />
                                    <span class="Large Important">A. 活動開始前第十五日以上取消者，須賠償團費的百分之十。</span><br />
                                    <span class="Large Important">B. 活動開始前第五日至第十四日取消者，須賠償團費的百分之三十。</span><br />
                                    <span class="Large Important">C. 活動開始前第二日至第四日取消者，須賠償團費的百分之五十。</span><br />
                                    <span class="Large Important">D. 於活動當日或前一日取消，或未通知不參加者，須賠償團費全額。</span><br />
                                    *若有溢繳或可退款的費用，可選擇來店退款或刷退（須視當時繳費方式），若要匯款轉帳退費須扣除手續費（每筆NT 30元），亦可選擇保留餘額轉至其他消費。
                                </li>
                                <li>若遇颱風、浪況不佳等不可抗拒之因素，基於安全考量，iDiving將保有取消活動之權利，已繳交的費用於扣除必要支出（如代訂大眾交通的退票手續費）後退還。</li>
                                <li>國內潛水旅遊皆為完整套裝行程，如有個人因素更動行程內容，恕不提供會員優惠。</li>
                                <li>住宿房間之房等差異（套房、雅房、通鋪等），如現場遇特殊情況，將由領隊視情況協調分配。若有特殊需求請事先提出。</li>
                                <li>須持有合格的潛水證照（國際潛水證照初級以上資格），若要參加特殊環境潛水（如：夜潛），需具備相關資格；部分活動可能會有額外條件要求。</li>
                                <li>活動行程以Line活動群組公布的資訊為準。潛點選擇由潛導視當時天候、海況、團隊狀況決定。進行潛水活動時採潛伴制進行，請彼此相互照應。</li>
                                <li>禁帶漁獵器材，亦禁止攜帶任何生物上岸，違者不得再參加後續潛水活動。</li>
                                <li>填寫＂Google表單＂報名者，視同同意以上所有條款。</li>
                            </ol>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 協辦旅行社 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【協辦旅行社】</h3>
                <div>iDiving 為了讓旅遊活動有保障，所有旅遊行程特別與旅行社偕同舉辦，讓你玩得開心又有保障。</div>
                <br />
                <table border="1" cellpadding="0" cellspacing="0">
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">主辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">愛潛水股份有限公司</td>
                        <td>承辦人：愛潛水</td>
                        <td>行動電話：0909-097696</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2882-7696</td>
                        <td>FAX：02-2882-7656</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAILl：info&#64;idiving.com.tw</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市士林區前港街8號</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">協辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">萬陽旅行社有限公司</td>
                        <td>業務請洽：蘇恒璀</td>
                        <td>行動電話：0937-196518</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2517-1333</td>
                        <td>FAX：02-2508-1537</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAIL：tracy410&#64;ms45.hinet.net</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市南京東路3段91號四樓之三</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>