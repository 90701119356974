<!-- 報名方式 -->
<div>
    <table borderless="true">
        <thead>
            <tr>
                <td colspan="2" style="text-align:center;">
                    <br>
                    <!-- https://mdbootstrap.com/docs/standard/tools/builders/buttons/ -->
                    <button type="button" class="btn btn01 idiving-color btn-block" color="dark" 
                    rounded="true" style="color: white;" onclick="window.location.href='/#/cz01'">
                        立即報名
                        <br>
                    </button>
                    <br>
                    <br>
                    <br>
                </td>
            </tr>
            <tr>
                <th colspan="2">
                    <br>
                    <h3 style="font-family:DFKai-sb;">報名方式</h3>
                    <hr>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <img width="100%" src="../../../../assets/Image/Data/MM_06.png" style="display:block; margin:auto;" />
                </td>
            </tr>
            <tr>
                <td class="fs-6" style="vertical-align:text-top;">
                    <ul>
                        <li>確認後相關資料會至SSI全球官網為您註冊學籍，<a style="color: red;">一經註冊，請恕無法退還定金。</a></li>
                        <li>上課前請備好個人照片，為申請潛水證照使用。</li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
</div>