import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-b212',
  templateUrl: './b212.component.html',
  styleUrls: ['./b212.component.scss']
})
export class B212Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
