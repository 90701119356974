<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">學潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">體驗潛水/水肺複習</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>體驗潛水</a>
                    <ng-template ngbNavContent class="custom-class">
                        <br>
                        <h3 style="font-family:DFKai-sb;">體驗潛水</h3>
                        <hr>
                        <!-- 介紹 -->
                        <div>
                            <h3>介紹</h3>
                            <table style="table-layout: fixed;">
                                <tr>
                                    <td class="fs-6" colspan="2">
                                        不用會游泳，不用有證照，體驗後立即報名 OWD(Open Water Diver 開放水域潛水員課程) 課程還可享特殊優惠！
                                    </td>
                                </tr>
                                <tr>
                                    <td class="fs-6" colspan="2">
                                        iDiving 周末六日出動所有教練保障OWD訓練的安全，但是還在猶豫是否要學潛水的朋友，iDiving
                                        知道你想了解更多的需求，我們特別在平日安排了水肺體驗活動。<br>
                                        想到泳池試試無重力的飄浮感嗎？請報名泳池體驗水肺 TRY SCUBA。<br>
                                        充滿冒險勇氣的你，想直接下海觀賞可愛的海洋生物嗎？請報名海洋體驗潛水TRY SCUBA DIVE。
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td><img src="../../../assets/Image/Data/Try_1.jpg" width="100%" /></td>
                                    <td><img src="../../../assets/Image/Data/Try_2.jpg" width="100%" /></td>
                                </tr>
                            </table>
                        </div>
                        <!-- 課程規劃 -->
                        <div>
                            <br>
                            <h3>時間規劃</h3>
                            <ul class="fs-6">
                                <li>預約制, 不接受現場報名</li>
                                <li>5月~9月 每周二至周五, 每天上午下午兩時段</li>
                                <li>活動前置作業, 會有約略一個小時的裝備使用介紹</li>
                                <li>活動時間：早上9點 ~ 12點 / 下午14點 ~ 17點</li>
                            </ul>
                        </div>
                        <!-- 超值學費 -->
                        <div>
                            <h3>超值學費</h3>
                            <table style="table-layout: fixed;">
                                <tr>
                                    <td>
                                        <ul>
                                            <li class="fs-6">
                                                泳池體驗水肺 TRY SCUBA（會提供體驗的照片）：<br />
                                                兩米深水肺體驗 1,500 元，兩人同行價 2,500。<br />
                                                五米深水肺體驗 2,500 元，兩人同行價 4,500。
                                            </li>
                                            <li class="fs-6">
                                                海洋體驗水肺 TRY SCUBA DIVE（會提供當天活動的影片）：<br>
                                                海底巡遊 3,000 元，兩人同行價 5,400 元，三人同行價 7,200 元。<br>
                                                如須搭車，台北到東北角車資 300 元/每人。
                                            </li>
                                        </ul>
                                        <div>
                                            <p class="fs-6">體驗完立即報名OWD課程，學費可折抵 1,000元。</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src="../../../assets/Image/Data/Try_3.jpg" width="130%" />
                                    </td>
                                </tr>
                            </table>
                            <br>
                        </div>
                        <!-- 報名方式 -->
                        <div>
                            <app-paymentmethod1000></app-paymentmethod1000>
                        </div>
                        <!-- 學前須知 -->
                        <div>
                            <h3>學前須知</h3>
                            <ol class="fs-6">
                                <li>各種體驗課程之教學說明約30分鐘，實際下水體驗時間約20~30分鐘。</li>
                                <li>更改約定上課日期須在７天前告知。７天內更動或未告知而曠課，補課需額外負擔1000元，身體不適，出示醫院掛號收據者不收。</li>
                                <li>費用包含：教練費、訓練期間潛水裝備使用、體驗水肺之泳池場地費、保險。</li>
                                <li>費用不含：海洋公園門票（遇風浪因素而須進入海洋公園體驗潛水時）、餐飲與交通費用。</li>
                                <li>學員自行放棄課程，本訓練中心將不予退費。</li>
                                <li>訓練期間所提供之裝備若有穿戴不適或任何問題，請務必告知主持教練。</li>
                                <li>體驗潛水之交通須自理，或由iDiving協調共乘，台北到東北角車資 300 元/每人</li>
                                <li>體驗水肺泳池場地：２米深前港泳池或５米深南港、松山運動中心潛水池</li>
                                <li>海洋體驗潛水：東北角海域體驗潛水時，餐食自理，若協調至餐廳用餐則費用均攤。</li>
                            </ol>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink>水肺複習</a>
                    <ng-template ngbNavContent>
                        <br>
                        <h3 style="font-family:DFKai-sb;">水肺複習</h3>
                        <hr>
                        <!-- 課程介紹 -->
                        <div>
                            <br>
                            <br>
                            <h3>課程內容</h3>
                            <hr>
                            <br>
                            <ul>
                                <li>潛水池訓練（兩米）</li>
                                <p>
                                    潛水簡報、計畫 / 入水和出水 / 全方位潛水系統浮力檢查 / 有控制的下潛<br>
                                    面鏡排水 / 二級頭排水（爆吐法與按鈕排水法）<br>
                                    二級頭尋回（手臂橫掃與順管摸索） / 氣源共享<br>
                                    旋軸式中性浮力練習（潛水姿勢<br>
                                    緊急上升技巧 / 裝備整理 / 總結
                                </p>
                            </ul>
                            <br>
                            <ul>
                                <li>潛水池訓練（五米）</li>
                                <p>
                                    潛水簡報、計畫 / 入水和出水 / 全方位潛水系統浮力檢查 / 有控制的下潛<br>
                                    面鏡排水 / 二級頭排水（爆吐法與按鈕排水法）<br>
                                    二級頭尋回（手臂橫掃與順管摸索） / 氣源共享<br>
                                    中性浮力（潛水姿勢） / 與潛伴有控制上升 / 氣源共享上升<br>
                                    緊急上升技巧 / 裝備整理 / 總結
                                </p>
                            </ul>
                            <br>
                            <ul>
                                <li>海洋訓練</li>
                                <p>
                                    潛水簡報、計畫 / 入水和出水 / 全方位潛水系統浮力檢查 / 有控制的下潛<br>
                                    面鏡排水 / 二級頭排水（爆吐法與按鈕排水法）<br>
                                    二級頭尋回（手臂橫掃與順管摸索） / 氣源共享<br>
                                    中性浮力（潛水姿勢） / 與潛伴有控制上升 / 氣源共享上升<br>
                                    緊急上升技巧 / DSMB練習 / 裝備整理 / 總結 / 潛水日誌填寫
                                </p>
                            </ul>
                            <br>
                            <img width="30%" src="../../../assets/Image/Data/SSU_1.jpg" />
                            <br>
                            <br>
                        </div>
                        <!-- 開課日期 -->
                        <div>
                            <h3>開課日期</h3>
                            <hr>
                            <p class="fs-6">「為 5~9 月每個周末皆可安排」，但請一周前填寫資料報名，以便安排。</p>
                            <br>
                            <br>
                            <img width="75%" src="../../../assets/Image/Data/SSU_5.jpg" />
                            <br>
                            <br>
                        </div>
                        <!-- 超值學費 -->
                        <div>
                            <h3>超值學費</h3>
                            <hr>
                            <ul class="fs-6">
                                <li>海洋 含三支氣瓶 定價 5,000 元</li>
                                <li>南港泳池（五米）含一支氣瓶 定價 3,500 元</li>
                                <li>前港泳池（兩米）含一支氣瓶 定價 2,000 元</li>
                                <li>執照更新課程含（學科 1.5h + 證照 + 海洋3支）定價7,000元</li>
                            </ul>
                            <ul style="list-style:none">
                                <li>
                                    <span class="fs-6">費用包含：場地費、教練費、氣瓶費、訓練期間之裝備。</span>
                                </li>
                                <li>
                                    <span class="fs-6">費用不含：東北角海洋交通費、餐食費。</span>
                                </li>
                                <li>
                                    <span class="fs-6">自備全部裝備者（鉛塊除外），費用可減免 500 元。</span>
                                </li>
                                <br>
                            </ul>
                            <br>
                        </div>
                        <!-- 報名方式 -->
                        <div>
                            <app-paymentmethod1000></app-paymentmethod1000>
                            <br>
                        </div>
                        <!-- 報名須知 -->
                        <div>
                            <h3>報名須知</h3>
                            <hr>
                            <ol class="fs-6">
                                <li>具備開放水域潛水員OWD證照（不分系統）。</li>
                                <li>年齡需滿12歲以上，健康狀況良好。</li>
                                <li>
                                    <a href="/#/health001">健康聲明書在此請點擊。</a>
                                </li>
                                <li>
                                    <p>潛水支數24支以內或距離上次潛水超過6個月者，請填寫</p><a href="/#/dz05">自我能力評估表。</a>
                                </li>
                                <li>本中心依據自我能力評估表分數，有權建議調整學員水肺複習上課內容。</li>
                            </ol>
                        </div>
                        <!-- 上課地點 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">上課地點</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="80px" style="vertical-align:text-top;">
                                            <p>泳池:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>南港運動中心（台北市南港區玉成街69號）</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>海洋:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>iDiving東北角龍洞店（新北市貢寮區龍洞街 66-1 號）</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>註: </p>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修無法預約等），若有額外增加的費用本中心將自行吸收（大台北區轉換場地之費用），
                                                不會另外收費。學員不可因課程時間/地點有調整而要求退費。
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p></p>
                                        </td>
                                        <td class="fs-6">
                                            <p>
                                                交通須自理，或由iDiving協調共乘，台北到東北角車資 300 元/人。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 更改辦法 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">更改辦法</h3>
                            <hr>
                            <ul>
                                <li>上課日期之更改須在3天前(不含上課當日)告知。</li>
                                <li>上課日期3天內(不含上課當日)調整，每次需收取調整費750元。</li>
                                <li>上課當天通知要更改日期或曠課未通知則需收取調整費1,000元。</li>
                                <li>因身體不適，出示醫院掛號單據者則不收。</li>
                                <li>女性夥伴參加課程前請自行評估生理期影響。</li>
                            </ul>
                        </div>
                        <!-- 取消辦法 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">取消辦法</h3>
                            <hr>
                            <ul>
                                <li>
                                    <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                                </li>
                            </ul>
                            <br>
                        </div>
                    </ng-template>
                </li>
            </ul>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>

            <!-- <pre>Active: {{ active }}</pre> -->

        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>