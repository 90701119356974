<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">學潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">延伸領域潛水課程</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">

            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>延伸領域潛水</a>
                    <ng-template ngbNavContent class="custom-class">
                        <br>
                        <h3 style="font-family:DFKai-sb;">延伸領域潛水 Extended Range(ER)</h3>
                        <hr>
                        <!-- 課程內容 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">開課內容</h3>
                            <ul>
                                <li class="fs-6">
                                    學科（9hr）<br>
                                    減壓理論、深水處的潛水生理學、延伸領域器材、減壓潛水計畫、備用計畫
                                </li>
                                <li class="fs-6">
                                    平靜水域（4hr）<br>
                                    雙瓶操作，裝備組裝與調整，安全檢查，踢動練習
                                </li>
                                <li class="fs-6">
                                    海洋訓練（8次）<br>
                                    階段氣瓶卸除，耗氣量檢測與計算，交換使用減壓氣體，無意識潛水員救援，執行減壓，應變狀況處理，團隊合作
                                </li>
                            </ul>
                            <br>
                            <img src="../../../../assets/Image/Data/SC_ER_01.png" width="60%" style="display:block; margin:auto;">
                        </div>
                        <!-- 課程特色 -->
                        <div>
                            <h3 style="font-family:DFKai-sb;"><br>課程特色</h3>
                            <ul class="fs-6">
                                <li>
                                    教你操作如何使用三支以上氣瓶下水，到達深水處遊玩，並有效縮短減壓時間。
                                </li>
                                <li>
                                    潛水最大拓展至45m。
                                </li>
                                <li>
                                    訓練團隊意識，成為合格的技術潛水人。
                                </li>
                                <li>
                                    學員來店購買全套裝備享有學員優惠價。
                                </li>
                            </ul>
                            <br>
                            <img src="../../../../assets/Image/Data/SC_ER_02.png" width="100%" style="display:block; margin:auto;">
                        </div>
                        <!-- 課程規劃 -->
                        <div>
                            <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td width="10%">
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                DAY 1 | 學科 19:00~22:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                深水處的潛水生理學、減壓理論
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 2 | 學科 19:00~22:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                延伸領域器材、減壓潛水計畫、備用計畫
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 3-4 | 平靜水域08:00~18:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                裝備組裝與調整、長短管操作、流量檢查、安全檢查、減壓氣瓶拆裝、踢動練習、應變狀況處理、階段氣瓶卸除、交換使用減壓氣體、無意識潛水員救援、執行減壓、BC故障
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 5-6 | 開放水域08:00~17:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                耗氣量檢測與計算、水面安全檢查、應變狀況處理、模擬減壓
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <p class="fs-6">
                                                <br>DAY 7-8 | 開放水域08:00~17:00<br>
                                            </p>
                                            <hr>
                                            <p class="fs-6">
                                                團隊合作、執行計畫減壓
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <img class="d-block" src="../../../../assets/Image/Data/ER_Schedule.png" width="100%" style="display:block; margin:auto;">
                        </div>
                        <!-- 課程學費 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">課程學費</h3>
                            <hr>
                            <ul class="fs-6">
                                <li style="color: green;">定價： 35000，直上至三合一混合氣課程：41000 元（不含氦氣氣體費用）。</li>
                                <li>現金優惠：送技潛記事本。</li>
                                <li>證照優惠：iDiving的減壓潛水員額外贈送30M 滾輪浮力袋組或側掛綁帶。</li>
                                <li style="color: red;">以上現金優惠、證照優惠可合併使用。</li>
                                <li>費用包含：SSI數位教材、國際數位證照申請、教練費、旅遊平安保險、一趟船潛3000元。</li>
                                <li>費用不含：裝備租借、氦氣氣體費用、海洋實習交通、用餐費用與移地至墾丁小琉球結訓之相關費用。</li>
                                <li>未達課程訓練標準，增加課程半天收費3000元。</li>
                            </ul>
                            <br>
                        </div>
                        <!-- 報名方式 -->
                        <div>
                            <br>
                            <app-paymentmethod></app-paymentmethod>
                            <br>
                        </div>
                        <!-- 報名須知 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">報名須知</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <p>
                                                基本條件需年滿18歲、已擁有50次的潛水紀錄，並具有深潛與高氧證照，健康狀況良好
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <a href="https://drive.google.com/file/d/13Lltu9vx3duMY8BWTbkFMDUY5FbmipIw/view?pli=1">健康聲明書在此請點擊</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 上課地點 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">上課地點</h3>
                            <hr>
                            <table borderless="true">
                                <tbody>
                                    <tr>
                                        <td class="fs-6" colspan="2">
                                            <p>往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" width="100px" style="vertical-align:text-top;">
                                            <p>教室學科:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>iDiving 士林前港店（台北市士林區前港街 8 號）</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p>海洋實習:</p>
                                        </td>
                                        <td class="fs-6">
                                            <p>iDiving 東北角龍洞店（新北市貢寮區龍洞街 66-1 號）</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="color:green;vertical-align:text-top;">
                                            <p>註: </p>
                                        </td>
                                        <td class="fs-6" style="color:green;">
                                            <p>
                                                上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修等），
                                                若有額外增加的費用本中心將自行吸收，不會另外收費，但學員不可因課程時間調整而要求退費。
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 特約課程 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">特約課程</h3>
                            <hr>
                            <ul>
                                <li>學科部份，每另外約一日課程加價 300 元</li>
                                <li>術科部份，每另外約一日課程加價 500 元</li>
                                <li>三人同行，一起約定特殊時間上課，不須額外加價。</li>
                                <li>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</li>
                            </ul>
                        </div>
                        <!-- 課程更改 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">課程更改</h3>
                            <hr>
                            <ul>
                                <li>課程須於開始上課後的兩個月內完成。</li>
                                <li>上課日期之更改須在 10 天前告知。</li>
                                <li>上課日期 10 天內調整，每次需收取調整費 500 元。</li>
                                <li>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</li>
                                <li>因身體不適，出示醫院掛號單據者則不收取。</li>
                                <li>女性夥伴參加課程前請自行評估生理期影響。</li>
                            </ul>
                        </div>
                        <!-- 課程取消 -->
                        <div>
                            <br>
                            <h3 style="font-family:DFKai-sb;">課程取消</h3>
                            <hr>
                            <ul>
                                <li>學員自行放棄課程，本訓練中心將不予退費。</li>
                                <li>若因身體因素不適合從事潛水活動，並開立醫生證明者，除了NT 3,000元購買數位教材的訂金，其餘將會退還。</li>
                            </ul>
                            <br>
                        </div>
                    </ng-template>
                </li>
            </ul>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>

            <!-- <pre>Active: {{ active }}</pre> -->

        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>