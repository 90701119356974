<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <div class="container-fluid">
                <nav class="breadcrumb bg-white">
                    <a class="breadcrumb-item" href="#">首頁</a>
                    <a class="breadcrumb-item">玩潛水</a>
                    <a class="breadcrumb-item">小琉球</a>
                </nav>
            </div>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-10">
            <h3 style="font-family:DFKai-sb;">小琉球</h3>
            <hr>
            <br>
            <!-- 景點介紹 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【景點介紹】</h3>
                <!-- <img src="../../../assets/Image/Data/Kenting_1..jpg" style="float: right; width: 80%"> -->
                <table cellpadding="0" cellspacing="0" style="text-align: justify;">
                    <tr>
                        <td>
                            <table width="100%">
                                <tr>
                                    <td width="55%">
                                        <table cellpadding="0" cellspacing="0" width="100%">
                                            <tr>
                                                <td class="fs-6">
                                                    <strong>位置</strong><br />
                                                    獨立小島「琉球嶼」的通稱，面積僅6.8平方公里，海岸線長約12公里，位於南臺灣屏東縣東港鎮西南方的海面上，
                                                    自東港搭船只需半小時即可抵達。小琉球是台灣近海的一座珊瑚礁島，觀光資源豐富，不受東北季風影響，四季皆適合旅遊。
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td><img src="../../../assets/Image/Data/Liuchiu_01.jpg"
                                            style="float: right; width: 80%"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td class="fs-6">
                            <strong>天氣概況</strong><br />
                            小琉球平均海溫終年都維持在 25℃
                            以上，較台灣其他地區高，因此為冬季最適合潛水等水上活動的景點之一，且除了颱風之外，風浪也較為平靜，因緯度較低又位於台灣西半部外島，
                            較不受東北季風影響，四季溫度變化較小，屬於熱帶季風氣候。
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <table width="100%">
                                <tr>
                                    <td><img src="../../../assets/Image/Data/Liuchiu_02.jpg" width="100%"></td>
                                    <td>&nbsp;</td>
                                    <td><img src="../../../assets/Image/Data/Liuchiu_03.jpg" width="100%"></td>
                                    <td>&nbsp;</td>
                                    <td><img src="../../../assets/Image/Data/Liuchiu_04.jpg" width="100%"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td class="fs-6">
                            <strong>地形</strong><br />
                            本嶼為一丘陸地，地形最高處稱為龜仔路山，海拔87公尺，素為航海者之指標。形勢東西狹窄，東西最寬處僅2公里，南北長4.1公里，北部稍寬，南部較狹。
                            島之長軸從東北伸向西南，周圍十三公里，面積僅有6.8平方公里，全島被兩條交叉地塹切割成為四塊台地，一條走向東北至西南形成中央地溝，
                            為島中主要交通孔道（由白沙至海子口）；另一條走向西北至東南，通過島之中央，外形較不明顯，為名符其實之剖腹山，故俗稱「剖腹山嶼」。
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td class="fs-6">
                            <strong>地質</strong><br />
                            本嶼是台灣屬島中，唯一的珊瑚島，表面被珊瑚石灰岩覆蓋，海岸被隆起的珊瑚礁圍繞。石灰岩洞地形及珊瑚礁海岸地形遍佈全島，較著名的如烏鬼洞、美人洞、
                            山豬溝等。琉球嶼珊瑚石灰岩質與鳳山珊瑚石灰岩相似，琉球嶼之四塊台地表面，均被紅土質土壤所覆蓋，而呈紅土台地。
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 著名潛點 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【著名潛點】</h3>
                <table style="text-align: justify;">
                    <tr>
                        <td>
                            <table width="100%">
                                <tr>
                                    <td><img src="../../../assets/Image/Data/Liuchiu_08.jpg" width="100%"></td>
                                    <td>&nbsp;</td>
                                    <td><img src="../../../assets/Image/Data/Liuchiu_09.jpg" width="100%"></td>
                                    <td>&nbsp;</td>
                                    <td><img src="../../../assets/Image/Data/Liuchiu_10.jpg" width="100%"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <ol class="fs-6">
                                <li>【花瓶岩】小琉球的熱門潛點，深度6~12米，魚群親人不怕生，也是海龜常出沒的地點，軟珊瑚的種類也不少。</li>
                                <li>【美人洞】這裡的海域寬廣，孕育豐富的海洋生態，同時也有大量海龜被吸引而來，因此是各潛點中海龜數量最多的一個。</li>
                                <li>【衫福漁港沈船】位於小琉球西北面杉福漁港外，最大深度23m內。水下沈船已經斷裂破碎，主要分為船頭和船身，船頭在水深16-18m沙地與岩盤交界處，沿著下潛繩下潛便可看見船頭。船身距離船頭約100~200公尺外的大片沙地上，深度約22-23m，船身周遭的沙地上都有許多花園鰻，踢到船尾就可以看到幾座電桿礁。
                                </li>
                                <br>
                                <table width="100%">
                                    <tr>
                                        <td><img src="../../../assets/Image/Data/Liuchiu_11.jpg" width="100%"></td>
                                        <td>&nbsp;</td>
                                        <td><img src="../../../assets/Image/Data/Liuchiu_12.jpg" width="100%"></td>
                                        <td>&nbsp;</td>
                                        <td><img src="../../../assets/Image/Data/Liuchiu_13.jpg" width="100%"></td>
                                    </tr>
                                </table>
                                <br>
                                <li class="fs-6">【龍蝦洞軟珊瑚區】在小琉球東岸，容易有流，是中級的潛點，底下有從台灣本島拉到小琉球的海底電纜，生態非常豐富，也是海龜常常出沒的地點。</li>
                                <li class="fs-6">
                                    【鎮海艦】：1998年「妮蔻兒」颱風來襲，鎮海艦因任務關係來不及返港，而在海上失去動力漂流疏泊，由於艦齡已達55歲，又歷經多次碰撞，遂決定於1999年令其除役，並於2001年12月於小琉球爆破沉海做為人工魚礁。
                                    深約30米，常有強流，屬高級潛點，需在專業導潛帶領尚可進行潛水；船身目前仍算完整，可慢慢探訪沉船的美麗。</li>
                                <br>
                            </ol>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 建議行程 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【建議行程】</h3>
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>2 天 1 夜</a>
                        <ng-template ngbNavContent class="custom-class">
                            <!-- 行程說明 -->
                            <div>
                                <h3 style="font-family:DFKai-sb;">行程說明</h3>
                                <table style="text-align: justify;">
                                    <tr>
                                        <td class="fs-6">
                                            出發<br>
                                            可選擇：<br>
                                            一、19:30 iDiving 集合搭車前往東港並住宿一晚。<br>
                                            二、08:30 自行前往，東港東琉線碼頭集合（請務必準時集合）。<br>
                                            (每趟行程將因應交通方式的不同，集合時間將略有調整，以出發前一週公布的為準。)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            第一天<br>
                                            08:00 前往東港渡船碼頭，搭乘渡船前往小琉球，領取機車後即前往民宿整理裝備，約 11:30 集合午餐，下午進行船潛 2
                                            支，盥洗後享用晚餐。晚間可自由活動安排潮間帶陸遊、觀星。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            第二天<br>
                                            早上 7:30 用餐，8:30 集合潛水，上午船潛 2 支，約 12:00
                                            返回潛水中心，清洗、晾乾裝備，返回民宿盥洗並將行李打包放置於大廳；待午餐完畢再回潛店收拾裝備及行李。搭程 15:00
                                            船班返回東港東琉線碼頭。準備回家，結束快樂旅程。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%">
                                                <tr>
                                                    <td><img src="../../../assets/Image/Data/Liuchiu_05.jpg"
                                                            width="100%"></td>
                                                    <td>&nbsp;</td>
                                                    <td><img src="../../../assets/Image/Data/Liuchiu_06.jpg"
                                                            width="100%"></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <!-- 費用說明 -->
                            <div>
                                <h3 style="font-family:DFKai-sb;">費用說明</h3>
                                <div>
                                    行程費用及更多活動詳情請洽iDiving Line&#64;
                                    <a href="https://lin.ee/ge19YeP">
                                        <img src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png" height="36" border>
                                    </a>
                                </div>
                                <table style="text-align: justify;">
                                    <tr class="fs-6">
                                        <td width="1%">包　含：</td>
                                        <td>東港←→小琉球來回船票、小琉球島上交通及食宿、4 支日船潛、三百萬旅平險。</td>
                                    </tr>
                                    <tr class="fs-6">
                                        <td style="white-space:nowrap">不包含：</td>
                                        <td>裝備租借、出發地←→東港來回交通、東港住宿、個人消費、行程外潛水。</td>
                                    </tr>
                                </table>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink>3 天 2 夜</a>
                        <ng-template ngbNavContent>
                            <!-- 行程說明 -->
                            <div>
                                <h3 style="font-family:DFKai-sb;">行程說明</h3>
                                <table style="text-align: justify;">
                                    <tr>
                                        <td class="fs-6">
                                            出發<br>
                                            可選擇：<br>
                                            一、19:30 iDiving 集合搭車前往東港並住宿一晚。<br>
                                            二、08:30 自行前往，東港東琉線碼頭集合（請務必準時集合）。<br>
                                            (每趟行程將因應交通方式的不同，集合時間將略有調整，以出發前一週公布的為準。)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            第一天<br>
                                            08:00 前往東港渡船碼頭，搭乘渡船前往小琉球，領取機車後即前往民宿整理裝備，約 11:30
                                            集合午餐，下午進行船潛2支，盥洗後享用晚餐。晚間可自由活動安排潮間帶陸遊、觀星。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            第二天<br>
                                            08:00 前往東港渡船碼頭，搭乘渡船前往小琉球，領取機車後即前往民宿整理裝備，約 11:30 集合午餐，下午進行船潛 2
                                            支，盥洗後享用晚餐。晚間可自由活動安排潮間帶陸遊、觀星。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">
                                            第三天<br>
                                            早上 7:30 用餐，8:30 集合潛水，上午船潛 2 支，約 12:00
                                            返回潛水中心，清洗、晾乾裝備，返回民宿盥洗並將行李打包放置於大廳；待午餐完畢再回潛店收拾裝備及行李。搭程 15:00
                                            船班返回東港東琉線碼頭。準備回家，結束快樂旅程。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%">
                                                <tr>
                                                    <td><img src="../../../assets/Image/Data/Liuchiu_05.jpg"
                                                            width="100%"></td>
                                                    <td>&nbsp;</td>
                                                    <td><img src="../../../assets/Image/Data/Liuchiu_06.jpg"
                                                            width="100%"></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <!-- 費用說明 -->
                            <div>
                                <h3 style="font-family:DFKai-sb;">費用說明</h3>
                                <div class="fs-6">
                                    行程費用及更多活動詳情請洽iDiving Line&#64;
                                    <a href="https://lin.ee/ge19YeP">
                                        <img src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png"
                                            height="36" border>
                                    </a>
                                </div>
                                <table style="text-align: justify;">
                                    <tr class="fs-6">
                                        <td width="1%">包　含：</td>
                                        <td>東港←→小琉球來回船票、小琉球島上交通及食宿、6 支日船潛、三百萬旅平險。</td>
                                    </tr>
                                    <tr class="fs-6">
                                        <td style="white-space:nowrap">不包含：</td>
                                        <td>裝備租借、出發地←→東港來回交通、東港住宿、個人消費、行程外潛水。</td>
                                    </tr>
                                </table>
                            </div>
                        </ng-template>
                    </li>
                </ul>

                <div [ngbNavOutlet]="nav" class="mt-2"></div>
                <!-- <pre>Active: {{ active }}</pre> -->

            </div>
            <!-- 注意事項 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【注意事項】</h3>
                <table>
                    <tr>
                        <td>
                            <ol class="fs-6">
                                <li>填寫＂Google 表單＂報名，並繳交團費<span class="Large Important"> NT$5,000
                                        的『定金』</span>才算完成報名流程，若以轉帳方式繳交定金，請將您的匯款日期及帳號末五碼與金額傳至iDiving官方Line
                                        &#64;帳號，以便確認。若以現金（or匯款）繳交全額費用，享有現金優惠！</li>
                                <li>國內潛水旅遊活動會有成團人數要求，含領隊需達10人才會出團；<span
                                        class="Large Important">若因報名人數不足而無法出團，最遲將於出團日14天以前通知已報名活動之團員，『定金』可全數退還。</span>
                                </li>
                                <li><span class="Large Important">出團日30天以前會於Line的活動群組提供行程資料，若有任何需求請提出討論。</span></li>
                                <li>當人數達到出團門檻，所有團員亦繳清費用時，則保證出團，若有人員臨時取消造成出團人數不足亦不受影響，而臨時退團人員的退費規則如下：<br />
                                    <span class="Large Important">A. 活動開始前第十五日以上取消者，須賠償團費的百分之十。</span><br />
                                    <span class="Large Important">B. 活動開始前第五日至第十四日取消者，須賠償團費的百分之三十。</span><br />
                                    <span class="Large Important">C. 活動開始前第二日至第四日取消者，須賠償團費的百分之五十。</span><br />
                                    <span class="Large Important">D. 於活動當日或前一日取消，或未通知不參加者，須賠償團費全額。</span><br />
                                    *若有溢繳或可退款的費用，可選擇來店退款或刷退（須視當時繳費方式），若要匯款轉帳退費須扣除手續費（每筆NT 30元），亦可選擇保留餘額轉至其他消費。
                                </li>
                                <li>若遇颱風、浪況不佳等不可抗拒之因素，基於安全考量，iDiving將保有取消活動之權利，已繳交的費用於扣除必要支出（如代訂大眾交通的退票手續費）後退還。</li>
                                <li>國內潛水旅遊皆為完整套裝行程，如有個人因素更動行程內容，恕不提供會員優惠。</li>
                                <li>住宿房間之房等差異（套房、雅房、通鋪等），如現場遇特殊情況，將由領隊視情況協調分配。若有特殊需求請事先提出。</li>
                                <li>須持有合格的潛水證照（國際潛水證照初級以上資格），若要參加特殊環境潛水（如：夜潛），需具備相關資格；部分活動可能會有額外條件要求。</li>
                                <li>活動行程以Line活動群組公布的資訊為準。潛點選擇由潛導視當時天候、海況、團隊狀況決定。進行潛水活動時採潛伴制進行，請彼此相互照應。</li>
                                <li>禁帶漁獵器材，亦禁止攜帶任何生物上岸，違者不得再參加後續潛水活動。</li>
                                <li>填寫＂Google表單＂報名者，視同同意以上所有條款。</li>
                            </ol>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- 協辦旅行社 -->
            <div>
                <h3 style="font-family:DFKai-sb;">【協辦旅行社】</h3>
                <div>iDiving 為了讓旅遊活動有保障，所有旅遊行程特別與旅行社偕同舉辦，讓你玩得開心又有保障。</div>
                <br />
                <table border="1" cellpadding="0" cellspacing="0">
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">主辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">愛潛水股份有限公司</td>
                        <td>承辦人：愛潛水</td>
                        <td>行動電話：0909-097696</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2882-7696</td>
                        <td>FAX：02-2882-7656</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAILl：info&#64;idiving.com.tw</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市士林區前港街8號</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td rowspan="4" style="vertical-align: middle">協辦單位</td>
                        <td rowspan="4" style="vertical-align: middle">萬陽旅行社有限公司</td>
                        <td>業務請洽：蘇恒璀</td>
                        <td>行動電話：0937-196518</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td>TEL：02-2517-1333</td>
                        <td>FAX：02-2508-1537</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">E-MAIL：tracy410&#64;ms45.hinet.net</td>
                    </tr>
                    <tr class="fs-6" align="center">
                        <td colspan="2">地址：台北市南京東路3段91號四樓之三</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>