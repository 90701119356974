<div class="container-fluid">

    <app-head></app-head>

    <div class="row">
        <table>
            <tr>
                <td style="height: 12vh;">
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col w-100">
            <br>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">首頁</a></li>
                    <li class="breadcrumb-item"><a href="#">學潛水</a></li>
                    <li class="breadcrumb-item active" aria-current="page">獎勵認證</li>
                </ol>
            </nav>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-xl">
        </div>
        <div class="col-xl-8">
            <h3 style="font-family:DFKai-sb;">獎勵認證</h3>
            <!-- 特約班 課程介紹 -->
            <div>
                <br>
                <h3>課程介紹</h3>
                <hr>
                <div>
                    <p class="fs-6">
                        為鼓勵學員能精進自身技巧並增進潛水安全，SSI將贈送特定執照給達成3階段目標的學員。<br>
                    </p>
                    <br>
                </div>
                <div>
                    <img src="../../../../assets/Image/Data/DiverLevels.png" width="80%" style="display:block; margin:auto;" alt="" />
                    <br>
                </div>
            </div>
            <!-- 特約班 報名方式 -->
            <div>
                <app-paymentmethod></app-paymentmethod>
            </div>
            <!-- 學前須知 -->
            <div>
                <table borderless="true">
                    <thead>
                        <tr>
                            <th>
                                <h3 style="font-family:DFKai-sb;">學前須知</h3>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="fs-6">
                                1. iDiving
                                採有條件的保證班教學，課程應於開始後的兩個月內完成（10~3月除外），若教練要求學員加強練習，將不另外收費；
                                如果因故自行拖延上課，導致學習效果不佳，所衍生的費用（課程安排外的教練費、場地費、裝備使用費）需自行負擔。
                                上課日期之更改須在 10 天前告知，若於上課日期 10 天內才告知，每次需收取課程調整費 500元，若是上課當天才通知要更改
                                日期或曠課未通知則需收取課程調整費 1,000元，如果是因身體不適，出示醫院掛號單據者則不收取。
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p class="fs-6">2. 本訓練中心除了報名時收取的學費及購買器材之費用外，訓練期間不另外收取其他費用。</p>
                                <table>
                                    <tr>
                                        <td width="5%" style="vertical-align:text-top;">
                                            <p class="fs-6" style="white-space:nowrap;">
                                                學費包含:
                                            </p>
                                        </td>
                                        <td class="fs-6">
                                            數位教材、教練費、泳池場地費、訓練期間部分潛水裝備（浮力調整裝置、調節器、電腦錶、配重、氣瓶）使用、海洋實習保險及數位證照申請。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6" style="vertical-align:text-top;">
                                            <p class="fs-6" style="white-space:nowrap;">
                                                學費不含:
                                            </p>
                                        </td>
                                        <td class="fs-6">
                                            浮潛裝備（面鏡、呼吸管、防寒衣、手套、套鞋、蛙鞋，需自備，亦可租借）使用、餐飲、交通、住宿等費用。
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6">
                                <p>3.
                                    學員自行放棄課程，本訓練中心將不予退費。
                                    若因身體因素而不適合從事潛水活動，並開立醫生證明者，繳交之費用退費方式如下:<br />
                                    NT 3,000 元訂金用做購買線上教材，無法退費；<br />
                                    開課前 30 天以上，退回全新贈品，可退費NT 12,000 元；<br />
                                    開課前 30 天之內，退回全新贈品，可退費NT 10,000 元；<br />
                                    已上過學科，退回全新贈品，可退費NT 8,000 元；<br />
                                    已上過泳池課程或贈品已使用，可退費NT 4,000 元；<br />
                                    已上過海洋實習，不可退費。
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6">
                                <p>4.
                                    學員於在學期間購買潛水器材享有會員優惠價。<br />
                                    訓練期間所提供之裝備若有穿戴不適或任何問題，請務必告知主持教練。<br />
                                    課程之學費、器材之購買費用與2吋大頭照，請於泳池課程之前繳齊。
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td class="fs-6">
                                5.上課地點，往返之交通請自行處理，<span>若有需要可以詢問是否能代為安排</span>
                                <table>
                                    <tr>
                                        <td width="5%" style="vertical-align:text-top;">
                                            <p class="fs-6" style="white-space:nowrap;">
                                                教室學科:
                                            </p>
                                        </td>
                                        <td class="fs-6">
                                            iDiving 士林前港店（
                                            <a class="fs-6" style="font-size: 30px;" href="https://maps.google.com.tw/maps?q=%E5%8F%B0%E5%8C%97%E5%B8%82%E5%A3%AB%E6%9E%97%E5%8D%80%E5%89%8D%E6%B8%AF%E8%A1%978%E8%99%9F">台北市士林區前港街
                                                8 號
                                            </a>
                                            ）
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="5%" style="vertical-align:text-top;">
                                            <p class="fs-6" style="white-space:nowrap;">
                                                泳池術科:
                                            </p>
                                        </td>
                                        <td class="fs-6">前港公園泳池（需先到 iDiving 集合）</td>
                                    </tr>
                                    <tr>
                                        <td width="5%" style="vertical-align:text-top;">
                                            <p class="fs-6" style="white-space:nowrap;">
                                                海洋實習:
                                            </p>
                                        </td>
                                        <td class="fs-6">iDiving 東北角龍洞店（新北市貢寮區龍洞街 66-1 號）
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr>
                                        <td width="1%" style="vertical-align:text-top;">
                                            <p class="fs-6" style="white-space:nowrap;">
                                                註:
                                            </p>
                                        </td>
                                        <td class="fs-6">
                                            上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修等），若有額外增加的費用本中心將自行吸收，
                                            不會另外收費，但學員不可因課程"之地點/時間有"調整而要求退費。
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                6.結訓之學員均需達到課程標準，包括學科與術科之評鑑:
                                <table>
                                    <tr>
                                        <td class="fs-6" width="1%" nowrap>學科:</td>
                                        <td class="fs-6">需通過筆試測驗（成績達80分以上），未達標準需於檢討後重考。</td>
                                    </tr>
                                    <tr>
                                        <td class="fs-6">術科:</td>
                                        <td class="fs-6">平靜水域所學之潛水技巧須在開放水域操作，並接受教練評鑑。</td>
                                    </tr>
                                </table>
                                學員結訓後，訓練中心將於一週內完成SSI國際數位證照申請。（如有急需請先告知，本中心可於課程完成後的下一個工作天內完成申請）<br>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <div class="col-xl">
        </div>
    </div>

    <div class="row">
        <table>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
        </table>
    </div>

    <app-foot></app-foot>

</div>